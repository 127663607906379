import { Button, Wrapper, useBreadcrumbRoutes, useUpdate, Modal, Tabs } from '@maxtropy/components';
import { Space, Spin } from 'antd';
import PlatformNav from './components/PlatformNav';
import CustomNav from './components/CustomNav';
import type { TabsProps } from 'antd';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { NavItem, TabKey, TabKeyDisPlay, getNavList } from '../../api/compositeNavConfig';
import CusNavEditModal from './components/CustomNav/CusNavEditModal';
import GroupModal from './components/GroupModal';
import { useHasPermission } from '../../utils/hooks';
import { PermissionsType } from '../../common/permissionsConst';
import { apiV2ComprehensiveMenuResetPlatformNavigationsPost } from '@maxtropy/cc-customer-apis-v2';

const CompositeNavConfig: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [tabKey, setTabKey] = useState<TabKey>(TabKey.PLATFORM);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [groupModal, setGroupModal] = useState<boolean>(false);
  const [data, setData] = useState<NavItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const hasDistributeGroupConfig = useHasPermission(PermissionsType.B_DISTRIBUTE_GROUP_CONFIG); // 分组管理
  const hasAdd = useHasPermission(PermissionsType.B_INCREASE_NAVIGATION); // 新增导航

  useEffect(() => {
    setLoading(true);
    getNavList(tabKey)
      .then(res => setData(res.list ?? []))
      .finally(() => setLoading(false));
  }, [tabKey, update]);

  const tabItems: TabsProps['items'] = [
    {
      label: TabKeyDisPlay[TabKey.PLATFORM],
      key: TabKey.PLATFORM.toString(),
      children: (
        <Spin spinning={loading}>
          <PlatformNav updateFn={updateFn} data={tabKey === TabKey.PLATFORM ? data : []} />
        </Spin>
      ),
    },
    {
      label: TabKeyDisPlay[TabKey.CUSTOM],
      key: TabKey.CUSTOM.toString(),
      children: (
        <Spin spinning={loading}>
          <CustomNav updateFn={updateFn} data={tabKey === TabKey.CUSTOM ? data : []} />
        </Spin>
      ),
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <Tabs
        tabBarExtraContent={
          <Space size={8}>
            {tabKey === TabKey.CUSTOM && hasAdd && (
              <Button
                type="primary"
                onClick={() => {
                  setAddModalOpen(true);
                }}
              >
                新增导航
              </Button>
            )}
            {tabKey === TabKey.PLATFORM && (
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: '请确认重置平台导航？',
                    content: '重置后重新获取默认配置（分组、权限、链接、跳转方式），现有平台导航仅保留导航名称配置。',
                    okText: '重置',
                    cancelText: '取消',
                    onOk: () => {
                      apiV2ComprehensiveMenuResetPlatformNavigationsPost({}).then(res => {
                        Modal.success({ title: '重置成功' });
                      });
                    },
                  });
                }}
              >
                重置平台导航
              </Button>
            )}
            {hasDistributeGroupConfig && (
              <Button
                type="primary"
                onClick={() => {
                  setGroupModal(true);
                }}
              >
                分组管理
              </Button>
            )}
          </Space>
        }
        items={tabItems}
        onChange={v => {
          setTabKey(Number(v));
          setData([]);
        }}
      />
      {groupModal && <GroupModal updateFn={updateFn} modalOpen={groupModal} setModalOpen={setGroupModal} />}
      {addModalOpen && (
        <CusNavEditModal updateFn={updateFn} isEdit={false} modalOpen={addModalOpen} setModalOpen={setAddModalOpen} />
      )}
    </Wrapper>
  );
};

export default CompositeNavConfig;
