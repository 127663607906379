import { fetch } from '@maxtropy/components';

export interface TaxPointRespnse {
  name: string;
  tax: number;
  value: number;
}

export function getTaxPoint() {
  return fetch<{ list: TaxPointRespnse[] }>(
    `/api/v2/taxpoint/listEnum`,
    {
      method: 'POST',
    },
    true
  );
}
