import React from 'react';
import styles from '../screen.module.scss';
import { Col, Row, Space, Spin, Typography } from 'antd';
import { NavigationDetail } from '../../../api/navScreen';
import NavButton from './NavButton';

type CardsWrapperSectionProps = {
  title?: string;
  navDetails?: NavigationDetail[];
  children?: React.ReactNode;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  spinning?: boolean;
};

export default function CardsWrapperSection({
  title,
  navDetails,
  children,
  wrapperClassName,
  wrapperStyle,
  spinning = false,
}: CardsWrapperSectionProps) {
  return (
    <div className={`${styles.cardsWrapper} ${wrapperClassName}`} style={wrapperStyle}>
      <Spin spinning={spinning}>
        <Row justify="space-between" align="top" style={{ marginBottom: 12 }}>
          <Col>
            <Typography.Title level={4}>{title}</Typography.Title>
          </Col>
          {navDetails ? (
            <Col>
              <Space>
                {navDetails.map(n => (
                  <NavButton navDetail={n} key={n.id} />
                ))}
              </Space>
            </Col>
          ) : null}
        </Row>
        <>{children}</>
      </Spin>
    </div>
  );
}
