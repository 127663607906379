/* eslint-disable no-useless-escape */
import { useState, useEffect } from 'react';
import { isNil } from 'lodash-es';

export const useQuery = <T>(action: () => Promise<T | undefined>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(undefined);
  useEffect(() => {
    setIsLoading(true);
    action()
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [action]);
  return { isLoading, data };
};

export interface Labels {
  [key: string]: string;
}

interface LabelValue<V extends string | number> {
  label: string;
  value: V;
}

export function generateLabelList<V extends string | number>(labels: Labels, values: V[]): LabelValue<V>[] {
  return values.map(value => ({
    label: labels[value],
    value,
  }));
}

export const getRange = (min: number | undefined, max: number | undefined) => {
  if (!isNil(min) && !isNil(max)) {
    return `${min} ~ ${max}`;
  } else if (!isNil(min) && isNil(max)) {
    return `${min} ~`;
  } else if (isNil(min) && !isNil(max)) {
    return `~ ${max}`;
  } else {
    return '--';
  }
};
