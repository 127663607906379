import { useState, useEffect } from 'react';
import { useTenantPermissions } from '@maxtropy/components';

export function useHasPermission(key: string) {
  const [status, setStatus] = useState<boolean>();
  const permissions = useTenantPermissions();
  useEffect(() => {
    setStatus((permissions ?? []).findIndex(i => i === key) !== -1);
  }, [permissions, key]);
  return status;
}
