import { Button, EllipsisSpan, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { useState } from 'react';
import { HrefType, HrefTypeDisPlay, NavItem } from '../../../../api/compositeNavConfig';
import PlatformNavEditModal from './PlatformNavEditModal';
import { useHasPermission } from '../../../../utils/hooks';
import { PermissionsType } from '../../../../common/permissionsConst';

const columns = [
  {
    title: '导航名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属分组',
    dataIndex: 'comprehensiveMenuGroupName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '对应权限',
    dataIndex: 'functionPermissionCodeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '导航链接',
    dataIndex: 'path',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '跳转方式',
    dataIndex: 'hrefType',
    ellipsis: { showTitle: true },
    render: (v: HrefType) => <EllipsisSpan value={HrefTypeDisPlay[v]} />,
  },
];

interface Props {
  data: NavItem[];
  updateFn: () => void;
}

const PlatformNav: React.FC<Props> = ({ data, updateFn }) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [navItem, setNavItem] = useState<NavItem>();
  const hasEdit = useHasPermission(PermissionsType.B_EDIT_NAVIGATION); // 编辑导航
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (v: any, record: NavItem) => (
        <Space>
          {hasEdit && (
            <Button
              type="link"
              onClick={() => {
                setNavItem(record);
                setEditModalOpen(true);
              }}
            >
              编辑
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table columns={buildColumns} dataSource={data} />
      {editModalOpen && (
        <PlatformNavEditModal
          updateFn={updateFn}
          data={navItem}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
        />
      )}
    </>
  );
};

export default PlatformNav;
