import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import IframeView from '../../components/IframeView';
import styles from './index.module.scss';
interface Iprops {
  pageType: string; // plantForm 平台应用  integrated 集成应用
}
const QlLanding: React.FC<Iprops> = ({ pageType }) => {
  const { permissionCode = 'unknown', linkId = 'unknown' } = useParams<{
    permissionCode: string;
    linkId: string;
  }>();

  const urlTemplate = encodeURIComponent('{qfHost}/app/{appId}');
  const iframeId = 'unique' + Date.now();

  let path = '';

  if (pageType === 'plantForm') {
    let codeBase64 = Base64.encode(permissionCode);
    path = `/qingflow/landing?urlTemplate=${urlTemplate}&permissionCode=${codeBase64}`;
  } else {
    path = `/qingflow/landing/integrated?urlTemplate=${urlTemplate}&linkId=${linkId}`;
  }
  return (
    <>
      <div className={styles.wrapper}>
        <IframeView id={iframeId} src={path} />
      </div>
    </>
  );
};
export default QlLanding;
