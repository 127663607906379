import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Form, Input, Modal, AttachmentUpload } from '@maxtropy/components';
import styles from '../index.module.scss';
import { CurrentDataProps } from './Manage';
import {
  createCustomer,
  CreateCustomerProps,
  CustomerProps,
  getCustomer,
  getNextLevelName,
  updateCustomer,
} from '../../../api/customer';
import { checkFullName, checkName } from './utils';
import { getDefaultLogo } from '../../../api/file';

interface EditModalProps {
  visible: boolean;
  onCancel: () => void;
  isAdd: boolean;
  currentData?: CurrentDataProps;
}

const EditModal: React.FC<EditModalProps> = props => {
  const { onCancel, isAdd, currentData } = props;
  const [form] = Form.useForm();
  const [opened, setOpened] = useState(props.visible);
  const [nextLevelName, setNextLevelName] = useState<string>('');
  const [info, setInfo] = useState<CustomerProps>();

  useEffect(() => {
    if (opened && !isAdd) {
      getCustomer(currentData!.mcid).then(data => {
        form.setFieldsValue(data);
        setInfo(data);
        if (!data.logoUrl) {
          defaultLogo();
        }
      });
    } else {
      changeLogo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAdd) {
      getNextLevelName(currentData!.mcid).then(data => {
        setNextLevelName(data.levelName);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdd]);

  const onFinish = async (values: CreateCustomerProps) => {
    if (!values.logoUrl) {
      await getDefaultLogo().then(data => {
        values.logoUrl = data.key;
      });
    }
    const res = isAdd
      ? await createCustomer(currentData!.mcid, values)
      : await updateCustomer(currentData!.mcid, values);
    if (res) {
      setOpened(false);
      onCancel();
    }
  };

  const defaultLogo = () => {
    getDefaultLogo().then(data => {
      form.setFieldsValue({
        logoUrl: data.key,
      });
    });
  };

  const changeLogo = () => {
    const mcid = isAdd ? currentData?.mcid : currentData?.parentMcid;
    if (mcid) {
      getCustomer(mcid).then(data => {
        form.resetFields(['logoUrl']);
        if (!data.logoUrl) {
          defaultLogo();
        } else {
          form.setFieldsValue({
            logoUrl: data.logoUrl,
          });
        }
      });
    } else {
      defaultLogo();
    }
  };

  return (
    <>
      <Modal
        title={`${isAdd ? '新建' : '编辑'}组织`}
        open={opened}
        size="large"
        onOk={() => form.submit()}
        onCancel={() => {
          setOpened(false);
          onCancel();
        }}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[32, 16]}>
            {(isAdd ? currentData?.name : currentData?.parentName) && (
              <Col span={12}>
                <Form.Item label="上级组织">
                  <span>{isAdd ? currentData?.name : currentData?.parentName}</span>
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item label={`${isAdd ? '新建' : ''}组织类型`}>
                <span>{isAdd ? nextLevelName : currentData?.levelName}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label={`${isAdd ? '新建' : ''}组织简称`}
                required
                rules={[{ validator: (rule: any, value: string) => checkName(rule, value, info?.name) }]}
                validateTrigger={['onBlur']}
              >
                <Input placeholder="最多8个字符，租户级唯一" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="fullName"
                label={`${isAdd ? '新建' : ''}组织全称`}
                required
                rules={[{ validator: (rule: any, value: string) => checkFullName(rule, value, info?.fullName) }]}
                validateTrigger={['onBlur']}
              >
                <Input placeholder="最多30个字符，租户级唯一" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="组织logo">
                <div className={styles.box}>
                  <Form.Item name="logoUrl" noStyle>
                    <AttachmentUpload />
                  </Form.Item>
                </div>
              </Form.Item>
              <span className={styles.restLogo} onClick={() => changeLogo()}>
                还原为默认logo
              </span>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditModal;
