import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { Row, Col, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import dayjs, { Dayjs } from 'dayjs';
import { isNil, debounce } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Gender, LeftNavPreference } from '../../../api/const';
import { commonMobilePhoneRegex, commonUsernameRegex } from '../../../utils/regex';
import { transformSpace } from '../../../utils/helper';
import {
  Modal,
  Radio,
  DatePicker,
  Upload,
  SearchInput as CustomSearchInput,
  SearchInputOptionProps,
  SubContent,
  defaultErrorHandling,
  Button,
  ResponseCodeError,
  Form,
  Input,
  message,
} from '@maxtropy/components';
import { CustomerUser, createUser, fetchUserById, getByCellphone, getByUsername } from '../../../api/staff';
import styles from './index.module.scss';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InfoCircleOutlined } from '@ant-design/icons';

interface UserFormValues {
  name: string;
  cellphone: string;
  username: string;
  navigationPreference: LeftNavPreference;
}

interface CreateUserProps {
  createUserForm: FormInstance;
  createStaffForm: FormInstance;
  saveCreateUserRes: (value: CustomerUser) => void;
  onClose: () => void;
}

const CreateUser: React.FC<CreateUserProps> = props => {
  const { createUserForm: form, createStaffForm, onClose, saveCreateUserRes } = props;
  const [createUserDisabled, setCreateUserDisabled] = useState<boolean>(false);
  const [option, setOption] = useState<SearchInputOptionProps[]>([]);
  const [searchPatternPhoneValue, setSearchPatternPhoneValue] = useState<string>('');
  const [cellphoneRes, setCellphoneRes] = useState<CustomerUser>();
  const [usernameRes, setUsernameRes] = useState<CustomerUser>();
  const [submitting, setSubmitting] = useState(false);
  const { id } = useParams<{ id: string }>();

  const [responseError, setResponseError] = useState<string>();

  const disabled = !isNil(id);

  const onPhoneSearch = (value: string) => {
    if (commonMobilePhoneRegex.test(value)) {
      setSearchPatternPhoneValue(value);
    } else {
      form.validateFields(['cellphone']);
    }
  };

  useEffect(() => {
    (async () => {
      if (!isNil(id)) {
        const res = await fetchUserById(id);
        form.setFieldsValue({
          ...res,
          birthday: res.birthday ? dayjs(res.birthday) : undefined,
        });
        saveCreateUserRes(res);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id]);

  useEffect(() => {
    if (commonMobilePhoneRegex.test(searchPatternPhoneValue)) {
      setCellphoneRes(undefined);
      getByCellphone(searchPatternPhoneValue)
        .onError(e => {
          defaultErrorHandling(e);
          return false;
        })
        .then(res => {
          setCellphoneRes(res);
          if (!res) {
            form.setFieldsValue({
              cellphone: searchPatternPhoneValue,
            });
          }
        })
        .catch(e => {
          setResponseError(e.cause.errorMessage);
        })
        .finally(() => {
          form.validateFields(['cellphone']);
        });
    }
  }, [searchPatternPhoneValue, form]);

  useEffect(() => {
    if (cellphoneRes) {
      setUsernameRes(undefined);
      setOption([
        {
          name: `${cellphoneRes.cellphone} ${cellphoneRes.name}`,
          value: cellphoneRes.cellphone,
          label: cellphoneRes.cellphone,
        },
      ]);
    } else {
      if (commonMobilePhoneRegex.test(searchPatternPhoneValue)) {
        setOption([]);
        form.setFieldsValue({
          username: undefined,
          name: undefined,
          gender: undefined,
          birthday: undefined,
          wechat: undefined,
          qq: undefined,
          email: undefined,
          headPic: undefined,
        });
        setCreateUserDisabled(false);
      }
    }
  }, [searchPatternPhoneValue, cellphoneRes, form]);

  const onCellphoneChange = (value: string) => {
    if (value === cellphoneRes?.cellphone) {
      form.setFieldsValue({
        ...cellphoneRes,
        birthday: cellphoneRes.birthday ? dayjs(cellphoneRes.birthday) : undefined,
        workEmail: cellphoneRes.email,
        workQq: cellphoneRes.qq,
        workWechat: cellphoneRes.wechat,
      });
      setCreateUserDisabled(true);
      saveCreateUserRes(cellphoneRes);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUsernameChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        getByUsername(e.target.value).then(res => {
          setUsernameRes(res);
        });
      } else {
        setUsernameRes(undefined);
      }
    }, 500),
    []
  );

  const onInputChange = (v: string, key: string) => {
    createStaffForm.setFieldsValue({
      [key]: v,
    });
  };

  const onFinish = async (values: unknown) => {
    const typedValues = values as UserFormValues;
    try {
      setSubmitting(true);
      const res = await createUser(typedValues);
      saveCreateUserRes(res);
      setCreateUserDisabled(true);
      if (res.password) {
        return new Promise(resolve => {
          const modal = Modal.success({
            title: '账号创建成功！',
            className: styles.modal,
            content: (
              <div className={styles.modalContentSty}>
                <div>账号：{typedValues.username}</div>
                <div>
                  密码：<span style={{ color: '#A61D24' }}> {res.password}</span>
                </div>
                <div>请及时保存密码</div>
              </div>
            ),
            footer: (
              <div className={styles.footerSty}>
                <CopyToClipboard
                  text={res.password!}
                  onCopy={() => {
                    message.success('复制密码成功');
                  }}
                >
                  <Button type="primary">复制新密码</Button>
                </CopyToClipboard>
                <Button
                  style={{ marginLeft: 8 }}
                  type="default"
                  onClick={() => {
                    modal.destroy();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
          });
        });
      } else {
        message.success('保存成功');
      }
    } catch (e) {
      console.error(e);
      message.error(ResponseCodeError.getMessage(e, '保存失败'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form form={form} layout="vertical" validateTrigger={['onBlur']} onFinish={onFinish}>
      <SubContent title="用户信息" style={{ marginBottom: 8 }}>
        <Row>
          <Col span={8}>
            <Form.Item
              label="手机号码"
              name="cellphone"
              validateFirst
              normalize={transformSpace}
              rules={[
                formInstance => ({
                  validator(_: any, value: string) {
                    if (responseError) {
                      return Promise.reject(responseError);
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
                { required: true, message: '请输入手机号码' },
              ]}
              tooltip={{
                title: '登录密码将通过手机号发送，请确保手机号可用',
                icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: '6px' }} />,
              }}
            >
              <CustomSearchInput
                disabled={!isNil(id)}
                timer={300}
                placeholder="请输入手机号码"
                option={option}
                onSearch={onPhoneSearch}
                onChange={onCellphoneChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  label="用户姓名"
                  name="name"
                  rules={[
                    { required: true, message: '请输入用户姓名' },
                    {
                      pattern: /^[•\u4e00-\u9fa5A-Za-z\u00C0-\u00FF\u00D1\u00F1\u0400-\u052F\u0370-\u03FF]+$/g,
                      message: '姓名须为汉字、•、英文字母、法语字母、西班牙语字母、俄语字母、希腊语字符',
                    },
                    { max: 20, message: '用户姓名最多20个字符' },
                  ]}
                  tooltip={{
                    title: '姓名须为汉字、•、英文字母、法语字母、西班牙语字母、俄语字母、希腊语字符',
                    icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: '6px' }} />,
                  }}
                >
                  <Input
                    placeholder="请输入用户姓名"
                    disabled={!getFieldValue('cellphone') || createUserDisabled || disabled}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  label="用户账号"
                  name="username"
                  rules={[
                    { required: true, message: '请输入用户账号' },
                    {
                      pattern: commonUsernameRegex,
                      message: '请输入长度5~20个字符，英文、数字、特殊字符并且不能为纯数字的组合',
                    },
                    form => ({
                      validator: (rule, value) =>
                        usernameRes && usernameRes.username === value
                          ? Promise.reject('抱歉，这个用户账号已经被他人使用了，请换一个')
                          : Promise.resolve(),
                    }),
                  ]}
                  tooltip={{
                    title: '请输入长度5~20个字符，英文、数字、特殊字符并且不能为纯数字的组合',
                    icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: '6px' }} />,
                  }}
                >
                  <Input
                    placeholder={
                      cellphoneRes
                        ? '请输入员工工号，该账号不作为登录系统用，仅用作企业内区分标识'
                        : '请输入用户账号，此账号用于日常登录'
                    }
                    disabled={!getFieldValue('cellphone') || createUserDisabled || disabled}
                    onChange={onUsernameChange}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item name="navigationPreference" label="左侧导航栏" rules={[{ required: true, message: '请选择' }]}>
              <Radio.Group disabled={createUserDisabled || disabled}>
                <Radio value={LeftNavPreference.OPEN_CLOSE}>收起/展开</Radio>
                <Radio value={LeftNavPreference.ALL_OPEN}>一直展开</Radio>
                <Radio value={LeftNavPreference.ALL_CLOSE}>直接隐藏</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="用户微信"
              name="wechat"
              rules={[{ pattern: /^[a-zA-Z0-9_-]{6,20}?$/, message: '请输入正确的微信' }]}
              validateFirst
            >
              <Input
                placeholder="请输入用户微信"
                onChange={e => {
                  onInputChange(e.target.value, 'workWechat');
                }}
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="用户邮箱"
              name="email"
              rules={[{ type: 'email', message: '请输入正确的邮箱' }]}
              validateFirst
            >
              <Input
                placeholder="请输入用户邮箱"
                onChange={e => {
                  onInputChange(e.target.value, 'workEmail');
                }}
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label="用户QQ"
              name="qq"
              rules={[{ pattern: /^\d{4,15}?$/, message: '请输入正确的QQ' }]}
              validateFirst
            >
              <Input
                placeholder="请输入用户QQ"
                onChange={e => {
                  onInputChange(e.target.value, 'workQq');
                }}
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="出生年月" name="birthday">
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={(currentDate: Dayjs) => currentDate.isAfter(dayjs())}
                placeholder="请输入出生年月"
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="性别" name="gender">
              <Radio.Group disabled={createUserDisabled || disabled}>
                <Radio value={Gender.MALE}>男</Radio>
                <Radio value={Gender.FEMALE}>女</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="用户头像" name="headPic">
              <Upload disabled={createUserDisabled || disabled} />
            </Form.Item>
          </Col>
        </Row>
        {!createUserDisabled && !disabled && (
          <Row className={styles.buttonWrapper}>
            <Col span={8}>
              <Form.Item>
                <Space size={8}>
                  <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
                    开始创建员工工号
                  </Button>
                  <Button onClick={onClose}>关闭</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
      </SubContent>
    </Form>
  );
};

export default CreateUser;
