import { fetch, PromiseWithErrorHandler } from '@maxtropy/components';
import { Gender, UserStatus } from './const';
import { Customer } from '@maxtropy/components/es/api/entity';
export interface OrganizationTreeData {
  code?: string;
  hasPermission?: boolean;
  levelName?: string;
  mcid: string;
  name?: string;
  canAddSub?: boolean;
  fullName?: string;
}

export interface OrganizationTreeProps {
  children: OrganizationTreeProps[];
  data?: OrganizationTreeData;
  parent?: number;
}

export function getOrganizationTree(): Promise<OrganizationTreeProps> {
  return fetch(
    `/api/v2/customer/organization-with-code`,
    {
      method: 'POST',
    },
    true
  );
}

export function getOrganizationWithCodeWithCurrent(): Promise<OrganizationTreeProps> {
  return fetch(
    `/api/v2/customer/organization-with-code-with-current`,
    {
      method: 'POST',
    },
    true
  );
}

export interface SummaryProps {
  projectSum: number;
  staffSum: number;
  allStaffsSum: number;
  allProjectsSum: number;
}

export function getSummary(mcid: string): Promise<SummaryProps> {
  return fetch(
    `/api/v2/customer/summary`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );
}

export interface CreateCustomerProps {
  name: string;
  fullName: string;
  logoUrl?: string;
  platformTitleConfig?: boolean;
  iconUrl?: string;
  platformTitleType?: number;
  platformName?: string;
  homePageLogoConfig?: boolean;
  homepageLogoUrl?: string;
  platformSloganNameConfig?: boolean;
  platformSloganName?: string;
  staffIds: number[];
}

export function createCustomer(parentMcid: string, body: CreateCustomerProps) {
  return fetch(
    `/api/v2/customer/create`,
    {
      method: 'POST',
      body: JSON.stringify({ parentMcid, ...body }),
    },
    true
  );
}

export interface CustomerProps {
  mcid: string;
  name: string;
  fullName: string;
  uscc?: string;
  address?: string;
  phone?: string;
  adCode?: string;
  logoUrl?: string;
  disabled?: boolean;
  provinceCityDistrict?: string;
  code?: string;
  createTime?: string;
  updateTime?: string;
  platformTitleConfig: boolean;
  iconUrl: string;
  platformTitleType: number;
  platformName?: string;
  homePageLogoConfig: boolean;
  homePageLogoUrl?: string;
  platformSloganNameConfig?: boolean;
  platformSloganName?: string;
}

export function getCustomer(mcid: string): Promise<CustomerProps> {
  return fetch(
    `/api/v2/customer`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );
}

export function updateCustomer(mcid: string, body: CreateCustomerProps) {
  return fetch(
    `/api/v2/customer/update`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid, ...body }),
    },
    true
  );
}

export interface MoveCustomerProps {
  source?: string;
  target?: string;
  name?: string;
  fullName?: string;
  logoUrl?: string;
  muid?: string;
  operationPassword?: string;
  targeCode?: string;
  targetName?: string;
  targetLevelName?: string;
}

export function moveCustomer(body: MoveCustomerProps) {
  return fetch<{ moveResult: boolean }>(
    `/api/v2/customer/move`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface CustomerListProps {
  muid: string;
  username: string;
  name: string;
  cellphone?: string;
  passwordUpdateTime?: string;
  createTime?: string;
  updateTime?: string;
  status?: UserStatus;
  gender?: Gender;
  birthday?: string;
  email?: string;
  wechat?: string;
  qq?: string;
  headPic?: string;
  humanCode?: string;
}

export function getListCustomer(value: string): PromiseWithErrorHandler<{ list: CustomerListProps[] }> {
  return fetch(
    `/api/v2/staff/customerUser/list`,
    {
      method: 'POST',
      body: JSON.stringify({ value }),
    },
    true
  );
}

export function getDescendants(): Promise<{ list: Customer[] }> {
  return fetch(
    `/api/v2/customer/descendants`,
    {
      method: 'POST',
    },
    true
  );
}

export interface DeleteCustomerProps {
  muid: string;
  operationPassword: string;
}

export function deleteCustomer(mcid: string, body: DeleteCustomerProps) {
  return fetch(
    `/api/v2/customer/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid, ...body }),
    },
    true
  );
}

export function getNextLevelName(mcid: string): Promise<{ levelName: string }> {
  return fetch(
    `/api/v2/customer/next-level-name`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );
}

export interface CheckNameUniqueProps {
  name?: string;
  fullName?: string;
}

export function checkNameUnique(query?: CheckNameUniqueProps) {
  return fetch<{ checkResult: boolean }>(
    `/api/v2/customer/checkNameUnique`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}
