import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { BusinessScope, PropertyManagementConfigOperationStaffStatus, WorkDivisionEnum } from './const';

export interface Paged<T> {
  total: number;
  page: number;
  size: number;
  list: T[];
}

export interface PageRequest {
  page?: number;
  size?: number;
}

export interface PropertyManagementConfigOperationStaffRequest {
  staffId: number;
  workDivision: WorkDivisionEnum;
  status: PropertyManagementConfigOperationStaffStatus;
  description?: string;
}

export interface PropertyManagementConfigOperationStaffResponse extends PropertyManagementConfigOperationStaffRequest {
  id: number;
  name: string;
  tel: string;
  staffCode: string;
}

export interface ElectricityDistributionSalesConfigRequest {
  contactNumber: string;
  taxPoint: number;
  powerTierConfigId: number;
  opStaffList: Array<PropertyManagementConfigOperationStaffRequest>;
  billTitle: string;
}

export interface PropertyManagementNewEnergyConfigRequest {
  companyName: string;
  companyAddress: string;
  contactNumber: string;
  taxPoint: number;
  opStaffList: Array<PropertyManagementConfigOperationStaffRequest>;
  billTitle: string;
}

export interface CreatePropertyManagementConfigRequest {
  customerMcid: string;
  salesOrg: string;
  invoiceAddress: string;
  taxId: string;
  beneficialBank: string;
  bankAccount: string;
  businessScopes: BusinessScope;
  electricityDistributionSalesConfig: ElectricityDistributionSalesConfigRequest;
  propertyManagementNewEnergyConfig: PropertyManagementNewEnergyConfigRequest;
}

export function createPropertyManagementConfig(request: CreatePropertyManagementConfigRequest) {
  return fetch<{ configId: number }>(
    `/api/v2/propertyManagementConfig/create`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ElectricityDistributionSalesConfigResponse
  extends Omit<ElectricityDistributionSalesConfigRequest, 'opStaffList'> {
  opStaffList: Array<PropertyManagementConfigOperationStaffResponse>;
}

export interface PropertyManagementNewEnergyConfigResponse
  extends Omit<PropertyManagementNewEnergyConfigRequest, 'opStaffList'> {
  opStaffList: Array<PropertyManagementConfigOperationStaffResponse>;
}

export interface PropertyManagementConfigResponse
  extends Omit<
    CreatePropertyManagementConfigRequest,
    'electricityDistributionSalesConfig' | 'propertyManagementNewEnergyConfig'
  > {
  id: number;
  code: string;
  customerName: string;
  electricityDistributionSalesConfig: ElectricityDistributionSalesConfigResponse;
  propertyManagementNewEnergyConfig: PropertyManagementNewEnergyConfigResponse;
}

export function getPropertyManagementConfig(id: Key) {
  return fetch<PropertyManagementConfigResponse>(
    `/api/v2/propertyManagementConfig/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface UpdatePropertyManagementConfigRequest
  extends Omit<CreatePropertyManagementConfigRequest, 'customerMcid'> {
  id: number;
}

export function updatePropertyManagementConfig(request: UpdatePropertyManagementConfigRequest) {
  return fetch<{ result: boolean }>(
    `/api/v2/propertyManagementConfig/update`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface PropMgmtDeptPageRequest extends PageRequest {
  salesOrg?: string;
  code?: string;
  customerMcid?: string;
}

export interface PropMgmtDeptPageResponse {
  id: number;
  salesOrg: string;
  code: string;
  createTime: string;
  updateTime: string;
}

// 获取物业管理配置分页
export function getPropMgmtDeptList(params: PropMgmtDeptPageRequest): Promise<Paged<PropMgmtDeptPageResponse>> {
  return fetch(
    `/api/v2/propertyManagementConfig/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
