import { FC, ReactNode, CSSProperties } from 'react';

const ShowInput: FC<{ value?: ReactNode; style?: CSSProperties }> = ({ value, style }) => (
  <div
    style={{
      ...style,
      overflowWrap: 'break-word',
    }}
  >
    {value ?? '--'}
  </div>
);

export default ShowInput;
