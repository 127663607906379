import { Spin } from 'antd';
import { useState } from 'react';
import styles from './index.module.scss';
interface Iprops {
  id: string;
  src: string;
}
const IframeView: React.FC<Iprops> = ({ id, src }) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <div className={styles.iframeView}>
        <Spin className={styles.iframeSpin} spinning={loading}>
          <iframe
            onLoad={() => setLoading(false)}
            title={id}
            src={src}
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="auto"
            sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-top-navigation allow-modals"
          />
        </Spin>
      </div>
    </>
  );
};
export default IframeView;
