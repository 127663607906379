import { DataNode } from 'antd/es/tree';
import { useCallback, useRef } from 'react';
import { OrganizationTreeProps } from '../api/customer';
import { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';

export function useCurrent<T>(value: T): () => T {
  const ref = useRef(value);
  ref.current = value;
  return useCallback(() => ref.current, []);
}

export function classNames(...items: (string | false | null | undefined)[]): string {
  return items.filter(x => !!x).join(' ');
}

export const filterTreeData = (data: DataNode[], key: string): DataNode[] => {
  const res: DataNode[] = [];
  data.forEach((item: any) => {
    if ((item.data?.name as string).includes(key) || (item.data?.code && item.data?.code.includes(key))) {
      res.push(item);
    } else {
      if (item.children && item.children.length) {
        const newChild = filterTreeData(item.children, key);
        if (newChild.length) {
          const newData: DataNode = {
            ...item,
            children: newChild,
          };
          res.push(newData);
        }
      }
    }
  });
  return res;
};

interface AddParentMcidProps extends OrganizationTreeProps {
  parentMcid?: string;
}

function addParentKeyWrapper(tree: OrganizationTreeProps[]) {
  const data = JSON.parse(JSON.stringify(tree));
  function addParentMcid(data: AddParentMcidProps[], parentMcid?: string) {
    data.forEach(ele => {
      const { children, data } = ele;
      ele.parentMcid = parentMcid;
      if (children) {
        addParentMcid(children, data!.mcid);
      }
    });
  }
  addParentMcid(data, '');
  return data;
}

export function getParent(tree: OrganizationTreeProps[], id: string) {
  let arrRes: string[] = [];
  if (tree.length === 0) {
    if (!!id) {
      arrRes.unshift(id);
    }
    return arrRes;
  }
  const newTree = addParentKeyWrapper(tree);
  const rev = (data: AddParentMcidProps[], nodeId?: string) => {
    for (let i = 0, length = data.length; i < length; i++) {
      let node = data[i];
      if (node.data?.mcid === nodeId) {
        arrRes.unshift(nodeId!);
        rev(newTree, node.parentMcid);
        break;
      } else {
        if (!!node.children) {
          rev(node.children, nodeId);
        }
      }
    }
    return arrRes;
  };
  arrRes = rev(newTree, id);
  return arrRes;
}

export function formatTreeData(data: OrganizationTreeProps[]) {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    return res;
  });
}
