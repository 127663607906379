import React, { useState } from 'react';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CustomerUser } from '../../../api/staff';
import { Form, FormTitle, Modal, SubContent, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import usePageStatus, { PageStatus, PageStatusDisplay } from '../../RolePermissions/Edit/usePageStatus';
import CreateUser from './CreateUser';
import CreateStaff from './CreateStaff';
import styles from './index.module.scss';

const { useForm } = Form;

const Create: React.FC = () => {
  const [createUserRes, setCreateUserRes] = useState<CustomerUser>();
  const [createUserForm] = useForm();
  const [createStaffForm] = useForm();
  const navigate = useNavigate();
  const pageStatus = usePageStatus();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const routes = [{ name: `${PageStatusDisplay[pageStatus!]}员工` }];

  const saveCreateUserRes = (data: CustomerUser) => {
    setCreateUserRes(data);
  };

  const onClose = () => {
    if (pageStatus !== PageStatus.DETAIL) {
      Modal.confirm({
        content: '确认放弃未保存的修改返回列表吗?',
        onOk: () => {
          navigate(`/system/customer/staff`);
        },
      });
    } else {
      navigate(`/system/customer/staff`);
    }
  };

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle title={`${PageStatusDisplay[pageStatus!]}员工`} />
      <CreateUser
        createUserForm={createUserForm}
        createStaffForm={createStaffForm}
        saveCreateUserRes={saveCreateUserRes}
        onClose={onClose}
      />
      <CreateStaff createUserRes={createUserRes} createStaffForm={createStaffForm} onClose={onClose} />
    </Wrapper>
  );
};

export default Create;
