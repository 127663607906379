import { fetch } from '@maxtropy/components';

export interface PowerTierConfigResponse {
  id: number;
  name: string;
}

export function getPowerTierConfigList() {
  return fetch<{ list: PowerTierConfigResponse[] }>(
    `/api/v2/powerFactorConfig/listTier`,
    {
      method: 'POST',
    },
    true
  );
}

export interface PowerFactorConfigResponse {
  // 显示功率因数
  displayPowerFactor: number;
  // 功率因数始值
  powerFactorStart: number;
  // 功率因数止值
  powerFactorEnd: number;
  // 调整比例值
  scaleRatio: number;
}

export function getPowerFactorConfig(tierConfigId: number) {
  return fetch<{ list: PowerFactorConfigResponse[] }>(
    `/api/v2/powerFactorConfig/listConfig`,
    {
      method: 'POST',
      body: JSON.stringify({ tierConfigId }),
    },
    true
  );
}
