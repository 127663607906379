import React, { useEffect, useState, Key } from 'react';
import { Tree, Spin } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useCurrent } from '@maxtropy/components';
import { OrganizationTreeProps as OrganizationTreeType, getOrganizationTree } from '../../../api/customer';
import { RoleInfo } from '../../../api/role';
import { RoleType, RoleLevel } from '../../../api/const';
import styles from './index.module.scss';

interface OrganizationTreeProps {
  value?: string[];
  onChange?: (checkedKeys: Key[] | { checked: Key[]; halfChecked: Key[] }) => void;
  role?: RoleInfo;
  mcids?: string[];
}

export const transformTree = (data: OrganizationTreeType[]) => {
  return data.map(item => {
    const res: DataNode = {
      ...item,
      key: item.data?.mcid ?? '',
      disabled: !item.data?.hasPermission,
      title: item.data?.name || '',
      children: transformTree(item.children),
    };
    return res;
  });
};

const OrganizationTree: React.FC<OrganizationTreeProps> = props => {
  const { onChange, value, role, mcids } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const current = useCurrent();
  const mcid = current?.tenant?.mcid;

  // prettier-ignore
  const formatTreeData = (data: OrganizationTreeType[], role?: RoleInfo, keys?: string[]) => { // NOSONAR
    return data.map(item => {
      let disabled = false;
      if (role) {
        const { ownerCrMcid, level, type } = role;
        if (level === RoleLevel.TENANT && ownerCrMcid === item.data?.mcid) {
          disabled = true;
        } else if (level === RoleLevel.MGMT && type === RoleType.OWNER) {
          if (mcid && keys?.includes(mcid)) {
            disabled = true;
          }
        }
      }
      const res: DataNode = {
        ...item,
        key: item.data?.mcid ?? '',
        disabled: !item.data?.hasPermission || disabled,
        title: item.data?.name || '',
        children:
          role?.level === RoleLevel.MGMT && role?.type === RoleType.OWNER
            ? formatTreeData(item.children)
            : formatTreeData(item.children, role, mcids),
      };
      return res;
    });
  };

  useEffect(() => {
    if (value) {
      setCheckedKeys(value);
    }
  }, [value]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await getOrganizationTree();
      if (data) {
        const treeData = formatTreeData([data], role, mcids);
        setTreeData(treeData);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, mcids]);

  return (
    <div className={styles.treeWrap}>
      <Spin spinning={loading}>
        {treeData && treeData.length > 0 && (
          <Tree
            defaultExpandAll
            selectable={false}
            checkable
            checkStrictly
            treeData={treeData}
            checkedKeys={checkedKeys}
            onCheck={checkedKeys => {
              onChange && onChange(checkedKeys);
            }}
            disabled={role?.level === RoleLevel.MGMT && role?.type === RoleType.PREFACT}
          />
        )}
      </Spin>
    </div>
  );
};

export default OrganizationTree;
