import React, { useEffect, useState } from 'react';
import { OrganizationTable, DataType, getDefaultNames, Button, Modal, message } from '@maxtropy/components';
import { queryOrganizationLevelList, updateOrganizationLevel } from '../../../api/tenant';

const Customize: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataType[] | undefined>();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    queryOrganizationLevelList().then(data => {
      let dataSource = (data.list ?? []).map(item => ({ key: item.index, name: item.name }));
      if (dataSource.length === 0) {
        dataSource = Array(6)
          .fill('')
          .map((_, index) => ({
            key: index,
            name: getDefaultNames(index),
          }));
      }
      setDataSource(dataSource);
    });
  }, []);

  const onDataSourceChange = (value: DataType[]) => {
    setDataSource(value);
  };

  const onClose = () => {
    if (!disabled) {
      Modal.confirm({
        content: '确认放弃保存修改返回查看吗？',
        onOk: () => {
          setDisabled(true);
        },
      });
    } else {
      setDisabled(true);
    }
  };

  const submit = () => {
    const tenantOrganizationLevelUpdList = (dataSource ?? []).map(item => ({
      name: item.name,
      index: Number(item.key),
    }));
    updateOrganizationLevel({ tenantOrganizationLevelUpdList });
    message.success('保存成功');
    setDisabled(true);
  };

  return (
    <>
      <OrganizationTable dataSource={dataSource} onDataSourceChange={onDataSourceChange} disabled={disabled} />
      <div style={{ marginTop: 50 }}>
        {disabled && (
          <Button type="primary" onClick={() => setDisabled(false)}>
            编辑
          </Button>
        )}
        {!disabled && (
          <Button type="primary" onClick={submit}>
            保存
          </Button>
        )}
        {!disabled && (
          <Button style={{ marginLeft: 8 }} onClick={onClose}>
            退出编辑状态
          </Button>
        )}
      </div>
    </>
  );
};

export default Customize;
