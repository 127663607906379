import { TreeDataNode as DataNode } from '@maxtropy/components';
import { isNil } from 'lodash-es';

export function findTopLevelId(treeData: any[], key: string): string | null {
  for (const item of treeData) {
    if (item.key === key) {
      return item.key;
    }

    if (item.children) {
      const foundInChild = findTopLevelId(item.children, key);
      if (foundInChild) {
        return item.key;
      }
    }
  }

  return null;
}

export function getAllKeys(node: any): string[] {
  const keys = [node.key];

  if (node.children && node.children.length > 0) {
    node.children.forEach((child: any) => {
      keys.push(...getAllKeys(child));
    });
  }

  return keys;
}

export function findNodeByKey(tree: any[], key: string): any | null {
  for (const node of tree) {
    if (node.key === key) {
      return node;
    } else if (node.children && node.children.length > 0) {
      const childResult = findNodeByKey(node.children, key);
      if (childResult) {
        return childResult;
      }
    }
  }
  return null;
}

// export function findNodeByKey(tree: any[], key: string): any[] {
//   for (const node of tree) {
//     if (node.key === key) {
//       return getAllKeys(node);
//     } else if (node.children && node.children.length > 0) {
//       const childResult = findNodeByKey(node.children, key);
//       if (childResult.length > 0) {
//         return childResult;
//       }
//     }
//   }
//   return [];
// }

export function findKeysByCode(treeData: any[], code: string): string[] {
  let keys: string[] = [];
  const loop = (nodeData: any) => {
    for (const item of nodeData) {
      if (item.code === code) {
        keys.push(item.key); // 如果code匹配，返回当前item的key
      }

      if (item.children) {
        loop(item.children);
      }
    }
  };
  loop(treeData);
  return keys; // 如果没有在树中找到相应的code，返回null
}

export const findCodeByKey = (nodes: DataNode[], targetKey: string): string | null => {
  for (const node of nodes) {
    if (node.key === targetKey && node.level !== 1) {
      return node.code || null;
    }

    if (node.children.length > 0) {
      const code = findCodeByKey(node.children, targetKey);
      if (code) {
        return code;
      }
    }
  }

  return null;
};

// 小程序数据树处理
export const convertToDataNode = (data: any[], parentId: string | null = null, level: number = 1): DataNode[] => {
  return data.map(item => {
    const currentId = `${item.id}`;
    const combinedKey = parentId ? `${parentId}-${currentId}` : currentId;

    let node: DataNode = {
      key: combinedKey,
      title: item.name,
      description: item.description ?? '',
      level,
      freeze: level === 3 && isNil(item.code),
      code: item.code,
      children: [] as DataNode[],
    };

    if (item.children && item.children.length > 0) {
      node.children = convertToDataNode(item.children, currentId, level + 1);
    }

    node.isLeaf = node.children.length === 0;

    return node;
  });
};

// 勾选全部子节点自动勾选父节点
export const checkNodeAndChildren = (node: DataNode, keys: (string | number)[]): (string | number)[] => {
  let newKeys: (string | number)[] = [];

  // 如果该节点是叶子节点并且在keys中，直接返回该节点的key
  if (node.isLeaf || !node.children || node.children.length === 0) {
    return keys.includes(node.key) ? [node.key] : [];
  }

  // 使用递归检查所有子节点
  const allChildrenKeys = node.children.flatMap(child => checkNodeAndChildren(child, keys));

  // 如果子节点中至少有一个被选中，则将该节点的key加入到newKeys中
  if (allChildrenKeys.some(childKey => keys.includes(childKey))) {
    newKeys.push(node.key);
  }

  // 返回选中的子节点keys以及当前节点的key（如果它被选中）
  return newKeys.concat(allChildrenKeys.filter(key => keys.includes(key)));
};

export const findParentKey = (treeData: DataNode[], childKey: string): string | null => {
  for (let node of treeData) {
    if (node.children && node.children.some(child => String(child.key) === childKey)) {
      return String(node.key);
    }
    if (node.children) {
      const deeperParent = findParentKey(node.children, childKey);
      if (deeperParent) return deeperParent;
    }
  }
  return null;
};

export const getParentKeys = (treeData: DataNode[], selectedKeys: string[]): string[] => {
  const parentKeys: Set<string> = new Set();

  selectedKeys.forEach(key => {
    const parent = findParentKey(treeData, key);
    if (parent) {
      parentKeys.add(parent);
    }
  });

  return Array.from(parentKeys);
};
