import { InfoCircleOutlined } from '@ant-design/icons';
import { EllipsisSpan, Table, Button, Tooltip, Modal, message } from '@maxtropy/components';
import { Row, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { createUsers, getAccountList, QlStaffProps } from '../../../api/ql-user';
import styles from '../index.module.scss';

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface Iprops {
  deptId?: number;
  updateP?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}
const AccountAddModal: React.FC<Iprops> = ({ onCancel, onConfirm, updateP }) => {
  const [selectedId, setSelectedId] = useState<number[]>();
  const currentRows = useRef<QlStaffProps[]>([]);
  const [dataList, setDataList] = useState<QlStaffProps[]>([]);
  useEffect(() => {
    getAccountList().then(res => {
      setDataList(res.list ?? []);
    });
  }, []);
  // 添加账户确认按钮
  const addAccount = () => {
    let staffIds = currentRows.current.map(item => item.id!);
    if (!selectedId || selectedId.length <= 0) {
      message.error('请选择账户');
      return;
    }
    createUsers(staffIds).then(res => {
      if (res.result) {
        onConfirm?.();
        return;
      }
      Modal.error({
        title: '添加失败，请核对账户数使用情况。',
      });
    });
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], row: QlStaffProps[]) => {
      currentRows.current = row;
      let ids = selectedRowKeys as number[];
      setSelectedId(ids);
    },
    selectedRowKeys: selectedId,
  };
  return (
    <Modal
      open
      size="normal"
      title={
        <Space size={10}>
          添加账户
          <Tooltip title="添加员工默认启用，且在最高权限集合下。">
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
          </Tooltip>
        </Space>
      }
      onCancel={() => onCancel?.()}
      footer={
        <Row justify="end">
          <Button type="primary" onClick={addAccount}>
            确认
          </Button>
        </Row>
      }
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        rowKey="id"
        scroll={{ y: 450 }}
        columns={columns}
        dataSource={dataList}
      />
      {dataList.length > 0 && (
        <div className={styles.hasSelectBox}>
          已选择<span className={styles.hasSelectNum}>{selectedId?.length ?? 0}</span>个
        </div>
      )}
    </Modal>
  );
};

export default AccountAddModal;
