export interface PageResponse<T> {
  page: number;
  size: number;
  total: number;
  list: Array<T>;
}

export interface PageRequest {
  page: number;
  size: number;
}
export interface PageRequestDates {
  page: string;
  size: string;
}

export enum OuStaffType {
  MEMBER,
  ADMINISTRATOR,
}

export const OuStaffTypeDisplay = {
  [OuStaffType.MEMBER]: '成员',
  [OuStaffType.ADMINISTRATOR]: '管理员',
};
