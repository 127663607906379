// 正整数
export const positiveIntegerRegex = /^[0-9]*[1-9][0-9]*$/;

//电话号码
export const commonPhoneRegex = /^(?:1\d{10}|0\d{2,3}-?\d{7,8})$/;

//手机号码
export const commonMobilePhoneRegex = /^\d{11}$/;

//长度5~20个字符，支持中文、英文及数字并且不能为纯数字
export const commonAccountRegex = /^(?!\d+$)[\dA-Za-z\u{3400}-\u{4dbf}\u{4e00}-\u{9fff}\u{20000}-\u{2a6df}]{5,20}$/u;

//长度5~20个字符，支持英文、数字、特殊字符和.并且不能为纯数字
export const commonUsernameRegex = /^(?!\d+$)[\dA-Za-z.!@#$%^&*()_+]{5,20}$/;

//长度0~12个字符，支持英文及数字并且不能为纯数字
export const commonCodeRegex = /^(?!\d+$)[\dA-Za-z]{0,12}$/u;

export const staffCodeRegex = /^(?=[a-zA-Z]*[0-9])(?=[0-9]*[a-zA-Z])[a-zA-Z0-9]{0,12}$/;

interface CommonNameRegexOptions {
  start?: number;
  end?: number;
}

export const commonNameRegex = (options: CommonNameRegexOptions = { start: 5, end: 20 }) =>
  new RegExp(
    `^[\\dA-Za-z\\u{3400}-\\u{4dbf}\\u{4e00}-\\u{9fff}\\u{20000}-\\u{2a6df}]{${options.start},${options.end}}$`,
    'u'
  );
