import React, { useEffect, useState } from 'react';
import { Space, Spin, Transfer } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { useBreadcrumbRoutes, Wrapper, Button, useSubmission, Modal, Checkbox, Form } from '@maxtropy/components';
import { getStaffRoleList, bindRoleStaff, unBindRoleStaff } from '../../../api/staff';
import { findRoleByCode, RoleInfo } from '../../../api/role';
import { RoleSteps } from '../../../components/CommonSteps';
import styles from './index.module.scss';

const routes = [{ name: '编辑角色权限' }];

interface TransferItemType {
  key: string;
  title: string;
  description?: string;
  disabled?: boolean;
}

const BindStaff: React.FC = () => {
  const [children, setChildren] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransferItemType[]>([]);
  const [initialBindKeys, setInitialBindKeys] = useState<string[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [role, setRole] = useState<RoleInfo>();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { code } = useParams<{ code: string }>();
  const [doSubmit, submitting] = useSubmission();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (!code) return;
    findRoleByCode(code).then(data => {
      setRole(data);
    });
  }, [code]);

  useEffect(() => {
    (async () => {
      if (!isNil(code)) {
        setLoading(true);
        const res = await getStaffRoleList(code, children);
        const { bind, unbind } = res.map;
        const bindIds = bind.map(item => `${item.id}`);
        const allStaffs = bind.concat(unbind).map(item => ({
          key: `${item.id}`,
          title: `${item.staffCode}(${item.name ?? '--'})/${item.roleName ?? '--'}`,
          disabled: !item.staffCode,
        }));
        setData(allStaffs);
        setTargetKeys(bindIds);
        setInitialBindKeys(bindIds);
        setLoading(false);
      }
    })();
  }, [code, children]);

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onClose = () => {
    Modal.confirm({
      content: '确认放弃未保存的修改返回列表吗?',
      onOk: () => {
        navigate(`/system/rolePer/role`);
      },
    });
  };

  const countDown = () => {
    const url = '/system/rolePer/role';
    let secondsToGo = 10;
    const modalInstance = Modal.success({
      title: '操作成功',
      content: `对角色CODE为【${code}】, 角色名称为【${role?.name}】的修改完成`,
      okText: `确认(${secondsToGo}秒)`,
      onOk: () => {
        navigate(url);
        clearInterval(timer);
      },
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      if (secondsToGo !== 0) {
        modalInstance.update({
          okText: `确认(${secondsToGo}秒)`,
        });
      } else navigate(url);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modalInstance.destroy();
    }, secondsToGo * 1000);
  };

  const onFinish = async () => {
    const idsAdd = targetKeys.filter(key => !initialBindKeys.includes(key));
    const idsDelete = initialBindKeys.filter(key => !targetKeys.includes(key));
    try {
      await doSubmit(async () => {
        if (targetKeys && idsAdd.length > 0) {
          await bindRoleStaff({
            roleCode: code!,
            staffIds: idsAdd,
          });
        }
        if (targetKeys && idsDelete.length > 0) {
          await unBindRoleStaff({
            roleCode: code!,
            staffIds: idsDelete,
          });
        }
        countDown();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { includeChildren } = changedValues;
    setChildren(includeChildren);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <RoleSteps current={3} isDetail={false} />
      <div className={styles.formWrapper} style={{ width: 540, margin: '0 auto' }}>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="horizontal"
            className={styles.form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label="匹配员工"
              name="includeChildren"
              valuePropName="checked"
              labelCol={{ style: { width: 70 } }}
            >
              <Checkbox>包括子组织员工</Checkbox>
            </Form.Item>
            <Transfer
              dataSource={data}
              titles={['未绑定该角色员工', '已绑定角色员工']}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={onChange}
              onSelectChange={onSelectChange}
              render={(item: TransferItemType) => item.title}
              className={styles.transfer}
            />
            <Form.Item>
              <Space size={8}>
                <Button onClick={() => navigate(`/system/rolePer/role/permissions/bind/organization/${code}`)}>
                  上一步
                </Button>
                <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
                  提交
                </Button>
                <Button onClick={onClose}>关闭</Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Wrapper>
  );
};

export default BindStaff;
