import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Col, Row } from 'antd';
import Manage from './components/Manage';
import Customize from './components/Customize';
import { useCurrent, Wrapper, getRealUrl, useBreadcrumbRoutes, Tabs } from '@maxtropy/components';
import { getDefaultLogo } from '../../api/file';
import { useHasPermission } from '../../utils/hooks';
import { PermissionsType } from '../../common/permissionsConst';

const { TabPane } = Tabs;

enum TabKey {
  MANAGE = 'manage',
  CUSTOMIZE = 'customize',
}

const Organization: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [logo, setLogo] = useState<string>();
  const user = useCurrent();
  const isRootNode = user?.customer?.mcid === user?.tenant?.mcid;

  const allowUpdateCustomer = useHasPermission(PermissionsType.PUPDATECUSTOMER);
  const allowTenantPersonalized = useHasPermission(PermissionsType.PTENANTPERSONALIZED);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const tabContent: Record<TabKey, React.ReactElement> = {
    manage: <Manage user={user} isRootNode={isRootNode} />,
    customize: <Customize />,
  };

  const tabs = useMemo(
    () => [
      {
        name: '组织管理',
        key: TabKey.MANAGE,
        enable: allowUpdateCustomer,
      },
      {
        name: '组织名自定义',
        key: TabKey.CUSTOMIZE,
        enable: allowTenantPersonalized,
      },
    ],
    [allowTenantPersonalized, allowUpdateCustomer]
  );

  useEffect(() => {
    if (user) {
      if (user.customer?.logoUrl) {
        setLogo(getRealUrl(user.customer.logoUrl));
      } else {
        getDefaultLogo().then(data => {
          setLogo(getRealUrl(data.key));
        });
      }
    }
  }, [user]);

  useEffect(() => {
    setActiveKey(tabs.find(item => item.enable)?.key ?? undefined);
  }, [tabs]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <div className={styles.background}>
        <Row>
          <Col>
            <img style={{ maxWidth: 200, maxHeight: 64 }} src={logo} alt="" />
          </Col>
          <Col offset={1}>
            <p className={styles.code}>{user?.tenant?.tenantCode}</p>
            <p className={styles.company}>
              {user?.customer?.fullName + `${isRootNode ? `-${user?.tenant?.name}` : ''}`}
            </p>
          </Col>
        </Row>
      </div>
      <div className={styles.background} style={{ minHeight: 'calc(100vh - 230px)' }}>
        <Tabs activeKey={activeKey} onChange={v => setActiveKey(v)}>
          {tabs.map(item =>
            item.enable ? (
              <TabPane tab={item.name} key={item.key}>
                {tabContent[item.key]}
              </TabPane>
            ) : (
              <div key={item.key} />
            )
          )}
        </Tabs>
      </div>
    </Wrapper>
  );
};

export default Organization;
