import { checkNameUnique } from '../../../api/customer';

export const checkFullName = async (rule: any, value: string, beforeName?: string) => {
  if (!value || !value.trim()) {
    return Promise.reject('请输入组织全称');
  } else if (value.length > 30) {
    return Promise.reject('最多30个字符');
  } else {
    if (value !== beforeName) {
      const res = await checkNameUnique({ fullName: value });
      if (res.checkResult) {
        return Promise.reject('组织全称已被使用！');
      }
    }
    return Promise.resolve();
  }
};

export const checkName = async (rule: any, value: string, beforeName?: string) => {
  if (!value || !value.trim()) {
    return Promise.reject('请输入组织简称');
  } else if (value.length > 8) {
    return Promise.reject('最多8个字符');
  } else {
    if (value !== beforeName) {
      const res = await checkNameUnique({ name: value });
      if (res.checkResult) {
        return Promise.reject('组织简称已被使用！');
      }
    }
    return Promise.resolve();
  }
};
