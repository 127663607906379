import React from 'react';
import { Card, Space, Typography } from 'antd';
import styles from '../screen.module.scss';
import LampImage from './LampImage';
import { NavigationDetail } from '../../../api/navScreen';
import NavButton from './NavButton';

type EnergyAssetProps = {
  headImageUrl: string;
  statistic?: number;
  statisticTitle: string;
  navDetails?: NavigationDetail[];
};

// 能源资产管理卡片
export default function EnergyAssetCard({ headImageUrl, statistic, statisticTitle, navDetails }: EnergyAssetProps) {
  return (
    <Card className={styles.navCard}>
      <div
        style={{
          height: '100%',
          minHeight: '19vh',
          display: 'flex',
          // justifyContent: 'space-between',
          // alignItems: 'center',
        }}
      >
        <div style={{ marginRight: '12px', alignSelf: 'center' }}>
          <LampImage headImageUrl={headImageUrl} />
        </div>
        <div style={{ marginRight: 'auto', alignSelf: 'center' }}>
          <Space direction="vertical">
            <Typography.Title level={2} style={{ color: '#52E7FF' }}>
              {statistic ?? '--'}
            </Typography.Title>
            <Typography.Text>{statisticTitle}</Typography.Text>
          </Space>
        </div>
        {navDetails ? (
          <div style={{ alignSelf: 'flex-start' }}>
            <Space direction="vertical" size={12}>
              {navDetails?.map(n => (
                <NavButton navDetail={n} key={n.id} />
              ))}
            </Space>
          </div>
        ) : null}
      </div>
    </Card>
  );
}
