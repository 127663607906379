import React from 'react';
import { Avatar, Space, Typography } from 'antd';
import styles from '../screen.module.scss';

type BannerProps = {
  title: string;
  titleBackground: string;
  icon?: React.ReactNode;
  image?: string | React.ReactNode;
  iconBackground: string;
};

export default function BusinessTypeBanner({ title, titleBackground, icon, image, iconBackground }: BannerProps) {
  return (
    <Space size={0} className={styles.navBanner}>
      <Avatar
        style={{
          background: iconBackground,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        shape="square"
        icon={icon}
        src={image}
      />
      <div
        style={{
          height: '32px',
          padding: '4px 8px',
          background: titleBackground,
        }}
      >
        <Typography.Title level={5}>{title}</Typography.Title>
      </div>
    </Space>
  );
}
