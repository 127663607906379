import {
  Form,
  useBreadcrumbRoutes,
  Wrapper,
  SubContent,
  Button,
  BaseInfoContent,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserStatusDisplay } from '../../../api/const';
import { getOuStaffAndUserResponse, StaffAndUserResponse } from '../../../api/staff';
import ShowInput from '../../../components/ShowInput';
import OuList from './OuList';

import styles from './index.module.scss';

const OuMange: FC = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [staffInfo, setStaffInfo] = useState<StaffAndUserResponse>();

  useEffect(() => {
    if (id) {
      getOuStaffAndUserResponse(id).then(res => {
        setStaffInfo(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (staffInfo) {
      form.setFieldsValue({
        ...staffInfo,
        status: UserStatusDisplay[staffInfo.status],
      });
    }
  }, [staffInfo, form]);

  const goList = useCallback(() => {
    navigate(`/system/customer/staff`);
  }, [navigate]);

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), { name: `运营单元` }]}>
      <FormTitle title="运营单元" />
      <SubContent>
        <Form form={form} style={{ marginBottom: 32 }}>
          <BaseInfoContent title="员工信息" layout="horizontal">
            <Form.Item name="staffCode" label="员工工号">
              <ShowInput />
            </Form.Item>
            <Form.Item name="name" label="姓名">
              <ShowInput />
            </Form.Item>
            <Form.Item name="cellPhone" label="手机号">
              <ShowInput />
            </Form.Item>
            <Form.Item name="customerFullName" label="所在组织全称">
              <ShowInput />
            </Form.Item>
            <Form.Item name="status" label="员工工号状态">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </Form>
        <OuList staffId={staffInfo?.id} />
        <Space>
          <Button onClick={goList}>返回</Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default OuMange;
