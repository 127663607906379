import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Table, Button, Form, Select, Modal, Radio, Input } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import {
  PropertyManagementConfigOperationStaffStatus,
  PropertyManagementConfigOperationStaffStatusDisplay,
  WorkDivisionEnum,
  WorkDivisionEnumDisplay,
} from '../../../../api/const';
import {
  PropertyManagementConfigOperationStaffRequest,
  PropertyManagementConfigOperationStaffResponse,
} from '../../../../api/propertyManagementConfig';
import { getListStaffByMcid, StaffByMcidResponse } from '../../../../api/staff';

export interface IPropMgmtDeptBusinessValue extends Omit<PropertyManagementConfigOperationStaffResponse, 'id'> {
  id?: number;
}

export interface SelectPropMgmtDeptBusinessStaffProps {
  mcid?: string;
  value?: IPropMgmtDeptBusinessValue[];
  onChange?: (value: IPropMgmtDeptBusinessValue[]) => void;
  disabled?: boolean;
}

const columns: ColumnType<IPropMgmtDeptBusinessValue>[] = [
  {
    title: '序号',
    dataIndex: 'order',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string, rows: IPropMgmtDeptBusinessValue, index: number) => <EllipsisSpan value={index + 1} />,
  },
  {
    title: '姓名',
    dataIndex: 'name',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电话',
    dataIndex: 'tel',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '分工类型',
    dataIndex: 'workDivision',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: WorkDivisionEnum) => <EllipsisSpan value={WorkDivisionEnumDisplay[v]} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: PropertyManagementConfigOperationStaffStatus) => (
      <EllipsisSpan value={PropertyManagementConfigOperationStaffStatusDisplay[v]} />
    ),
  },
  {
    title: '备注',
    dataIndex: 'description',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生成时间',
    dataIndex: 'createTime',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const SelectPropMgmtDeptBusinessStaff: FC<SelectPropMgmtDeptBusinessStaffProps> = ({
  disabled = false,
  value,
  onChange,
  mcid,
}) => {
  const [form] = Form.useForm();

  const [opened, setOpened] = useState<boolean>(false);
  const [current, setCurrent] = useState<IPropMgmtDeptBusinessValue>();

  const [staffs, setStaffs] = useState<Array<StaffByMcidResponse>>([]);

  const [staffId, setStaffId] = useState<number>();

  useEffect(() => {
    if (mcid) {
      getListStaffByMcid(mcid).then(res => setStaffs(res.list ?? []));
    }
  }, [mcid]);

  const onAdd = () => {
    if (mcid === undefined) {
      Modal.warning({
        title: '请先选择关联组织',
      });
      return;
    }
    setOpened(true);
  };

  const onEdit = (record: IPropMgmtDeptBusinessValue) => {
    setCurrent(record);
  };

  useEffect(() => {
    if (current) {
      form.setFieldsValue({
        staffId: current.staffId,
        workDivision: current.workDivision,
        status: current.status,
        description: current.description,
      });
    }
  }, [current, form]);

  const onOk = () => {
    form.validateFields().then((data: PropertyManagementConfigOperationStaffRequest) => {
      const currentStaff = staffs.find(i => i.staffId === data.staffId);
      if (currentStaff) {
        const staffData = {
          staffCode: currentStaff.staffCode,
          name: currentStaff.name,
          tel: currentStaff.cellphone,
        };
        if (current) {
          // 编辑
          const index = (value ?? []).findIndex(i => i === current);
          onChange?.([
            ...(value ?? []).slice(0, index),
            {
              ...current,
              ...data,
              ...staffData,
            },
            ...(value ?? []).slice(index + 1),
          ]);
        } else {
          // 新增
          onChange?.([
            ...(value ?? []),
            {
              ...data,
              ...staffData,
            },
          ]);
        }
      }
      onClose();
    });
  };

  const onClose = () => {
    setOpened(false);
    setCurrent(undefined);
    setStaffId(undefined);
    form.resetFields();
  };

  const buildColumns = [
    ...columns,
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            fixed: 'right' as 'right',
            width: 280,
            render: (record: IPropMgmtDeptBusinessValue) => (
              <Button
                type="link"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                  onEdit(record);
                }}
              >
                编辑
              </Button>
            ),
          },
        ]),
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {!disabled && (
          <Space>
            <Button type="primary" onClick={onAdd}>
              <PlusOutlined />
              添加审单人员
            </Button>
          </Space>
        )}
        <Table
          rowKey={record => `${record.staffId}-${record.workDivision}`}
          scroll={{ x: 1300, y: 400 }}
          dataSource={value ?? []}
          columns={buildColumns}
        />
      </Space>
      <Modal
        title="添加审单人员"
        width={600}
        open={!!current || opened}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form style={{ width: 450 }} form={form} labelCol={{ flex: '110px' }} labelAlign="right">
          <Form.Item name="staffId" label="姓名" rules={[{ required: true, message: '请选择员工' }]}>
            <Select
              placeholder="请选择"
              optionFilterProp="label"
              onChange={v => {
                setStaffId(v);
              }}
              showSearch
              options={staffs.map(i => ({
                label: `${i.name}  ${i.cellphone}  ${i.staffCode}`,
                value: i.staffId,
              }))}
            />
          </Form.Item>
          <Form.Item
            dependencies={['staffId']}
            name="workDivision"
            label="分工类型"
            rules={[
              { required: true, message: '请选择分工类型' },
              ({ getFieldValue }) => ({
                validator(_, v: WorkDivisionEnum | undefined) {
                  const staffId: number | undefined = getFieldValue('staffId');
                  if (staffId && v) {
                    const filterData = (value ?? []).filter(i => i !== current);
                    const find = filterData.find(i => i.staffId === staffId && i.workDivision === v);
                    if (find) {
                      return Promise.reject(new Error('该员工下已经存在该分工类型'));
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              placeholder="请选择"
              disabled={staffId === undefined}
              options={[
                // {label: WorkDivisionEnumDisplay[WorkDivisionEnum.METERREADER], value: WorkDivisionEnum.METERREADER},
                { label: WorkDivisionEnumDisplay[WorkDivisionEnum.AUDITOR], value: WorkDivisionEnum.AUDITOR },
                { label: WorkDivisionEnumDisplay[WorkDivisionEnum.ACCOUNTANT], value: WorkDivisionEnum.ACCOUNTANT },
                { label: WorkDivisionEnumDisplay[WorkDivisionEnum.REVIEWER], value: WorkDivisionEnum.REVIEWER },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label="员工状态"
            rules={[{ required: true, message: '请选择员工状态' }]}
            initialValue={PropertyManagementConfigOperationStaffStatus.ENABLE}
          >
            <Radio.Group>
              <Radio value={PropertyManagementConfigOperationStaffStatus.ENABLE}>
                {
                  PropertyManagementConfigOperationStaffStatusDisplay[
                    PropertyManagementConfigOperationStaffStatus.ENABLE
                  ]
                }
              </Radio>
              <Radio value={PropertyManagementConfigOperationStaffStatus.DISABLE}>
                {
                  PropertyManagementConfigOperationStaffStatusDisplay[
                    PropertyManagementConfigOperationStaffStatus.DISABLE
                  ]
                }
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="description" label="备注">
            <Input.TextArea maxLength={500} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SelectPropMgmtDeptBusinessStaff;
