import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { DataNode } from 'antd/es/tree';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { Tree, Modal, Button } from '@maxtropy/components';

interface ChooseOrganizationFormItemProps {
  treeData: DataNode[];
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

function findTreeTitle(treeData: DataNode[], id: string) {
  let hasFound = false,
    result = null;
  const fn = (data: DataNode[]) => {
    if (Array.isArray(data) && !hasFound) {
      data.forEach(item => {
        if (item.key === id) {
          result = item.title;
          hasFound = true;
        } else if (item.children) {
          fn(item.children);
        }
      });
    }
  };
  fn(treeData);
  return result;
}

const ChooseOrganizationFormItem: React.FC<ChooseOrganizationFormItemProps> = props => {
  const { treeData, onChange, value, disabled } = props;

  const [opened, setOpened] = useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = useState<{ key: string; title?: string }>();
  const [checkedTitle, setCheckedTitle] = useState<string>('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (value) {
      setCheckedKeys({
        key: value,
      });
      setCheckedTitle(findTreeTitle(treeData, value) ?? '');
    }
  }, [value, treeData]);

  useEffect(() => {
    if (treeData) {
      setExpandedKeys(treeData && treeData.length > 0 ? [`${treeData[0].key}`] : []);
    }
  }, [treeData]);

  return (
    <>
      <Space>
        {checkedTitle}
        <Button className={styles.chooseBtn} disabled={disabled} onClick={() => setOpened(true)}>
          <PlusOutlined />
          选择组织
        </Button>
      </Space>
      <Modal
        open={opened}
        title="选择员工所在组织"
        onCancel={() => {
          setOpened(false);
        }}
        onOk={() => {
          onChange && checkedKeys && onChange(checkedKeys.key);
          setCheckedTitle(checkedKeys?.title ?? checkedTitle);
          setOpened(false);
        }}
      >
        <Tree
          selectable={false}
          checkable
          checkStrictly
          treeData={treeData}
          checkedKeys={[checkedKeys?.key ?? '']}
          expandedKeys={expandedKeys}
          onCheck={(checkedKeys: any, info: any) => {
            setCheckedKeys({
              title: info.node.title,
              key: info.node.key,
            });
          }}
          onExpand={expandedKeys => setExpandedKeys(expandedKeys as string[])}
        />
      </Modal>
    </>
  );
};

export default ChooseOrganizationFormItem;
