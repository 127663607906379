import { Form, Input, Modal, Select } from '@maxtropy/components';

import { useEffect } from 'react';

import {
  NavItem,
  TabKey,
  UpdateNavBody,
  updateNav,
  HrefTypeDisPlay,
  HrefType,
} from '../../../../api/compositeNavConfig';

const { TextArea } = Input;

interface Props {
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  data?: NavItem;
  updateFn: () => void;
}

const hrefTypeOptions = [
  {
    label: HrefTypeDisPlay[HrefType.NEWWINDOW],
    value: HrefType.NEWWINDOW,
  },
  {
    label: HrefTypeDisPlay[HrefType.CURWINDOW],
    value: HrefType.CURWINDOW,
  },
];

const PlatformNavEditModal: React.FC<Props> = ({ modalOpen, setModalOpen, data, updateFn }) => {
  const [form] = Form.useForm();

  // 获取分组列表
  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
      comprehensiveMenuGroupName: data?.comprehensiveMenuGroupName,
      permissionName: data?.functionPermissionCodeFullName.replaceAll(' ', '/'),
      path: data?.path,
      hrefType: data?.hrefType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setModalOpen(false);
  };

  const onFinish = (v: UpdateNavBody) => {
    if (data?.id) {
      const body = {
        id: data.id,
        name: v.name,
        hrefType: v.hrefType,
        type: TabKey.PLATFORM,
      };
      updateNav(data.id, body).then(res => {
        if (res.influencedLineAmount) {
          updateFn();
        }
      });
      setModalOpen(false);
    }
  };

  return (
    <Modal
      open={modalOpen}
      contentClassName="modal-form-content"
      size="normal"
      title="平台导航编辑"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          hrefType: HrefType.NEWWINDOW,
        }}
      >
        <Form.Item
          label="导航名称"
          name="name"
          rules={[
            { required: true, message: '请输入名称', whitespace: true },
            {
              pattern: /^[\u4e00-\u9fa5]+$/,
              message: '请输入中文',
            },
            { max: 12, message: '最多输入12个中文字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="所属分组" name="comprehensiveMenuGroupName">
          <Input disabled />
        </Form.Item>
        <Form.Item label="对应权限" name="permissionName">
          <Input disabled />
        </Form.Item>
        <Form.Item label="导航链接" name="path">
          <TextArea rows={4} disabled />
        </Form.Item>
        <Form.Item label="跳转方式" name="hrefType" rules={[{ required: true, message: '请选择' }]}>
          <Select options={hrefTypeOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PlatformNavEditModal;
