import React, { useState } from 'react';
import { NavigationDetail } from '../../../api/navScreen';
import { Button } from 'antd';
import styles from '../screen.module.scss';
import { useHasPermission } from '../../../utils/hooks';

type NavButtonProps = {
  // 是否为平台导航按钮
  isPlatform?: boolean;
  navDetail: NavigationDetail;
};

export default function NavButton({ navDetail, isPlatform = true }: NavButtonProps) {
  const hasPermission = useHasPermission(navDetail?.functionPermissionCode ?? '');
  const [hovered, setHovered] = useState(false);

  // 配置导航无需权限, 平台导航需要权限
  return hasPermission || !isPlatform ? (
    <>
      <Button
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => {
          const path = navDetail?.path?.match(/^https?:\/\//i) ? navDetail.path : `http://${navDetail.path}`;
          window.open(path, navDetail.hrefType === 1 ? '_self' : '_blank');
        }}
        className={styles.navButton}
        type="primary"
        ghost
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'rgba(255, 255, 255, 0.85)',
          }}
        >
          {navDetail.name}
        </div>
        {!hovered && <div className={styles.dot} style={{ top: '0px', left: '0px' }} />}
        {!hovered && <div className={styles.dot} style={{ top: '0px', right: '0px' }} />}
        {!hovered && <div className={styles.dot} style={{ bottom: '0px', left: '0px' }} />}
        {!hovered && <div className={styles.dot} style={{ bottom: '0px', right: '0px' }} />}

        {hovered && <div className={styles.triangle} />}
        {hovered && <div className={styles.slash} />}
      </Button>
    </>
  ) : null;
}
