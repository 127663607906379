import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Col, RadioChangeEvent, Row, Space, UploadFile } from 'antd';
import {
  Upload,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Wrapper,
  Button,
  useBreadcrumbRoutes,
  getRealUrl,
  Table,
  Key,
  EllipsisSpan,
  FormTitle,
} from '@maxtropy/components';
import { getDefaultLogo } from '../../api/file';
import {
  createCustomer,
  CreateCustomerProps,
  CustomerProps,
  getCustomer,
  getNextLevelName,
  updateCustomer,
} from '../../api/customer';
import { checkFullName, checkName } from '../Organization/components/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { PlusOutlined } from '@ant-design/icons';
import templateIcon from '../../assets/image/templateIcon.png';
import titleLine from '../../assets/image/titleLine1920.png';
import { apiV2CustomerDataPermissionsStaffsPost } from '@maxtropy/cc-customer-apis-v2';
import StaffSelect from './components/StaffSelect';
import { CommonUserInfo } from './components/StaffSelect/interface';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

enum CheckEnum {
  CHECKED = 1,
}

interface FormCustomerProps {
  name: string;
  fullName: string;
  logoUrl?: string;
  platformTitleConfig?: number[];
  iconUrl?: string;
  platformTitleType?: number;
  platformName?: string;
  homePageLogoConfig?: number[];
  homepageLogoUrl?: string;
  data?: any;
}

enum TitleType {
  PLATFORM = 1,
  NAVIGATE = 2,
  PLATFORMNAVIGATE = 3,
}

const TitleTypeDisplay = {
  [TitleType.PLATFORM]: '平台名称',
  [TitleType.NAVIGATE]: '导航名称',
  [TitleType.PLATFORMNAVIGATE]: '平台名称-导航名称',
};

const DEFAULT_PLATFORM_NAME = 'Maxtropy';

const titleOptions = [
  { label: TitleTypeDisplay[TitleType.PLATFORM], value: TitleType.PLATFORM },
  { label: TitleTypeDisplay[TitleType.NAVIGATE], value: TitleType.NAVIGATE },
  { label: TitleTypeDisplay[TitleType.PLATFORMNAVIGATE], value: TitleType.PLATFORMNAVIGATE },
];

const OrganizationManage: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const platformSloganNameConfig = Form.useWatch('platformSloganNameConfig', form);
  const navigate = useNavigate();
  const [nextLevelName, setNextLevelName] = useState<string>('');
  const [info, setInfo] = useState<CustomerProps>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { mcid } = useParams<{ mcid: string }>();
  const parentMcid = query.get('parentMcid');
  const isAdd = query.get('isAdd') === 'true';
  const name = query.get('name');
  const parentName = query.get('parentName');
  const levelName = query.get('levelName');
  const [visible, setVisible] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSouce, setDatasouce] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<CommonUserInfo[]>([]);
  const [selectedPageRows, setSelectedPageRows] = useState<CommonUserInfo[]>([]);
  const [selectedStaffCodes, setSelectedStaffCodes] = useState<string[]>([]);
  const [disabledStaffCodes, setDisabledStaffCodes] = useState<string[]>([]);

  const data = Form.useWatch('data', form);
  // prettier-ignore
  useEffect(() => { // NOSONAR
    if (!isAdd && !isNil(mcid)) {
      getCustomer(mcid).then(data => {
        form.setFieldsValue({
          ...data,
          platformTitleConfig: data.platformTitleConfig ? [CheckEnum.CHECKED] : [],
          homePageLogoConfig: data.homePageLogoConfig ? [CheckEnum.CHECKED] : [],
          platformTitleType: data.platformTitleType ?? TitleType.PLATFORM,
          platformName: data.platformName ?? DEFAULT_PLATFORM_NAME,
          platformSloganNameConfig: data.platformSloganNameConfig ?? false,
          platformSloganName: data.platformSloganName ?? "工业互联网平台",
        });
     
        setInfo(data);
        if (!data.logoUrl) {
          defaultLogo();
        }
      });
    } else {
      form.setFieldsValue({
        platformSloganNameConfig:  false,
          platformSloganName: "工业互联网平台"
      })
      changeLogo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcid]);

  useEffect(() => {
    if (isAdd && !isNil(mcid)) {
      getNextLevelName(mcid).then(data => {
        setNextLevelName(data.levelName);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdd]);

  useEffect(() => {
    if (isAdd) return;
    setLoading(true);

    apiV2CustomerDataPermissionsStaffsPost({ mcid })
      .then(data => {
        if (isNil(data.list)) return;
        setSelectedPageRows(data.list);
        setDatasouce(data.list);
        form.setFieldsValue({ data: data.list });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcid]);

  useEffect(() => {
    form.setFieldsValue({ data: selectedPageRows });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageRows]);

  useEffect(() => {
    const list = (data ?? []).map((item: CommonUserInfo) => item?.staffCode);
    setSelectedStaffCodes(list);
    setDisabledStaffCodes(list);
    const res: any = [];
    list.forEach((item: any) => {
      const foundItem = dataSouce.find((i: CommonUserInfo) => i.staffCode === item);
      if (foundItem) {
        res.push(foundItem); // 如果找到了匹配项，将其推入结果数组
      }
    });
    setSelectedPageRows(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const removeHomePage = (file: UploadFile) => {
    return new Promise<boolean>((resolve, reject) => {
      Modal.confirm({
        content: '是否删除图片？',
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  };

  const previewModal = () => {
    let customHomePageUrl = form.getFieldValue('homePageLogoUrl');
    let imgUrl = getRealUrl(customHomePageUrl);
    Modal.info({
      title: '预览',
      width: 1180,
      content: (
        <div className={styles.previewModal}>
          <img className={styles.topMenu} src={titleLine} alt="顶部导航" />
          <img className={styles.previewImg} src={imgUrl} alt={'自定义图片'} />
        </div>
      ),
      okText: '确认',
    });
  };

  const beforeUploadIcon = async (file: UploadFile) => {
    const { size, type } = file;
    if ((size as number) > 50 * 1024) {
      Modal.error({ content: `上传图片超过50K` });
      return false;
    }
    if (
      type !== 'image/jpeg' &&
      type !== 'image/png' &&
      type !== 'image/vnd.microsoft.icon' &&
      type !== 'image/x-icon'
    ) {
      Modal.error({ content: `仅支持.ico, .jpg, .jpeg, .png格式` });
      return false;
    }
    let isSuitable = await checkImgSize(file)
      .then(res => res)
      .catch(err => err);
    if (!isSuitable) {
      Modal.error({ content: `图片尺寸需要小于128*128px` });
      return false;
    }
    return true;
  };
  // 检查图片大小
  const checkImgSize = (file: UploadFile) => {
    const limitSize = 128;
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = img.width <= limitSize && img.height <= limitSize;
        valid ? resolve(true) : resolve(false);
      };
      img.src = _URL.createObjectURL(file as unknown as Blob);
    });
  };

  const onFinish = async (values: FormCustomerProps) => {
    if (isNil(mcid)) return;
    const staffIds = values.data.map((item: any) => item.id);
    if (!values.logoUrl) {
      await getDefaultLogo().then(data => {
        values.logoUrl = data.key;
      });
    }
    let params: CreateCustomerProps = {
      ...values,
      platformTitleConfig: Boolean((values.platformTitleConfig ?? []).length),
      homePageLogoConfig: Boolean((values.homePageLogoConfig ?? []).length),
      staffIds,
    };
    console.log(params);
    const res = isAdd ? await createCustomer(mcid, params) : await updateCustomer(mcid, params);
    if (res) {
      navigate(-1);
    }
  };

  const defaultLogo = () => {
    getDefaultLogo().then(data => {
      form.setFieldsValue({
        logoUrl: data.key,
      });
    });
  };

  const changeLogo = () => {
    const newMcid = isAdd ? mcid : parentMcid;
    if (newMcid) {
      getCustomer(newMcid).then(data => {
        form.resetFields(['logoUrl']);
        if (!data.logoUrl) {
          defaultLogo();
        } else {
          form.setFieldsValue({
            logoUrl: data.logoUrl,
          });
        }
      });
    } else {
      defaultLogo();
    }
  };

  const columns = [
    {
      title: '用户编号',
      dataIndex: 'customerUserHumanCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '员工工号',
      dataIndex: 'staffCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '用户登录账号',
      dataIndex: 'customerUserUsername',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '管理员/成员',
      dataIndex: 'roleName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '姓名',
      dataIndex: 'customerUserName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '手机号',
      dataIndex: 'customerUserCellphone',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所在组织',
      dataIndex: 'customerFullName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      render: (v: string, record: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              let currentRecord = { ...record };
              const data = form.getFieldValue('data');
              const findIndex = data.findIndex((i: any) => i.id === currentRecord.id);
              data.splice(findIndex, 1);
              form.setFieldsValue({ data: data });
            }}
          >
            取消绑定
          </Button>
        );
      },
    },
  ];
  const handleOk = (_value: any[]) => {
    const staffCodes = _value.map(item => item.staffCode);
    const fixed = dataSouce.filter((item: CommonUserInfo) => item.unbindable);
    const unfixed = dataSouce.filter((item: CommonUserInfo) => !item.unbindable);
    setDatasouce([
      ...fixed,
      ..._value,
      ...unfixed.filter((item: CommonUserInfo) => !staffCodes.includes(item.staffCode)),
    ]);
    setVisible(false);
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes}>
      <FormTitle title="组织详情" />
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        {...formLayout}
        initialValues={{ platformTitleType: TitleType.PLATFORM, platformName: DEFAULT_PLATFORM_NAME }}
      >
        <Row>
          {(isAdd ? name : parentName) && (
            <Col span={12}>
              <Form.Item label="上级组织">
                <span>{isAdd ? name : parentName}</span>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item label={`${isAdd ? '新建' : ''}组织类型`}>
              <span>{isAdd ? nextLevelName : levelName}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label={`${isAdd ? '新建' : ''}组织简称`}
              required
              rules={[{ validator: (rule: any, value: string) => checkName(rule, value, info?.name) }]}
              validateTrigger={['onBlur']}
            >
              <Input placeholder="最多8个字符，租户级唯一" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fullName"
              label={`${isAdd ? '新建' : ''}组织全称`}
              required
              rules={[{ validator: (rule: any, value: string) => checkFullName(rule, value, info?.fullName) }]}
              validateTrigger={['onBlur']}
            >
              <Input placeholder="最多30个字符，租户级唯一" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="组织logo" name="logoUrl">
              <Upload />
            </Form.Item>
            <span className={styles.restLogo} onClick={() => changeLogo()}>
              还原为默认logo
            </span>
          </Col>
          <Col span={12}>
            <Row>
              <Form.Item label="平台Slogan" name="platformSloganNameConfig">
                <Radio.Group>
                  <Radio value={false}>默认</Radio>
                  <Radio value={true}>自定义</Radio>
                </Radio.Group>
              </Form.Item>
              {platformSloganNameConfig && (
                <Form.Item
                  name="platformSloganName"
                  style={{ width: 'calc(100% - 150px)', marginTop: 30 }}
                  rules={[
                    { required: true, message: '请输入平台名称' },
                    { min: 4, message: '最少输入4个字符' },
                    { max: 8, message: '最多输入8个字符' },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={12}>
            <Form.Item name="platformTitleConfig" label="浏览器设置">
              <Checkbox.Group>
                <Checkbox value={CheckEnum.CHECKED}>自定义浏览器标签页</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle dependencies={['platformTitleConfig']}>
          {({ getFieldValue }) => {
            let isConfig = getFieldValue('platformTitleConfig') ?? [];
            return (
              !!isConfig.length && (
                <Row>
                  <Col span={12}>
                    <Form.Item label="标签页icon">
                      <Form.Item name="iconUrl" noStyle>
                        <Upload
                          accept={['.ico', '.jpg', '.jpeg', '.png']}
                          maxCount={1}
                          showUploadList={{ showPreviewIcon: false }}
                          beforeUpload={beforeUploadIcon}
                          tip="支持.ico, .jpg, .jpeg, .png格式，尺寸小于128*128px，文件小于50K"
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <div className={styles.example}>
                      <span>示例：</span>
                      <img src={templateIcon} style={{ width: 240, height: 50 }} alt="示例" />
                    </div>
                    <div className={styles.tips}>提示：示例为默认标签页icon与标签页标题</div>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="标签页标题" name="platformTitleType">
                      <Radio.Group
                        options={titleOptions}
                        onChange={(e: RadioChangeEvent) => {
                          if (e.target.value !== TitleType.NAVIGATE) {
                            form.setFieldsValue({ platformName: DEFAULT_PLATFORM_NAME });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item noStyle dependencies={['platformTitleType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('platformTitleType') !== TitleType.NAVIGATE && (
                            <Form.Item
                              label="平台名称"
                              name="platformName"
                              rules={[
                                { required: true, message: '请输入平台名称' },
                                { max: 15, message: '最多输入15个字符' },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          )
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              )
            );
          }}
        </Form.Item>

        <Row>
          <Col span={12}>
            <Form.Item name="homePageLogoConfig" label="平台首页图配置">
              <Checkbox.Group>
                <Checkbox value={CheckEnum.CHECKED}>自定义平台首页</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle dependencies={['homePageLogoConfig']}>
          {({ getFieldValue }) => {
            let isConfig = getFieldValue('homePageLogoConfig') ?? [];
            return (
              !!isConfig.length && (
                <Row>
                  <Col span={12}>
                    <Form.Item name="homePageLogoUrl" rules={[{ required: true, message: '请上传首页图' }]}>
                      <Upload
                        accept={['.jpg', '.jpeg', '.png', 'gif']}
                        fileSize={20}
                        onRemove={removeHomePage}
                        onPreview={previewModal}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )
            );
          }}
        </Form.Item>
        <Row>
          <Col span={24}>
            <div style={{ position: 'relative' }}>
              <Button
                type="link"
                style={{ position: 'absolute', left: 80, top: 2, zIndex: 10 }}
                icon={<PlusOutlined></PlusOutlined>}
                onClick={() => {
                  setVisible(true);
                }}
              >
                添加成员
              </Button>
            </div>
            <Form.Item
              name="data"
              label="组织数据权限"
              valuePropName="dataSource"
              style={{ marginBottom: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Table loading={loading} columns={columns}></Table>
            </Form.Item>
          </Col>
        </Row>

        <Row className={styles.operateContainer}>
          <Col span={12}>
            <Space>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              <Button
                onClick={() => {
                  Modal.confirm({
                    content: '您是否放弃本次修改?',
                    onOk: () => {
                      navigate(-1);
                    },
                  });
                }}
              >
                取消
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <StaffSelect
        open={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        type={0}
        dataSource={dataSouce}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        setSelectedRows={setSelectedRows}
        setSelectedPageRows={setSelectedPageRows}
        selectedPageRows={selectedPageRows}
        selectedRows={selectedRows}
        selectedStaffCodes={selectedStaffCodes}
        disabledStaffCodes={disabledStaffCodes}
      ></StaffSelect>
    </Wrapper>
  );
};

export default OrganizationManage;
