import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  Select,
  Form,
  Input,
  Modal,
  Radio,
  Wrapper,
  SubContent,
  Upload,
  Button,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Gender, UserStatus } from '../../../api/const';
import EmailFormItem from './component/EmailFormItem';
import {
  CustomerUserInfoProps,
  getCustomerUserInfo,
  updateCustomerUserInfo,
  UpdateCustomerUserInfoProps,
} from '../../../api/user';
import styles from './index.module.scss';

const { Option } = Select;
const routes = [{ name: '个人中心' }, { name: '个人设置' }, { name: '个人资料' }];

const emailFormLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
};

const Information: React.FC = () => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [info, setInfo] = useState<CustomerUserInfoProps>();

  useEffect(() => {
    if (!isEdit) {
      getCustomerUserInfo().then(data => {
        const info = Object.assign(data.staffDto, data.customerUserDto);
        form.setFieldsValue({
          ...info,
          birthday: info.birthday ? dayjs(info.birthday) : '',
        });
        setInfo(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const onFinish = (values: UpdateCustomerUserInfoProps) => {
    values.birthday = values.birthday ? dayjs(values.birthday).format('YYYY-MM-DD') : '';
    values.muid = info!.customerUserDto.muid;
    values.staffId = info!.staffDto.id;
    updateCustomerUserInfo(values).then(data => {
      if (data) {
        Modal.success({
          content: `已经成功更新用户资料，用户登录账号${data.customerUserDto.name}`,
          okText: '确定',
        });
        setIsEdit(false);
      }
    });
  };

  const onCancel = () => {
    Modal.confirm({
      title: '是否放弃所有未保存信息并关闭页面？',
      onOk: () => {
        setIsEdit(false);
      },
    });
  };

  return (
    <Wrapper routes={routes} className={styles.wrapperPadding}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <FormTitle title="个人资料" />
        <SubContent title="基本信息">
          <Row>
            <Col span={8}>
              <Form.Item label="用户ID" name="humanCode">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="手机号码" name="cellphone">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="姓名"
                name="name"
                validateFirst={true}
                rules={[
                  { required: true, whitespace: true, message: '请输入姓名' },
                  { pattern: /^[0-9\u4e00-\u9fa5]+$/g, message: '姓名格式不正确！' },
                  { max: 8, message: '员工姓名最多8个字符' },
                ]}
              >
                <Input placeholder="请输入姓名" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="用户状态" name="status">
                <Select disabled>
                  <Option value={UserStatus.ENABLE}>启用</Option>
                  <Option value={UserStatus.DISABLE}>禁用</Option>
                  <Option value={UserStatus.FREEZE}>冻结</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="登录账号" name="username">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="用户扩展信息">
          <Row>
            <Col span={8}>
              <Form.Item name="gender" label="性别">
                <Radio.Group disabled={!isEdit}>
                  <Radio value={Gender.MALE}>男</Radio>
                  <Radio value={Gender.FEMALE}>女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="birthday" label="出生年月">
                <DatePicker
                  disabled={!isEdit}
                  style={{ width: '100%' }}
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs(), 'date');
                  }}
                  placeholder="请输入出生年月"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="email"
                label="邮箱"
                {...emailFormLayout}
                rules={[
                  { required: true, message: '请输入邮箱' },
                  { type: 'email', message: '请输入正确的邮箱' },
                ]}
              >
                <EmailFormItem disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="wechat"
                label="微信"
                rules={[{ pattern: /^[A-Za-z0-9_-]{6,20}$/, message: '请输入正确的微信' }]}
              >
                <Input placeholder="请输入微信" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="qq" label="QQ" rules={[{ pattern: /^[0-9]*$/, message: '请输入正确的QQ' }]}>
                <Input placeholder="请输入QQ" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="headPic" label="用户头像">
                <Upload disabled={!isEdit} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="当前登录员工资料">
          <Row>
            <Col span={8}>
              <Form.Item label="工号" name="staffCode">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="workWechat"
                label="微信"
                rules={[{ pattern: /^[A-Za-z0-9_-]{6,20}$/, message: '请输入正确的微信' }]}
              >
                <Input placeholder="请输入微信" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="workEmail"
                label="邮箱"
                {...emailFormLayout}
                rules={[{ type: 'email', message: '请输入正确的邮箱' }]}
              >
                <EmailFormItem disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="workQq" label="QQ" rules={[{ pattern: /^[0-9]*$/, message: '请输入正确的QQ' }]}>
                <Input placeholder="请输入QQ" disabled={!isEdit} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space style={{ zIndex: 200 }} className="sticky-footer">
          {!isEdit ? (
            <Button
              type="primary"
              onClick={e => {
                e.preventDefault();
                setIsEdit(true);
              }}
            >
              编辑
            </Button>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
              <Button onClick={onCancel}>取消</Button>
            </>
          )}
        </Space>
      </Form>
    </Wrapper>
  );
};

export default Information;
