import { useCurrent, useUpdate, Button } from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { getQuota, QuotaProps } from '../../../api/ql-user';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/hooks';
import AccountAddModal from './AccountAddModal';
import AccountOpModal from './AccountOpModal';
import styles from './index.module.scss';
interface Iprops {
  deptId?: number;
  getApiStatus?: (status: boolean) => void;
}
const AccountManage: React.FC<Iprops> = ({ deptId, getApiStatus }) => {
  const [accountOpModalVisible, setAccountOpModalVisible] = useState(false);
  const [accountAddModalVisible, setAccountAddModalVisible] = useState(false);
  const [accountNum, setAccount] = useState<QuotaProps>();
  const [updateState, update] = useUpdate();
  const user = useCurrent();
  const mcid = user?.tenant?.mcid;
  const hasAccountManagePers = useHasPermission(PermissionsType.B_QL_MANAGE_ACCOUNT);
  const hasAccountAddPers = useHasPermission(PermissionsType.B_QL_ADD_ACCOUNT);

  // 获取账户数使用情况
  useEffect(() => {
    getQuota(mcid!)
      .then(res => {
        setAccount(res);
        getApiStatus?.(true);
      })
      .catch(_ => getApiStatus?.(false));
  }, [mcid, updateState]);
  return (
    <>
      <Space>
        <div className={styles.accountBox}>
          <Space>
            <div className={styles.account}>账户数使用情况:</div>
            <div>
              <span className={styles.used}>{accountNum?.usedUserQuota ?? 0}</span>
              <span>&nbsp;/&nbsp;</span>
              <span className={styles.total}>{accountNum?.userQuota ?? 0}</span>
            </div>
            <div>
              {hasAccountManagePers && (
                <Button type="link" onClick={() => setAccountOpModalVisible(true)}>
                  管理账户
                </Button>
              )}
              {hasAccountAddPers && (
                <Button type="link" onClick={() => setAccountAddModalVisible(true)}>
                  添加账户
                </Button>
              )}
            </div>
          </Space>
        </div>
      </Space>
      {
        // 账户管理弹框
        accountOpModalVisible && (
          <AccountOpModal
            updateP={update}
            onConfirm={() => {
              setAccountOpModalVisible(false);
            }}
            onCancel={() => {
              setAccountOpModalVisible(false);
            }}
          />
        )
      }
      {
        // 添加账户弹框
        accountAddModalVisible && (
          <AccountAddModal
            updateP={update}
            onConfirm={() => {
              setAccountAddModalVisible(false);
              update();
            }}
            onCancel={() => {
              setAccountAddModalVisible(false);
            }}
          />
        )
      }
    </>
  );
};
export default AccountManage;
