import React, { useEffect, useState } from 'react';
import { Col, Row, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { Form, Input, message, Modal, Wrapper, Button, FormContent, FormTitle, SubContent } from '@maxtropy/components';
import {
  checkSms,
  existOperationPassword,
  initOperationPassword,
  sendSms,
  updateOperationPassword,
} from '../../../api/user';
import { passwordRegex } from '../../../lib/regex';

const FormItem = Form.Item;

const operationEnum = ['init', 'update'];

const OperatePassword: React.FC = props => {
  const [current, setCurrent] = useState<string>('');
  const [blockingCount, setBlockingCount] = useState<number>(0);
  const [intervalMark, setIntervalMark] = useState<ReturnType<typeof setInterval> | null>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [sms, setSms] = useState<string>();

  const [initForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  useEffect(() => {
    existOperationPassword().then(res => {
      if (res.existed) {
        setCurrent('update');
      } else {
        setCurrent('init');
      }
    });
  }, []);

  useEffect(() => {
    if (opened) {
      if (intervalMark) {
        clearInterval(intervalMark);
      }
      setBlockingCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const checkUpdateConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== updateForm.getFieldValue('newOperationPassword')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  const checkInitConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== initForm.getFieldValue('operationPassword')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  const handleUpdate = async (values: any) => {
    try {
      await updateOperationPassword(values);
      message.success('操作密码修改成功！');
      updateForm.resetFields();
    } catch (e: any) {
      message.error(e.errorMessage);
    }
  };

  const handleInit = async (values: any) => {
    try {
      await initOperationPassword(values);
      message.success('操作密码设置成功！');
      window.location.reload();
    } catch (e: any) {
      message.error(e.errorMessage);
    }
  };

  const onUpdateCancel = () => {
    updateForm.resetFields();
  };

  const onInitCancel = () => {
    initForm.resetFields();
  };

  const updateOperatePassword: React.ReactNode = (
    <Form form={updateForm} onFinish={handleUpdate}>
      <Row>
        <Col span={24}>
          <FormItem
            name="oldOperationPassword"
            rules={[{ required: true, message: '请输入原操作密码' }]}
            label="原操作密码"
          >
            <Input.Password placeholder="请输入原操作密码" autoComplete="new-password" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="newOperationPassword"
            rules={[
              { required: true, message: '请输入新操作密码' },
              { pattern: passwordRegex, message: '密码不少于8位，必须含大小写字母和数字' },
            ]}
            label="新操作密码"
          >
            <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" autoComplete="new-password" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="validNewOperationPassword"
            rules={[{ required: true, message: '请确认新操作密码' }, { validator: checkUpdateConfirm }]}
            label="确认新操作密码"
          >
            <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" autoComplete="new-password" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            cursor: 'pointer',
            marginLeft: 135,
            marginBottom: 24,
            color: 'var(--mx-link-color)',
          }}
          span={12}
          onClick={() => setOpened(true)}
        >
          <ExclamationCircleOutlined className={styles.warning} />
          &nbsp;忘记密码
        </Col>
      </Row>
      <Space>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
        <Button onClick={onUpdateCancel}>取消</Button>
      </Space>
    </Form>
  );

  const initOperatePassword: React.ReactNode = (
    <Form form={initForm} onFinish={handleInit}>
      <Row>
        <Col span={24}>
          <FormItem
            name="operationPassword"
            rules={[
              { required: true, message: '请输入操作密码' },
              { pattern: passwordRegex, message: '密码不少于8位，必须含大小写字母和数字' },
            ]}
            label="操作密码"
          >
            <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="validOperationPassword"
            rules={[{ required: true, message: '请确认操作密码' }, { validator: checkInitConfirm }]}
            label="确认操作密码"
          >
            <Input.Password placeholder="请输入至少8位数密码，密码必须含大小写字母和数字" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <ExclamationCircleOutlined className={styles.warning} />
        &nbsp;初始化输入操作密码，用于特定的敏感操作进行验证
      </Row>
      <Row className={styles.operateContainer}>
        <Space>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={onInitCancel}>取消</Button>
        </Space>
      </Row>
    </Form>
  );

  const renderFormArray = [
    {
      title: '初始化操作密码',
      children: initOperatePassword,
    },
    {
      title: '修改操作密码',
      children: updateOperatePassword,
    },
  ];

  const routes = [
    { name: '个人中心' },
    { name: '个人设置' },
    { name: renderFormArray[operationEnum.indexOf(current)] && renderFormArray[operationEnum.indexOf(current)].title },
  ];

  const onSendClick = () => {
    sendSms().then(data => {
      let blockingCounts = 60;
      const interval = setInterval(() => {
        const count = blockingCounts;
        blockingCounts = count > 0 ? count - 1 : 0;
        setBlockingCount(blockingCounts);
        if (count === 0) {
          clearInterval(interval);
        }
      }, 1000);
      setIntervalMark(interval);
    });
  };

  const resetPassword = () => {
    if (sms) {
      checkSms(sms).then(data => {
        if (!data.result) {
          message.error('验证码不正确！');
        } else {
          message.success('重置成功！');
          window.location.reload();
        }
      });
    } else {
      message.error('请输入验证码');
    }
  };

  return (
    <Wrapper routes={routes} className={styles.wrapperPadding}>
      {renderFormArray[operationEnum.indexOf(current)] && (
        <>
          <FormTitle title={renderFormArray[operationEnum.indexOf(current)].title} />
          <SubContent>{renderFormArray[operationEnum.indexOf(current)].children}</SubContent>
        </>
      )}
      <Modal
        title="忘记密码"
        open={opened}
        contentClassName="modal-form-content"
        onCancel={() => {
          setOpened(false);
          setBlockingCount(0);
        }}
        onOk={resetPassword}
      >
        <Row>
          <Col span={18}>
            <Input placeholder="请输入验证码，验证码60s内有效" value={sms} onChange={v => setSms(v.target.value)} />
          </Col>
          <Col span={5} offset={1}>
            <Button onClick={() => onSendClick()} type="primary" disabled={!!blockingCount}>
              {`${blockingCount ? blockingCount + 's' : '获取验证码'}`}
            </Button>
          </Col>
        </Row>
      </Modal>
    </Wrapper>
  );
};

export default OperatePassword;
