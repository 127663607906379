import React, { useEffect, useMemo, useState } from 'react';
import {
  Tree,
  Checkbox,
  Modal,
  Button,
  Wrapper,
  SubContent,
  useBreadcrumbRoutes,
  Empty,
  FormTitle,
} from '@maxtropy/components';
import { Space } from 'antd';
import { getOrganizationTree, OrganizationTreeProps } from '../../api/customer';
import { fetchStaffById, getListMcidByStaffId, Staff, updateStaffDataRole } from '../../api/staff';
import { AuthorityType } from '../../api/const';
import { DataNode } from 'antd/es/tree';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import usePageStatus, { PageStatus } from '../RolePermissions/Edit/usePageStatus';
import { getChildren } from '../../utils/helper';
import StatusButton from './StatusButton';
import { isNil } from 'lodash-es';

function formatTreeData(data: OrganizationTreeProps[], parentList?: string[]) {
  return data.map(item => {
    const res: DataNode = {
      ...item,
      key: item.data!.mcid,
      disabled: parentList?.includes(item.data!.mcid) || !item.data?.hasPermission,
      title: item.data?.name,
      children: formatTreeData(item.children, parentList),
    };
    return res;
  });
}

const routes = [{ name: `数据权限管理` }];

const generateList = (list: DataNode[]) => {
  const dataList: string[] = [];
  const rev = (list: DataNode[]) => {
    for (let i = 0; i < list.length; i++) {
      const node = list[i];
      const { key } = node;
      dataList.push(key as string);
      if (node.children) {
        rev(node.children);
      }
    }
  };
  rev(list);
  return dataList;
};

const traverseDataNodes = (dataNodes: DataNode[], callback: (data: DataNode, childKeys: string[]) => void) => {
  function processNode(node: DataNode) {
    const children = node ? node.children : dataNodes;

    if (node) {
      const _childKeys: string[] = [];
      const dig = (dataNodes: DataNode[]) => {
        (dataNodes ?? []).forEach(item => {
          if (!item.disabled) {
            _childKeys.push(item.key as string);
          }
          dig(item.children ?? []);
        });
      };
      dig(node.children ?? []);
      callback(node, _childKeys);
    }

    if (children) {
      children.forEach(subNode => {
        processNode(subNode);
      });
    }
  }

  processNode(null as unknown as DataNode);
};

const convertDataToEntities = (dataNodes: DataNode[]) => {
  const keyEntities: Record<string, string[]> = {};
  traverseDataNodes(dataNodes, (item, childKeys) => {
    const { key } = item;
    keyEntities[key] = childKeys;
  });
  return keyEntities;
};

const EmployeeAuthority: React.FC = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<OrganizationTreeProps[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [info, setInfo] = useState<Staff>();
  const [dataList, setDataList] = useState<string[]>([]);
  const pageStatus = usePageStatus();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    setDisabled(pageStatus === PageStatus.DETAIL);
  }, [pageStatus]);

  useEffect(() => {
    getOrganizationTree().then(data => {
      if (data) {
        setTreeData([data]);
        setDataList(generateList(formatTreeData([data])));
      }
    });
    if (isNil(id)) return;
    getListMcidByStaffId({
      staffId: id,
      type: AuthorityType.ORGANIZATION,
    }).then(data => {
      setCheckedList(data.list ?? []);
    });
    fetchStaffById(id).then(data => {
      setInfo(data);
    });
  }, [id]);

  const list = useMemo(() => {
    if (info) {
      const childrenList = getChildren(treeData, info.mcid);
      const parentsList = dataList.filter(a => {
        return !childrenList.some(c => c === a);
      });
      const intersection = parentsList.filter(v => checkedList.indexOf(v) > -1);
      if (intersection && intersection.length > 0) {
        setChecked(true);
      }
      if (treeData && !checked) {
        return formatTreeData(treeData, parentsList);
      } else {
        return formatTreeData(treeData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData, checked, info, dataList]);

  const onSubmit = () => {
    if (isNil(id)) return;
    updateStaffDataRole({
      mcidList: checkedList,
      type: AuthorityType.ORGANIZATION,
      staffId: id,
    }).then(data => {
      if (data) {
        navigate('/system/customer/staff');
      }
    });
  };

  const onClose = () => {
    if (pageStatus === PageStatus.DETAIL) {
      navigate('/system/customer/staff');
    } else {
      Modal.confirm({
        content: <div>是否放弃所有未保存信息并返回列表？</div>,
        onOk: () => {
          navigate('/system/customer/staff');
        },
      });
    }
  };

  const dataKeyEntities: Record<string, string[]> = useMemo(() => {
    return Array.isArray(list) && list.length > 0 ? convertDataToEntities(list) : {};
  }, [list]);

  const selectAllChild = (key: string, value: boolean) => {
    if (value) {
      setCheckedList(Array.from(new Set([...checkedList, ...dataKeyEntities[key]])));
    } else {
      setCheckedList(Array.from(new Set(checkedList.filter(item => !dataKeyEntities[key].includes(item)))));
    }
  };

  const titleRender = (node: DataNode) => {
    const visible = !disabled && !node.disabled && Array.isArray(node.children) && node.children?.length > 0;
    const checked = dataKeyEntities[node.key].every(value => checkedList.includes(value));
    return (
      <div>
        <span>{node?.title as React.ReactNode}</span>
        {visible && (
          <StatusButton
            style={{ margin: '2px 5px' }}
            checked={checked}
            onChange={(event, value) => selectAllChild(node.key as string, value)}
          >
            全选子组织
          </StatusButton>
        )}
      </div>
    );
  };

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle title="组织数据权限管理" />
      <SubContent>
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={v => {
            setChecked(v.target.checked);
            if (info && !v.target.checked) {
              const childrenList = getChildren(treeData, info.mcid);
              const checkList = checkedList.filter(val => childrenList.indexOf(val) > -1);
              setCheckedList(checkList);
            }
          }}
        >
          允许该员工管理其他组织和上级组织
        </Checkbox>
        <div className={styles.box}>
          {list && list.length > 0 ? (
            <Tree
              disabled={disabled}
              checkStrictly
              checkable
              checkedKeys={checkedList}
              treeData={list}
              defaultExpandAll
              onCheck={(checkedKeys: any) => setCheckedList(checkedKeys.checked)}
              titleRender={titleRender}
            />
          ) : (
            <Empty style={{ marginTop: '10%' }} />
          )}
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              navigate(`/system/customer/staff/${pageStatus}/${id}`);
            }}
          >
            上一步
          </Button>
          {!disabled && (
            <Button type="primary" onClick={onSubmit}>
              提交
            </Button>
          )}
          <Button onClick={onClose}>关闭</Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default EmployeeAuthority;
