import React from 'react';
import { StepsProps } from 'antd';
import './index.scss';
import { Steps } from '@maxtropy/components';

const { Step } = Steps;

interface CommonStepsProps extends StepsProps {
  children?: React.ReactNode;
}

const CommonSteps: React.FC<CommonStepsProps> = props => {
  const prefixCls = 'mgmt-steps';
  return (
    <div className={`${prefixCls}-wrapper`}>
      <Steps {...props}>{props.children}</Steps>
    </div>
  );
};

export default CommonSteps;

interface RoleStepsProps extends CommonStepsProps {
  isDetail: boolean;
}

export const RoleSteps: React.FC<RoleStepsProps> = props => {
  const { isDetail } = props;
  return (
    <CommonSteps {...props} style={{ width: 1000 }}>
      <Step title="角色基本信息" />
      <Step title="角色权限" />
      {!isDetail ? (
        <>
          <Step title="角色授权" />
          <Step title="匹配员工" />
        </>
      ) : (
        <Step title="使用情况" />
      )}
    </CommonSteps>
  );
};
