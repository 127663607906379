import { CheckCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FC } from 'react';
import classNames from 'classnames';
import { Button } from '@maxtropy/components';

import styles from './index.module.scss';

export interface CheckBusinessScopeOptionType {
  label: React.ReactNode;
  value: CheckboxValueType;
}

export interface CheckBusinessScopeProps {
  options: Array<CheckBusinessScopeOptionType>;
  value?: Array<CheckboxValueType>;
  onChange?: (checkedValue: Array<CheckboxValueType>) => void;
  disabled?: boolean;
}

function isChecked(option: CheckBusinessScopeOptionType, value?: Array<CheckboxValueType>) {
  return (value ?? []).includes(option.value);
}

const CheckBusinessScope: FC<CheckBusinessScopeProps> = ({ disabled = false, value, onChange, options }) => {
  const onClick = (v: CheckboxValueType) => {
    if (disabled) return;
    const index = (value ?? []).findIndex(i => i === v);
    if (index !== -1) {
      onChange?.((value ?? []).filter(i => i !== v));
    } else {
      onChange?.([...(value ?? []), v]);
    }
  };

  return (
    <Space>
      {options.map(i => (
        <Button
          key={i.value.toString()}
          className={classNames({ [styles.disabledButton]: disabled })}
          onClick={() => {
            onClick(i.value);
          }}
          type={isChecked(i, value) ? 'primary' : 'default'}
          icon={isChecked(i, value) && <CheckCircleOutlined />}
        >
          {i.label}
        </Button>
      ))}
    </Space>
  );
};

export default CheckBusinessScope;
