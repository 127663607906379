import { fetch, Key, PromiseWithErrorHandler } from '@maxtropy/components';
import { RoleStatus, RoleType, RoleLevel } from './const';

export interface Paged<T> {
  total: number;
  page: number;
  size: number;
  list: T[];
}

export interface PageRequest {
  page?: number;
  size?: number;
}
export interface RoleListRequest extends PageRequest {
  state?: RoleStatus.ENABLE | RoleStatus.FREEZE;
  toVoid?: boolean;
  level?: RoleType;
  ownerMcid?: string;
}

export interface RoleListResponse {
  id: number;
  name: string;
  state: RoleStatus;
  level: RoleType;
  countStaff: number;
  oraganize?: string;
  createTime: string;
  updateTime: string;
  isOwner: boolean;
  code: string;
}

interface UseInfoItem {
  code: string;
  name: string;
  num: number;
}
export interface RoleUseInfo {
  id: number;
  code: string;
  name: string;
  state: RoleStatus;
  customerCount: number;
  staffCount: number;
  useInfoList: UseInfoItem[];
}

export function getRoleList(query: RoleListRequest): Promise<Paged<RoleListResponse>> {
  return fetch(
    `/api/v2/role/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export function updateRoleState(id: number, state: RoleStatus) {
  return fetch<{ result: boolean }>(
    `/api/v2/role/updState`,
    {
      method: 'POST',
      body: JSON.stringify({ id, state }),
    },
    true
  );
}

interface RequestRoleBody {
  name: string;
  state: string;
  integratedAppIdList: number[];
}

export interface RoleInfo {
  id: number;
  code: string;
  name: string;
  state: RoleStatus;
  ownerCrMcid?: string;
  level?: RoleLevel;
  type?: RoleType;
  roleIntegratedAppList: IntegratedApp[];
  miniAppIds: number[];
}

export function createRole(body: RequestRoleBody): Promise<RoleInfo> {
  return fetch(
    `/api/v2/role/save`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export function findRoleById(id: string): Promise<RoleInfo> {
  return fetch(
    `/api/v2/role/details`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function findRoleByCode(code: string): Promise<RoleInfo> {
  return fetch(
    `/api/v2/role/detailsByCode`,
    {
      method: 'POST',
      body: JSON.stringify({ code }),
    },
    true
  );
}

export function updateRole(body: RequestRoleBody): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/role/update`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

interface CheckUserNameUniquenessQuery {
  name: string;
  id?: string;
}

export function checkUserNameUniqueness(
  query: CheckUserNameUniquenessQuery
): PromiseWithErrorHandler<{ result: boolean }> {
  return fetch(
    `/api/v2/role/checkUserName`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

interface PermissionProps {
  permissions: Key[];
  syncs: Key[];
}

export function queryPermissionList(roleCode: string): Promise<PermissionProps> {
  return fetch(
    `/api/v2/role/function/permission/permissionList`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );
}

export interface UpdatePermission {
  roleCode: string;
  addPermissions: Key[];
  deletePermission: Key[];
  addSyncs: Key[];
  deleteSyncs: Key[];
}

export interface IntegratedAppPermission extends UpdatePermission {
  integratedAppId: number;
}
export interface UpdatePermissionBody extends UpdatePermission {
  integratedAppPermission?: { [key: number]: IntegratedAppPermission };
}

export function updatePermission(body: UpdatePermissionBody): Promise<never> {
  return fetch(
    `/api/v2/role/function/permission/upsert`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

interface RequestRoleFromBody extends RequestRoleBody {
  copyRoleId: number;
}

export function createRoleFromCopy(body: RequestRoleFromBody): Promise<RoleInfo> {
  return fetch(
    `/api/v2/role/saveCopy`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 获取角色使用情况
export const getRoleUseInfo = (roleCode: string): Promise<RoleUseInfo> =>
  fetch(
    `/api/v2/role/use/info`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );

export interface IntegratedApp {
  id: number;
  name: string;
}

export function getIntegratedAppList(): Promise<{ list: IntegratedApp[] }> {
  return fetch(
    `/api/v2/role/permission/integrated/integratedAppList`,
    {
      method: 'POST',
    },
    true
  );
}

export function getRoleIntegratedAppList(roleCode: string): Promise<{ list: IntegratedApp[] }> {
  return fetch(
    `/api/v2/role/integratedAppList`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );
}

export function getIntegratedPermissionList(roleCode: string, integratedAppId: number): Promise<PermissionProps> {
  return fetch(
    `/api/v2/role/permission/integrated/permissionList`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode, integratedAppId }),
    },
    true
  );
}

export interface PermissionsItem {
  isPlatform?: boolean;
  integratedAppId?: number;
  code: string;
  description?: string;
  id: Key;
  name?: string;
}

export enum LevelType {
  FIRST = 1,
  SECOND,
  THIRD,
  FOUR,
}
export interface NavigationPermissionTree {
  id: Key;
  name?: string; // 导航名称
  level: LevelType; // 枚举备注: 1 :一级 2 :二级 3 :三级
  weighting?: number; // 权重
  permissionName?: string;
  code: string;
  description?: string;
  isPlatform?: boolean;
  integratedAppId?: number;
  permissions?: PermissionsItem[]; // 权限
  children?: NavigationPermissionTree[];
  navigationName?: string;
}

export interface AppletPermissionTree {
  id?: Key;
  name?: string; // 导航名称
  parentId?: number; //上级id
  rootId?: number;
  level?: LevelType; // 枚举备注: 1 :一级 2 :二级 3 :三级
  description?: string; // 权重
  pic?: string;
  code?: string;
  children?: AppletPermissionTree[];
}

// 获取导航视图树
export function getRoleNavigationPermissionTree(roleCode: string): Promise<{ list: NavigationPermissionTree[] }> {
  return fetch(
    `/api/v2/role/navigation/permission/tree`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );
}

// 获取角色视图权限
export function getRoleNavigationPermissionList(roleCode: string): Promise<{ list: PermissionsItem[] }> {
  return fetch(
    `/api/v2/role/navigation/permission/list`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );
}

export interface UpdateNavigationPermission {
  roleCode: string;
  addPermissions?: PermissionsItem[];
  miniPermissions?: string[];
  deletePermission?: PermissionsItem[];
}

// 更新导航视图权限
export function updateRoleNavigationPermissions(body: UpdateNavigationPermission): Promise<never> {
  return fetch(
    `/api/v2/role/navigation/permissions/upsert`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}
export interface AutoSyncPermission {
  permissionCode: string;
  isPlatform: boolean;
  integratedAppId: number;
}

// 获取角色导航视图权限自动同步列表
export function getNavigationAutoSyncPermissionList(roleCode: string): Promise<{ list: AutoSyncPermission[] }> {
  return fetch(
    `/api/v2/role/navigation/auto-sync/permission/list`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );
}

export interface MiniAppPermissionMenu {
  /**
   * 小程序id
   */
  id?: number;
  /**
   * 小程序名称
   */
  name?: string;
  /**
   * 小程序appId
   */
  appId?: string;
  /**
   * 小程序版本
   */
  version?: string;
  /**
   * 小程序主体
   */
  body?: string;
  /**
   * 说明
   */
  description?: string;
  /**
   * 入口页图标（fileCenter Key）
   */
  icon?: string;
  /**
   * 入口页欢迎词（\n代表换行）
   */
  welcomeText?: string;
  /**
   * 入口页背景 (fileCenterKey)
   */
  bg?: string;
  /**
   * 创建时间
   */
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 更新时间
   */
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 编号（8位随机字符串）
   */
  code?: string;
  /**
   * 状态 1 草稿 2 已发布
   */
  status?: number;
  /**
   * app密钥
   */
  appSecret?: string;
  /**
   * 上传密钥
   */
  uploadSecret?: string;
  /**
   * 升级状态0->未升级,1->已升级
   */
  upgradeStatus?: number;
  /**
   * mini_app_package_url
   */
  miniAppPackageUrl?: string;
  /**
   * 权限树
   */
  children?: {
    /**
     * id自增
     */
    id?: number;
    /**
     * 菜单名称
     */
    name?: string;
    /**
     * 菜单图标（fileCenter Key）
     */
    icon?: string;
    /**
     * 所属小程序id
     */
    miniAppId?: number;
    /**
     * 创建时间
     */
    createTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    /**
     * 更新时间
     */
    updateTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    /**
     * 卡片首页固定排序4、工具中心固定排序5
     */
    sorting?: number;
    /**
     * 0 隐藏 1 显示 （隐藏不参与打包）
     */
    visibility?: number;
    /**
     * 1 自定义 2 卡片首页 3 工具中心
     */
    type?: number;
    /**
     * 绑定的工具id(type=1才会绑定单个工具，工具镜像表id)
     */
    bindMirrorToolId?: number;
    /**
     * 小程序版本
     */
    version?: string;
    children?: {
      /**
       * 自增主键
       */
      id?: number;
      /**
       * 工具名称
       */
      name?: string;
      /**
       * 工具路径(git仓库地址)
       */
      gitRepoUrl?: string;
      /**
       * 工具图标（fileCenter key）
       */
      icon?: string;
      /**
       * 工具类型：1 自研、2 isv (自研不需要服务商id和集成应用id)
       */
      type?: number;
      /**
       * 服务商id
       */
      vendorId?: number;
      /**
       * 集成应用id
       */
      integratedAppId?: number;
      /**
       * 工具简介
       */
      description?: string;
      /**
       * 是否支持小卡片：0  不支持、1 支持
       */
      cardSupport?: number;
      /**
       * 创建时间
       */
      createTime?: {
        /**
         * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
         */
        seconds?: number;
        /**
         * The number of nanoseconds, later along the time-line, from the seconds field.
         * This is always positive, and never exceeds 999,999,999.
         */
        nanos?: number;
      };
      /**
       * 更新时间
       */
      updateTime?: {
        /**
         * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
         */
        seconds?: number;
        /**
         * The number of nanoseconds, later along the time-line, from the seconds field.
         * This is always positive, and never exceeds 999,999,999.
         */
        nanos?: number;
      };
      /**
       * 工具分类id (镜像)
       */
      mirrorCategoryId?: number;
      /**
       * 工具分类名称 (镜像)
       */
      mirrorCategoryName?: string;
      /**
       * 类别排序 (镜像)
       */
      mirrorCategorySorting?: number;
      /**
       * 源id (mini_app_tool表的id)
       */
      originalId?: number;
      /**
       * 排序
       */
      sorting?: number;
      /**
       * 所属小程序id
       */
      miniAppId?: number;
      /**
       * 小程序版本
       */
      version?: string;
      children?: {
        /**
         * 自增id
         */
        id?: number;
        /**
         * 权限code
         */
        code?: string;
        /**
         * 上级id
         */
        parentId?: number;
        /**
         * 根节点id
         */
        rootId?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 等级
         */
        level?: number;
        /**
         * 备注
         */
        description?: string;
        /**
         * 图片
         */
        pic?: string;
        /**
         * 权限来源的小程序id
         */
        miniAppId?: number;
        /**
         * 子权限
         */
        children?: {
          /**
           * 自增id
           */
          id?: number;
          /**
           * 权限code
           */
          code?: string;
          /**
           * 上级id
           */
          parentId?: number;
          /**
           * 根节点id
           */
          rootId?: number;
          /**
           * 名称
           */
          name?: string;
          /**
           * 等级
           */
          level?: number;
          /**
           * 备注
           */
          description?: string;
          /**
           * 图片
           */
          pic?: string;
          /**
           * 权限来源的小程序id
           */
          miniAppId?: number;
          /**
           * 子权限
           */
          children?: {}[];
        }[];
      }[];
    }[];
  }[];
}
