import { DataNode } from 'antd/es/tree';
import { OrganizationTreeProps } from '../api/customer';

export function transformSpace(value: string) {
  return value && value.replaceAll(' ', '');
}

export const getParentKey = (key: string, tree: DataNode[]): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export function getChildren(tree: OrganizationTreeProps[], id: string) {
  let childrenKeys: string[] = [];
  const loop = (data: OrganizationTreeProps[], arr: string[] = []) => {
    for (let item of data) {
      arr.push(item.data!.mcid);
      if (item.children && item.children.length) loop(item.children, arr);
    }
    return arr;
  };
  const rev = (data: OrganizationTreeProps[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      if (node.data?.mcid === id) {
        childrenKeys.push(node.data.mcid);
        childrenKeys.push(...loop(node.children));
        break;
      } else {
        if (!!node.children) rev(node.children);
      }
    }
  };
  rev(tree);
  return childrenKeys;
}
