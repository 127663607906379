import { FC, useEffect } from 'react';
import { TenantLayout as LayoutComponents, AuthorizedPage, activateTheme, Themes } from '@maxtropy/components';

type SelectStaffLayoutProps = {
  children?: React.ReactNode;
};

const SelectStaffLayout: FC<SelectStaffLayoutProps> = ({ children }) => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return (
    <AuthorizedPage>
      <LayoutComponents>{children}</LayoutComponents>
    </AuthorizedPage>
  );
};

export default SelectStaffLayout;
