import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

export default function RootRouteBoundary() {
  const error = useRouteError();

  useEffect(() => {
    console.error(error);
  }, [error]);

  return <></>;
}
