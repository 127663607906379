import { fetch, PromiseWithErrorHandler } from '@maxtropy/components';
import { AppFunctionPermissionVo } from './app';

export interface OrganizationLevelProps {
  name: string;
  index: number;
}

export function queryOrganizationLevelList(): Promise<{ list: OrganizationLevelProps[] }> {
  return fetch(
    `/api/v2/tenant/personalized/listOrganizationLevel`,
    {
      method: 'POST',
    },
    true
  );
}

interface RequestOrganizationLevelBody {
  tenantOrganizationLevelUpdList: { name: string; index: number }[];
}

export function updateOrganizationLevel(body: RequestOrganizationLevelBody): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/tenant/personalized/updateOrganizationLevel`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface RoleBindOrganizationForm {
  roleCode: string;
  mcidList: string[];
}

// 角色绑定组织 -- 新增
export const postRoleMcids = (form: RoleBindOrganizationForm): Promise<void> => {
  return fetch(
    `/api/v2/tenant/role/insert`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

// 角色绑定组织 -- 删除
export const deleteRoleMcids = (form: RoleBindOrganizationForm): PromiseWithErrorHandler<void> => {
  return fetch(
    `/api/v2/tenant/role/delete`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

export function fetchMcidsByRoleCode(roleCode: string): Promise<{ list: string[] }> {
  return fetch(
    `/api/v2/tenant/role/mcids`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode }),
    },
    true
  );
}

export function getIntegratedAppPermissionTree(integratedAppId: number): Promise<{ list: AppFunctionPermissionVo[] }> {
  return fetch(
    `/api/v2/tenant/integratedAppTreeByRootMcid`,
    {
      method: 'POST',
      body: JSON.stringify({ integratedAppId }),
    },
    true
  );
}
