import { FC, useEffect } from 'react';
import {
  Form,
  Modal,
  Radio,
  FormContent,
  Wrapper,
  NavigationPreference,
  Button,
  SubContent,
  FormTitle,
} from '@maxtropy/components';
import {
  getCurrentUser,
  updateCustomerUserNavigationPreference,
  UpdateCustomerUserNavigationPreferenceRequest,
} from '../../api/user';
import styles from './index.module.scss';

const routes = [{ name: '个人中心' }, { name: '个人设置' }, { name: '个人偏好' }];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const NavigationPreferenceDisplay: Record<NavigationPreference, string> = {
  [NavigationPreference.AUTO]: '支持收起/展示',
  [NavigationPreference.OPEN]: '一直展开',
  [NavigationPreference.HIDE]: '直接隐藏',
};

const NavigationPreferencePage: FC = () => {
  const [form] = Form.useForm<UpdateCustomerUserNavigationPreferenceRequest>();

  const onFinish = (values: UpdateCustomerUserNavigationPreferenceRequest) => {
    updateCustomerUserNavigationPreference(values).then(() => {
      Modal.success({
        title: '保存成功',
      });
    });
  };

  useEffect(() => {
    getCurrentUser().then(res => {
      console.log(res.customerUser.navigationPreference);
      form.setFieldsValue({
        navigationPreference: res.customerUser.navigationPreference ?? NavigationPreference.AUTO,
      });
    });
  }, [form]);

  return (
    <Wrapper routes={routes} className={styles.wrapperPadding}>
      <FormTitle title="个人偏好" />
      <Form form={form} onFinish={onFinish} style={{ width: '100%' }}>
        <SubContent>
          <Form.Item label="左侧导航栏" name="navigationPreference" required>
            <Radio.Group>
              <Radio value={NavigationPreference.AUTO}>{NavigationPreferenceDisplay[NavigationPreference.AUTO]}</Radio>
              <Radio value={NavigationPreference.OPEN}>{NavigationPreferenceDisplay[NavigationPreference.OPEN]}</Radio>
              <Radio value={NavigationPreference.HIDE}>{NavigationPreferenceDisplay[NavigationPreference.HIDE]}</Radio>
            </Radio.Group>
          </Form.Item>
          <Button type="primary" htmlType="submit" wrapStyle={{ marginTop: 8 }}>
            保存
          </Button>
        </SubContent>
      </Form>
    </Wrapper>
  );
};

export default NavigationPreferencePage;
