import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, List, Card, message, Spin, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import qs from 'qs';
import {
  refreshCurrrent,
  checkStaff,
  switchStaff,
  getStaffList,
  StaffResponse,
  useCurrent,
  getRealUrl,
  Button,
  locationReplace,
  locationAssign,
  getBase,
  getMicroAppBaseRoute,
} from '@maxtropy/components';
import { getCurrentNavigation } from '../../api/staff';
import { combineURL } from '@maxtropy/components';
import { TenantMenuOpenType } from '@maxtropy/components/es/type';

function linkUrl(path: string) {
  const hasOrigin = /^http/.test(path);
  if (hasOrigin) {
    const url = new URL(path);
    return path.slice(url.origin.length);
  }
  return path;
}

const StaffSelection: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [opened, setOpened] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<StaffResponse[]>();
  const current = useCurrent();
  const muid = current?.customerUser.muid;
  const mcid = current?.tenant?.mcid;
  const { search } = useLocation();
  const { redirect = '' }: { redirect?: string } = qs.parse(search, { ignoreQueryPrefix: true });

  // 选择过租户，自动跳转到home页
  useEffect(() => {
    if (mcid) {
      jump();
    }
  }, [mcid]);

  useEffect(() => {
    if (!muid) return;
    setLoading(true);
    getStaffList(muid)
      .then(res => {
        if (res) {
          setDataSource(res.list);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [muid]);

  const noStaff = (
    <Modal
      className={styles.noStaffModal}
      maskClosable={false}
      open={opened}
      onCancel={() => setOpened(false)}
      footer={null}
    >
      <p className={styles.p}>
        <ExclamationCircleOutlined className={styles.icon} />
        <span className={styles.span}>您好，您尚未开通任何企业内的员工账号</span>
      </p>
      <Button type="primary" onClick={() => setOpened(false)}>
        关闭
      </Button>
    </Modal>
  );

  const renderContent = (item: StaffResponse) => (
    <div className={styles.mod}>
      <div className={styles.modHd}>
        {item.logoUrl && (
          <>
            <div className={styles.logo}>
              <img className={styles.img} src={getRealUrl(item.logoUrl)} alt="logo" />
            </div>
            <Divider type="vertical" className={styles.divider} />
          </>
        )}
        <div className={styles.name}>{item.tenantName}</div>
      </div>
      <div className={styles.modBd}>
        <ul>
          <li>所在组织全称：{item.customerFullName}</li>
          <li>所在组织简称：{item.customerName}</li>
          <li>上级组织：{item.parentCustomerName ?? '--'}</li>
          <li>员工工号：{item.staffCode}</li>
        </ul>
      </div>
    </div>
  );

  const jump = () => {
    getCurrentNavigation().then(jumpPath => {
      if (!jumpPath) {
        // navigate('/home', {
        //   replace: true,
        // });
        locationReplace('/home');
        return;
      }

      // 微前端环境下，根据模块id跳转不同的微应用
      if (getBase()) {
        const origin = window.location.origin;
        const microAppBaseRoute = getMicroAppBaseRoute();
        if (jumpPath.moduleOriginId === 1) jumpPath.moduleOrigin = origin + microAppBaseRoute + '/ms-cc/';
        else if (jumpPath.moduleOriginId === 2) jumpPath.moduleOrigin = origin + microAppBaseRoute + '/ms-dc/';
        else if (jumpPath.moduleOriginId === 3) jumpPath.moduleOrigin = origin + microAppBaseRoute + '/ms-ds/';
        else if (jumpPath.moduleOriginId === 4) jumpPath.moduleOrigin = origin + microAppBaseRoute + '/ms-ids/';
      }

      let path: string;
      if (jumpPath.moduleOrigin) {
        path = combineURL(jumpPath.moduleOrigin, jumpPath.path);
      } else {
        path = jumpPath.path!;
      }

      if (getBase()) {
        locationReplace(path);
        return;
      }

      const hasOrigin = /^http/.test(path);
      if (hasOrigin) {
        const url = new URL(path);
        if (url.origin !== origin) {
          locationReplace(path);
          return;
        }
      }
      if (jumpPath.openType === TenantMenuOpenType.CurrentPage) {
        // navigate(linkUrl(jumpPath.path!),{replace:true});
        locationReplace(linkUrl(jumpPath.path!));
      } else {
        locationReplace(path);
      }
    });
  };

  const onSelectStaff = async (item: StaffResponse) => {
    try {
      await checkStaff(item.id);
    } catch (e: any) {
      if (e.status === 412 || e.status === 403) {
        messageApi.error('该staff已经禁用/冻结');
      }
      throw e;
    }
    switchStaff(item.id).then(res => {
      refreshCurrrent(() => {
        jump();
      });
    });
  };

  const multiStaff = (
    <div className={styles.multiStaffList}>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        dataSource={dataSource}
        renderItem={item => (
          <List.Item>
            <Card>
              {renderContent(item)}
              <div className={styles.cardFooter}>
                <Button type="link" onClick={() => onSelectStaff(item)}>
                  进入此账号
                </Button>
              </div>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <Spin spinning={loading}>
      {dataSource ? (dataSource.length ? multiStaff : noStaff) : null}
      {contextHolder}
    </Spin>
  );
};

export default StaffSelection;
