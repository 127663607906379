import React, { Key, useEffect, useState } from 'react';
import { Space, Spin } from 'antd';
import styles from '../index.module.scss';
import { FormOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getDepartmentTree, deleteDepartment, DepartmentTreeProps } from '../../../api/ql-user';
import { DataNode } from 'antd/lib/tree';
import DeptOpModal from './DeptOpModal';
import { useCurrent, useUpdate, Empty, Tooltip, Tree } from '@maxtropy/components';

interface PermissionGatherProps {
  selectTreeKey: (keys: Key[]) => void;
  accountApiIsOk: boolean;
}
export interface CurrentDataProps extends DepartmentTreeProps {
  parentName?: string;
}

const PermissionGather: React.FC<PermissionGatherProps> = ({ selectTreeKey, accountApiIsOk }) => {
  const [deptModalVisible, setDeptModalVisible] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [currentData, setCurrentData] = useState<CurrentDataProps>();
  const [list, setList] = useState<DataNode[]>([]);
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState(false);
  const userInfo = useCurrent();

  const mcid = userInfo?.tenant?.mcid;
  useEffect(() => {
    if (mcid && accountApiIsOk) {
      setLoading(true);
      getDepartmentTree(mcid)
        .then(res => {
          let tempTreeData = formatTreeData(res.list);
          setList(tempTreeData);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcid, updateState, accountApiIsOk]);

  // 删除部门
  const deleteDeptBtn = (deptId: string) => {
    deleteDepartment(deptId).then(res => {
      update();
    });
  };
  const formatTreeData = (data: DepartmentTreeProps[], parentInfo?: DepartmentTreeProps) => {
    return data.map(item => {
      const res: DataNode = {
        ...item,
        key: item?.qflowDeptId ?? '',
        title: titleFormat(item, parentInfo),
        children: formatTreeData(item.children ?? [], item),
      };
      return res;
    });
  };

  const titleFormat = (data: DepartmentTreeProps, parentInfo?: DepartmentTreeProps) => {
    return (
      <div>
        {data.name}
        <Space size={8} className={styles.titleFormatIcon}>
          {
            <>
              {/*编辑*/}
              {parentInfo && (
                <Tooltip title="编辑">
                  <FormOutlined
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentData({
                        ...data,
                        parentName: parentInfo?.name,
                      });
                      setDeptModalVisible(true);
                      setIsAdd(false);
                    }}
                  />
                </Tooltip>
              )}
              {/*新增*/}
              {
                <Tooltip title="新增">
                  <PlusCircleOutlined
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentData({
                        ...data,
                        parentName: parentInfo?.name,
                      });
                      setDeptModalVisible(true);
                      setIsAdd(true);
                    }}
                  />
                </Tooltip>
              }
              {/*删除 如果有子部门则不能删除*/}
              {parentInfo && !(data.children && data.children.length > 0) && (
                <Tooltip title="删除">
                  <MinusCircleOutlined
                    onClick={e => {
                      e.stopPropagation();
                      deleteDeptBtn(data.qflowDeptId!);
                    }}
                  />
                </Tooltip>
              )}
            </>
          }
        </Space>
      </div>
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        {list && list.length > 0 ? (
          <Tree
            className={styles.treeBox}
            height={500}
            showLine={{ showLeafIcon: false }}
            treeData={list}
            defaultExpandAll
            style={{ marginTop: 20 }}
            onSelect={selectTreeKey}
          />
        ) : (
          <Empty />
        )}
      </Spin>
      {deptModalVisible && (
        <DeptOpModal
          isAdd={isAdd}
          currentData={currentData}
          onCancel={refresh => {
            refresh && update();
            setDeptModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default PermissionGather;
