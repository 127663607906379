import { Key, useEffect } from 'react';
import { isBecomeLeader, UserProps } from '../../../api/ql-user';
import ShowInput from '../../../components/ShowInput';
import { Form, Modal, Select } from '@maxtropy/components';

interface Iprops {
  onCancel?: () => void;
  onConfirm?: () => void;
  deptId?: Key;
  currentData?: UserProps;
}
const StaffEditModal: React.FC<Iprops> = ({ onCancel, onConfirm, currentData, deptId }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(currentData);
  }, [currentData, form]);
  // 确认按钮
  const confirmBtn = () => {
    let values = form.getFieldsValue();
    isBecomeLeader({
      deptId: deptId as string,
      userId: currentData?.userId!,
      add: values.isLeader,
    }).then(_ => {
      onConfirm?.();
    });
  };
  return (
    <Modal
      open
      size="normal"
      title="编辑员工职位"
      onCancel={() => onCancel && onCancel()}
      contentClassName="modal-form-content"
      onOk={confirmBtn}
    >
      <Form form={form}>
        <Form.Item label="姓名 " name="name">
          <ShowInput />
        </Form.Item>
        <Form.Item label="员工工号 " name="userId">
          <ShowInput />
        </Form.Item>
        <Form.Item label="职位 " name="isLeader" rules={[{ required: true, message: '请输入职位' }]}>
          <Select>
            <Select.Option value={true}>部门主管</Select.Option>
            <Select.Option value={false}>成员</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="状态 " name="beingDisabled">
          <Status />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const Status: React.FC<{ value?: boolean }> = ({ value }) => {
  return (
    <>
      <ShowInput value={value ? '禁用' : '启用'} />
    </>
  );
};
export default StaffEditModal;
