import {
  Table,
  Paging,
  EllipsisSpan,
  usePaging,
  useUpdate,
  Form,
  Input,
  TreeSelect,
  CustomFilter,
} from '@maxtropy/components';
import { FC, useState, Key, useEffect } from 'react';
import { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { getOrganizationWithCodeWithCurrent } from '../../../api/customer';
import { formatTreeData } from '../../../lib/util';
import { ColumnType } from 'antd/es/table';
import { getStaffOuPage, OuResponse, StaffOuRequest } from '../../../api/staff';
import { OuStaffType, OuStatus, OuStatusDisplay } from '../../../api/const';

export const ouColumns: ColumnType<OuResponse>[] = [
  {
    title: '运营单元编号',
    dataIndex: 'serialNumber',
    width: 200,
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元名称',
    dataIndex: 'name',
    width: 200,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    width: 100,
    dataIndex: 'status',
    render: (v: OuStatus) => <EllipsisSpan value={OuStatusDisplay[v]} />,
  },
  {
    title: '所属组织',
    width: 300,
    dataIndex: 'customerName',
    render: (v: number) => <EllipsisSpan value={String(v)} />,
  },
];

interface OuModalProps {
  staffId?: number;
  type?: OuStaffType;
  ouIds?: number[];
  onChange?: (value: number[]) => void;
}

type SearchParams = Omit<StaffOuRequest, 'size' | 'page' | 'type' | 'staffId'>;

const OuModal: FC<OuModalProps> = ({ staffId, type, ouIds, onChange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState] = useUpdate();

  const [searchForm] = Form.useForm();

  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);

  const [data, setData] = useState<Array<OuResponse>>([]);

  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    if (staffId && type !== undefined) {
      setLoading(true);
      getStaffOuPage({
        staffId,
        type,
        page: pageOffset,
        size: pageSize,
        ...searchParams,
      })
        .then(res => {
          setData(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId, pageOffset, type, pageSize, searchParams, updateState]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      onChange?.(selectedRowKeys as number[]);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: ouIds,
  };

  const onFinish = (record: SearchParams) => {
    setPageOffset(1);
    setSearchParams(record);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  return (
    <>
      {type !== undefined && (
        <>
          <CustomFilter<SearchParams> form={searchForm} onFinish={onFinish} onReset={onReset} colSpan={8}>
            <Form.Item name="ouName" label="运营单元名称">
              <Input placeholder="请输入名称查询" maxLength={20} />
            </Form.Item>
            <Form.Item name="mcid" label="所在组织">
              <TreeSelect placeholder="请选择" treeData={treeData} />
            </Form.Item>
          </CustomFilter>
          <Table
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            sticky
            scroll={{ y: 300 }}
            rowKey="id"
            columns={ouColumns}
            dataSource={data}
          />
          <Paging pagingInfo={pagingInfo} />
        </>
      )}
    </>
  );
};

export default OuModal;
