export enum Gender {
  MALE = 0,
  FEMALE = 1,
}

export const GenderDisplay = {
  [Gender.MALE]: '男',
  [Gender.FEMALE]: '女',
};

export enum UserStatus {
  ENABLE = 0,
  DISABLE = 1,
  FREEZE = 2,
}

export const UserStatusDisplay = {
  [UserStatus.ENABLE]: '启用',
  [UserStatus.DISABLE]: '禁用',
  [UserStatus.FREEZE]: '冻结',
};

export enum RoleStatus {
  ENABLE = 0,
  FREEZE = 1,
  INVALID = 2,
}

export const RoleStatusDisplay = {
  [RoleStatus.ENABLE]: '启用',
  [RoleStatus.FREEZE]: '冻结',
  [RoleStatus.INVALID]: '作废',
};

export enum RoleType {
  PREFACT = 0,
  OWNER = 1,
}

export const RoleTypeDisplay = {
  [RoleType.PREFACT]: '平台预置',
  [RoleType.OWNER]: '自建角色',
};

export enum AuthorityType {
  PROJECT = 0, //项目数据权限
  ORGANIZATION = 1,
}

export enum RoleLevel {
  MGMT = 0,
  TENANT = 1,
}

export enum BusinessScope {
  ELECTRICITYDISTRIBUTIONSALES = 1,
  NEWENERGY = 2,
}

export const BusinessScopeDisplay = {
  [BusinessScope.ELECTRICITYDISTRIBUTIONSALES]: '配售电物业管理信息',
  [BusinessScope.NEWENERGY]: '分布式新能源物业管理信息',
};

export enum PropertyManagementConfigOperationStaffStatus {
  DISABLE = 0,
  ENABLE = 1,
}

export const PropertyManagementConfigOperationStaffStatusDisplay = {
  [PropertyManagementConfigOperationStaffStatus.DISABLE]: '停用',
  [PropertyManagementConfigOperationStaffStatus.ENABLE]: '启用',
};

export enum WorkDivisionEnum {
  METERREADER = 1,
  AUDITOR = 2,
  ACCOUNTANT = 3,
  REVIEWER = 4,
}

export const WorkDivisionEnumDisplay = {
  [WorkDivisionEnum.METERREADER]: '抄表员',
  [WorkDivisionEnum.AUDITOR]: '审核员',
  [WorkDivisionEnum.ACCOUNTANT]: '结算员',
  [WorkDivisionEnum.REVIEWER]: '复核员',
};

export enum OuStatus {
  DISABLE = 2,
  ENABLE = 1,
}

export const OuStatusDisplay = {
  [OuStatus.DISABLE]: '作废',
  [OuStatus.ENABLE]: '启用',
};

export enum OuStaffType {
  MEMBER,
  ADMINISTRATOR,
  CHANNEL,
}

export const OuStaffTypeDisplay = {
  [OuStaffType.MEMBER]: '成员',
  [OuStaffType.ADMINISTRATOR]: '管理员',
  [OuStaffType.CHANNEL]: '可访问租户运营单元成员',
};

export enum LeftNavPreference {
  OPEN_CLOSE = 1,
  ALL_OPEN,
  ALL_CLOSE,
}

export const LeftNavPreferenceDisplay = {
  [LeftNavPreference.OPEN_CLOSE]: '收起/展开',
  [LeftNavPreference.ALL_OPEN]: '一直展开',
  [LeftNavPreference.ALL_CLOSE]: '直接隐藏',
};
