import { FC, useEffect } from 'react';
import { TenantLayout as LayoutComponents, Themes, activateTheme, AuthorizedPage } from '@maxtropy/components';

import styles from './index.module.scss';

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return (
    <div className={styles.layout}>
      <AuthorizedPage>
        <LayoutComponents isDefaultLogo>{children}</LayoutComponents>
      </AuthorizedPage>
    </div>
  );
};

export default Layout;
