import { FC, useEffect, useState } from 'react';
import { Space, Divider, Col } from 'antd';
import {
  Wrapper,
  Filter,
  Table,
  EllipsisSpan,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  Modal,
  Form,
  Select,
  Checkbox,
  CustomFilter,
} from '@maxtropy/components';
import { getRoleList, RoleListResponse, RoleListRequest, updateRoleState } from '../../api/role';
import { RoleStatus, RoleStatusDisplay, RoleType, RoleTypeDisplay } from '../../api/const';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import qs from 'qs';

const columns = [
  {
    title: '角色ID',
    dataIndex: 'id',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '角色名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    width: 120,
    ellipsis: { showTitle: true },
    render: (value: RoleStatus) => {
      return <EllipsisSpan value={RoleStatusDisplay[value]} />;
    },
  },
  {
    title: '角色类型',
    dataIndex: 'level',
    width: 120,
    ellipsis: { showTitle: true },
    render: (value: RoleType) => {
      return <EllipsisSpan value={RoleTypeDisplay[value]} />;
    },
  },
  {
    title: '角色归属',
    dataIndex: 'organize',
    ellipsis: { showTitle: true },
    render: (value: string) => {
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '职员数',
    dataIndex: 'countStaff',
    ellipsis: { showTitle: true },
    render: (value: string) => {
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后修改时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

export type FilterParams = Omit<RoleListRequest, 'page' | 'size'>;

const PrefabRoleList: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, update] = useUpdate();
  const [tableSource, setTableSource] = useState<RoleListResponse[]>([]);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [search, setSearch] = useState<Omit<RoleListRequest, 'page' | 'size'>>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const getData = (page?: RoleListRequest) => {
    setLoading(true);
    getRoleList({ ...search, size: pageSize, page: pageOffset, ...page })
      .then(res => {
        setTotalCount(res.total);
        setPageOffset(res.page);
        setTableSource(res.list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!search) return;
    getData({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getData({ page: pageOffset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, updateState]);

  const updateRoleStateFn = (title: string, id: number, state: RoleStatus) => {
    Modal.confirm({
      title: `${title}角色`,
      content: `即将${title}该角色，点击确认以操作该执行`,
      onOk() {
        updateRoleState(id, state).then(res => {
          if (res.result) {
            Modal.success({
              content: `操作成功`,
              onOk() {
                update();
              },
            });
          }
        });
      },
    });
  };

  const onFinish = (value: FilterParams) => {
    value['state'] = value['state'] ?? undefined;
    value['toVoid'] = value['toVoid'] ?? false;
    value['level'] = value['level'] ?? undefined;
    setSearch({ ...value });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 350,
      fixed: 'right' as 'right',
      render: (value: undefined, record: RoleListResponse) => {
        return (
          <Space size={16}>
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/system/rolePer/role/detail/${record.code}`} target="_blank">
                查看详情
              </Link>
            </Button>
            {record.isOwner ? (
              <>
                <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
                  <Link to={`/system/rolePer/role/update/${record.code}`} target="_blank">
                    编辑
                  </Link>
                </Button>
                {(record.state === RoleStatus.FREEZE || record.state === RoleStatus.INVALID) && (
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => {
                      updateRoleStateFn('启用', record.id, RoleStatus.ENABLE);
                    }}
                    type="link"
                  >
                    启用
                  </Button>
                )}
                {(record.state === RoleStatus.FREEZE || record.state === RoleStatus.ENABLE) && (
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => {
                      updateRoleStateFn('作废', record.id, RoleStatus.INVALID);
                    }}
                    type="link"
                  >
                    作废
                  </Button>
                )}
                {(record.state === RoleStatus.ENABLE || record.state === RoleStatus.INVALID) && (
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => {
                      updateRoleStateFn('冻结', record.id, RoleStatus.FREEZE);
                    }}
                    type="link"
                  >
                    冻结
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
                  <Link
                    to={`/system/rolePer/role/create?${qs.stringify(
                      { type: 'copy', copyId: record.id },
                      { indices: false }
                    )}`}
                    target="_blank"
                  >
                    复制新建
                  </Link>
                </Button>
              </>
            )}
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/system/rolePer/role/permissions/bind/organization/${record.code}`}>绑定</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  const onReset = () => {
    setSearch({});
  };

  const [form] = Form.useForm();

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val)} onReset={onReset} hasClear>
      <Form.Item name="state" label="角色状态">
        <Select placeholder="请选择角色状态">
          <Select.Option value={undefined}>全部</Select.Option>
          <Select.Option value={RoleStatus.ENABLE}>{RoleStatusDisplay[RoleStatus.ENABLE]}</Select.Option>
          <Select.Option value={RoleStatus.FREEZE}>{RoleStatusDisplay[RoleStatus.FREEZE]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item valuePropName="checked" name="toVoid">
        <Checkbox>显示已作废角色</Checkbox>
      </Form.Item>
      <Form.Item name="level" label="角色类型">
        <Select placeholder="请选择角色类型">
          <Select.Option value={undefined}>全部</Select.Option>
          <Select.Option value={RoleType.PREFACT}>{RoleTypeDisplay[RoleType.PREFACT]}</Select.Option>
          <Select.Option value={RoleType.OWNER}>{RoleTypeDisplay[RoleType.OWNER]}</Select.Option>
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className="page_wrapper" filters={filters}>
      <div className={styles.operationArea}>
        <Space>
          <Button type="primary">
            <Link to="/system/rolePer/role/create" target="_blank">
              <PlusOutlined />
              新建角色
            </Link>
          </Button>
        </Space>
      </div>
      <Table rowKey="id" scroll={{ x: 1500 }} loading={loading} dataSource={tableSource} columns={buildColumns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default PrefabRoleList;
