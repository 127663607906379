import { EllipsisSpan, Table, useUpdate, Modal, Button } from '@maxtropy/components';
import { Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getUserListAndStaffIds, updateUserStatus, UserProps } from '../../../api/ql-user';

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'userId',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'beingDisabled',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v ? '禁用' : '启用'} />,
  },
];
interface Iprops {
  deptId?: number;
  updateP?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}
const AccountOpModal: React.FC<Iprops> = ({ onCancel, onConfirm, updateP }) => {
  const [dataList, setDataList] = useState<UserProps[]>([]);
  const [updateState, update] = useUpdate();
  useEffect(() => {
    getUserListAndStaffIds().then(res => {
      setDataList(res.userList ?? []);
    });
  }, [updateState]);
  // 启用禁用
  const changeStatus = (useId: string, status: boolean) => {
    updateUserStatus(useId, status).then(res => {
      // 如果配额已满显示弹窗
      if (!res.result) {
        Modal.error({
          title: '操作失败，当前账户数使用已达配额。',
        });
      } else {
        update();
        updateP && updateP();
      }
    });
  };
  const opColunms = {
    title: '操作',
    fixed: 'right' as const,
    render: (_: any, record: UserProps) => {
      return (
        <Space size={16}>
          <Button
            disabled={!record.beingDisabled}
            style={{ padding: 0 }}
            type="link"
            onClick={() => changeStatus(record.userId!, false)}
          >
            启用
          </Button>
          <Button disabled={record.beingDisabled} type="link" onClick={() => changeStatus(record.userId!, true)}>
            禁用
          </Button>
        </Space>
      );
    },
  };
  return (
    <Modal
      open
      size="large"
      title="管理账户"
      onCancel={() => onCancel && onCancel()}
      footer={
        <Row justify="end">
          <Button type="primary" onClick={() => onConfirm && onConfirm()}>
            确认
          </Button>
        </Row>
      }
    >
      <Table sticky rowKey="userId" scroll={{ y: 450 }} columns={[...columns, opColunms]} dataSource={dataList} />
    </Modal>
  );
};

export default AccountOpModal;
