import { EllipsisSpan, Table, useUpdate, Button } from '@maxtropy/components';
import { Space } from 'antd';
import { Key, useEffect, useState } from 'react';
import { getUserListAndStaffIds, moveUser, UserProps } from '../../../api/ql-user';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/hooks';
import styles from '../index.module.scss';
import StaffAddModal from './StaffAddModal';
import StaffEditModal from './StaffEditModal';

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'userId',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '职位',
    dataIndex: 'isLeader',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v ? '部门主管' : '成员'} />,
  },
  {
    title: '状态',
    dataIndex: 'beingDisabled',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v ? '禁用' : '启用'} />,
  },
];
interface Iprops {
  deptId?: Key;
}
const StaffList: React.FC<Iprops> = ({ deptId }) => {
  const [staffAddModalVisible, setStaffAddModalVisible] = useState(false);
  const [staffEditModalVisible, setStaffEditModalVisible] = useState(false);

  const [dataList, setDataList] = useState<UserProps[]>();
  const [currentEditData, setCurrentEditData] = useState<UserProps>();
  const [updateState, update] = useUpdate();
  const hasQlMovePers = useHasPermission(PermissionsType.B_QL_MOVE_USER);
  const hasQlEditUserPers = useHasPermission(PermissionsType.B_QL_EDIT_USER);

  useEffect(() => {
    if (deptId) {
      getUserListAndStaffIds(deptId).then(res => {
        setDataList(res.userList ?? []);
      });
    }
  }, [deptId, updateState]);
  // 编辑按钮
  const editBtn = (data: UserProps) => {
    setCurrentEditData(data);
    setStaffEditModalVisible(true);
  };
  const editModalConfirmBtn = () => {
    update();
    setStaffEditModalVisible(false);
  };
  // 移出集合
  const removeBtn = (info: UserProps) => {
    moveUser({
      deptId: deptId as string,
      userIds: [info.userId!],
      moveIn: false,
    }).then(res => {
      update();
    });
  };
  const opColunms = {
    title: '操作',
    fixed: 'right' as const,
    render: (_: any, record: UserProps) => {
      return (
        <Space>
          {hasQlEditUserPers && (
            <Button type="link" onClick={() => editBtn(record)}>
              编辑
            </Button>
          )}
          {hasQlMovePers && (
            <Button type="link" onClick={() => removeBtn(record)}>
              移出集合
            </Button>
          )}
        </Space>
      );
    },
  };
  return (
    <>
      <div className={styles.mgb}>
        {hasQlMovePers && (
          <Button
            type="primary"
            onClick={() => {
              setStaffAddModalVisible(true);
            }}
          >
            移入集合
          </Button>
        )}
      </div>
      <Table
        sticky
        rowKey="userId"
        bordered
        scroll={{ y: 500 }}
        columns={[...columns, opColunms]}
        dataSource={dataList}
      />
      {staffAddModalVisible && (
        <StaffAddModal
          userList={dataList}
          deptId={deptId}
          onConfirm={() => {
            update();
            setStaffAddModalVisible(false);
          }}
          onCancel={() => {
            setStaffAddModalVisible(false);
          }}
        />
      )}
      {staffEditModalVisible && (
        <StaffEditModal
          deptId={deptId}
          currentData={currentEditData}
          onConfirm={editModalConfirmBtn}
          onCancel={() => {
            setStaffEditModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default StaffList;
