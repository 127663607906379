import { fetch } from '@maxtropy/components';

export function getDefaultLogo(): Promise<{ key: string }> {
  return fetch(
    `/api/v2/file-center/getDefaultLogoKey`,
    {
      method: 'POST',
    },
    true
  );
}
