import React, { useEffect, useState } from 'react';
import { Descriptions, Divider } from 'antd';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { RoleUseInfo, getRoleUseInfo } from '../../../api/role';
import { fetchMcidsByRoleCode } from '../../../api/tenant';
import { useBreadcrumbRoutes, Wrapper, Button } from '@maxtropy/components';
import { RoleSteps } from '../../../components/CommonSteps';
import OrganizationTree from './OrganizationTree';
import styles from './index.module.scss';

const routes = [{ name: '查看角色权限' }];

const BindDetail: React.FC = () => {
  const [mcids, setMcids] = useState<string[]>();
  const [data, setData] = useState<RoleUseInfo>();
  const navigate = useNavigate();
  const { code } = useParams<{ code: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (!isNil(code)) {
      fetchMcidsByRoleCode(code).then(data => {
        setMcids(data.list);
      });
    }
  }, [code]);

  useEffect(() => {
    if (!isNil(code)) {
      getRoleUseInfo(code).then(res => {
        setData(res);
      });
    }
  }, [code]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <RoleSteps current={2} isDetail />
      <div className={styles.content}>
        <div className={styles.formItem}>
          <div className={styles.label}>使用角色的组织情况</div>
          <OrganizationTree value={mcids} />
        </div>
        <Descriptions className={styles.desc}>
          <Descriptions.Item label="使用角色组织数">{data?.customerCount || 0}</Descriptions.Item>
          <Descriptions.Item label="使用角色员工数">{data?.staffCount || 0}</Descriptions.Item>
        </Descriptions>
        <Divider />
        <ul className={styles.list}>
          {data?.useInfoList.map(item => (
            <li key={item.code}>
              <dl className={styles.top}>
                <dt>组织名</dt>
                <dd>{item.name}</dd>
              </dl>
              <dl className={styles.middle}>
                <dt>组织编号</dt>
                <dd>{item.code || '--'}</dd>
              </dl>
              <dl className={styles.bottom}>
                <dt>员工数</dt>
                <dd>{item.num}</dd>
              </dl>
            </li>
          ))}
        </ul>
        <Button
          onClick={() => {
            navigate(`/system/rolePer/role`);
          }}
        >
          返回列表
        </Button>
      </div>
    </Wrapper>
  );
};

export default BindDetail;
