import { message, Modal, EllipsisSpan, Table, Button } from '@maxtropy/components';
import { Row } from 'antd';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { getStaffDeptList, moveUser, QlStaffProps, UserProps } from '../../../api/ql-user';

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface Iprops {
  userList?: UserProps[];
  deptId?: Key;
  onCancel?: () => void;
  onConfirm?: () => void;
}
const StaffAddModal: React.FC<Iprops> = ({ onCancel, onConfirm, userList, deptId }) => {
  const [selectedStaffCodes, setSelectedStaffCodes] = useState<string[]>();
  const currentRows = useRef<QlStaffProps[]>([]);
  const [dataList, setDataList] = useState<QlStaffProps[]>([]);
  const staffCodes = useMemo(() => {
    return userList?.map(item => item.userId!);
  }, [userList]);
  useEffect(() => {
    getStaffDeptList(deptId!).then(res => {
      setDataList(res.list ?? []);
    });
  }, [deptId]);
  // 移入集合
  const moveBtn = () => {
    if (dataList.length === 0) {
      onCancel?.();
      return;
    }
    if (!selectedStaffCodes || selectedStaffCodes.length === 0) {
      message.error('请选择员工');
      return;
    }
    moveUser({
      deptId: deptId as string,
      userIds: selectedStaffCodes,
      moveIn: true,
    }).then(res => {
      onConfirm?.();
    });
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], row: QlStaffProps[]) => {
      currentRows.current = row;
      let codes = selectedRowKeys as string[];
      setSelectedStaffCodes(codes);
    },
    selectedRowKeys: selectedStaffCodes ?? staffCodes,
    getCheckboxProps: (rows: QlStaffProps) => ({
      disabled: staffCodes?.includes(rows.staffCode!),
      staffCode: rows.staffCode,
    }),
  };
  return (
    <>
      <Modal
        open
        width={800}
        title="添加员工至权限集合"
        onCancel={() => onCancel?.()}
        footer={
          <Row justify="end">
            <Button type="primary" onClick={moveBtn}>
              确认
            </Button>
          </Row>
        }
      >
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          sticky
          scroll={{ y: 500 }}
          rowKey="staffCode"
          columns={columns}
          dataSource={dataList}
        />
      </Modal>
    </>
  );
};

export default StaffAddModal;
