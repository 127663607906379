import React, { useEffect, useState } from 'react';
import { Form, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { useBreadcrumbRoutes, Wrapper, Button, useSubmission, Modal } from '@maxtropy/components';
import { RoleLevel, RoleType } from '../../../api/const';
import { postRoleMcids, deleteRoleMcids, fetchMcidsByRoleCode } from '../../../api/tenant';
import { findRoleByCode, RoleInfo } from '../../../api/role';
import { RoleSteps } from '../../../components/CommonSteps';
import OrganizationTree from './OrganizationTree';
import styles from './index.module.scss';

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};

const routes = [{ name: '编辑角色权限' }];

const BindOrganization: React.FC = () => {
  const [mcids, setMcids] = useState<string[]>();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { code } = useParams<{ code: string }>();
  const [doSubmit, submitting] = useSubmission();
  const [role, setRole] = useState<RoleInfo>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (!code) return;
    findRoleByCode(code).then(data => {
      setRole(data);
    });
  }, [code]);

  useEffect(() => {
    if (!isNil(code)) {
      fetchMcidsByRoleCode(code).then(data => {
        form.setFieldsValue({
          ids: data.list,
        });
        setMcids(data.list);
      });
    }
  }, [code, form]);

  const onClose = () => {
    Modal.confirm({
      content: '确认放弃未保存的修改返回列表吗?',
      onOk: () => {
        navigate(`/system/rolePer/role`);
      },
    });
  };

  const onFinish = async (values: any) => {
    const list = values.ids.checked as string[];
    if (!(role?.level === RoleLevel.MGMT && role?.type === RoleType.PREFACT)) {
      if (Array.isArray(list) && list.length === 0) {
        form.setFields([
          {
            name: 'ids',
            value: values.ids,
            errors: ['请选择组织'],
          },
        ]);
        return;
      }
    }
    const idsAdd = list?.filter(key => !mcids?.includes(key));
    const idsDelete = (mcids || []).filter(key => !list?.includes(key));
    await doSubmit(async () => {
      if (list && idsAdd.length > 0) {
        await postRoleMcids({
          roleCode: code!,
          mcidList: idsAdd,
        });
      }
      if (list && idsDelete.length > 0) {
        await deleteRoleMcids({
          roleCode: code!,
          mcidList: idsDelete,
        });
      }
      navigate(`/system/rolePer/role/permissions/bind/staff/${code}`);
    });
  };

  let rules = [{ required: true, message: '请选择组织' }];
  if (role?.level === RoleLevel.MGMT && role?.type === RoleType.PREFACT) {
    rules = [];
  }

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <RoleSteps current={2} isDetail={false} />
      <div className={styles.formWrapper}>
        <Form
          {...formLayout}
          form={form}
          layout="horizontal"
          className={styles.form}
          validateTrigger={['onBlur']}
          onFinish={onFinish}
        >
          <Form.Item label="授权情况" name="ids" rules={rules}>
            <OrganizationTree role={role} mcids={mcids} />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 15, offset: 8 }}>
            <Space size={8}>
              <Button onClick={() => navigate(`/system/rolePer/role/permissions/update/${code}`)}>上一步</Button>
              <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
                下一步
              </Button>
              <Button onClick={onClose}>关闭</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Wrapper>
  );
};

export default BindOrganization;
