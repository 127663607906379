import React from 'react';
import { Space, Typography } from 'antd';

type AuthBannerProps = {
  title: string;
  statistic?: number;
  logoUrl: string;
};

export default function AuthBanner({ title, statistic, logoUrl }: AuthBannerProps) {
  return (
    <Space align="center">
      <img src={logoUrl} alt="" />
      <Space direction="vertical">
        <Typography.Text>{title}</Typography.Text>
        <Typography.Title level={3} style={{ color: '#52E7FF' }}>
          {statistic ?? '--'}
        </Typography.Title>
      </Space>
    </Space>
  );
}
