import { fetch } from '@maxtropy/components';

export interface ApplicationWithSubFlag {
  appId: string;
  name: string;
  simpleName: string;
  description: string;
  logoUrl: string;
  avatarUrl: string;
  entryUrl: string;
  contentUrl: string;
  ordering: number;
  subscriptionEnabled: boolean;
  createTime: string;
  updateTime: string;
  hasValidSub: boolean;
}

export interface AppFunctionPermissionVo {
  id: number;
  code: string;
  name: string;
  parentId: number;
  type: string;
  standard: boolean;
  children?: AppFunctionPermissionVo[];
  pic: string;
  description: string;
}

export function getAppPermissionTree(): Promise<{ list: AppFunctionPermissionVo[] }> {
  return fetch(
    `/api/v2/app/function/permission/tree`,
    {
      method: 'POST',
    },
    true
  );
}
