import React, { MouseEvent, useState } from 'react';
import classNames from 'classnames';
import './status-button.scss';

interface StatusButtonProps {
  checked?: boolean;
  onChange?: (event: MouseEvent, value: boolean) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const StatusButton: React.FC<StatusButtonProps> = props => {
  const prefixCls = 'cc';
  const baseClassName = `${prefixCls}-status-button`;

  const [checked, setChecked] = useState<boolean | undefined>(false);

  React.useEffect(() => {
    if ('checked' in props) {
      setChecked(props.checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checked]);

  const handleClick = (e: MouseEvent) => {
    if (!('checked' in props)) {
      setChecked(!checked);
    }
    props.onChange?.(e, !checked);
  };

  return (
    <>
      <span
        className={classNames(baseClassName, {
          [`${baseClassName}-checked`]: checked,
          [`${baseClassName}-disabled`]: !!props.disabled,
        })}
        onClick={handleClick}
        style={props.style}
      >
        {props.children}
      </span>
    </>
  );
};

export default StatusButton;
