import {
  Table,
  Paging,
  EllipsisSpan,
  usePaging,
  useUpdate,
  Form,
  Input,
  CustomFilter,
  Select,
} from '@maxtropy/components';
import { FC, useState, Key, useEffect } from 'react';
import { ColumnType } from 'antd/es/table';
import { StaffOuRequest } from '../../../api/staff';
import { OuStaffType } from '../../../api/const';
import {
  apiV2ChannelTenantOuPagePost,
  apiV2ChannelTenantTenantListPost,
  V2ChannelTenantTenantListPostResponse,
  V2ChannelTenantOuPagePostResponse,
} from '@maxtropy/cc-customer-apis-v2';

type RowData = Exclude<V2ChannelTenantOuPagePostResponse['list'], undefined>[number];

enum OuStatus {
  DISABLE = 0,
  ENABLE = 1,
  INVALID = 2,
}

const OuStatusDisplay = {
  [OuStatus.DISABLE]: '禁用',
  [OuStatus.ENABLE]: '启用',
  [OuStatus.INVALID]: '作废',
};

export const channelOuColumns: ColumnType<RowData>[] = [
  {
    title: '运营单元编号',
    dataIndex: 'serialNumber',
    width: 200,
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元名称',
    dataIndex: 'name',
    width: 200,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元状态',
    width: 200,
    dataIndex: 'status',
    render: (v: OuStatus) => <EllipsisSpan value={OuStatusDisplay[v]} />,
  },
  {
    title: '所属组织',
    width: 200,
    dataIndex: 'mcidName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属租户',
    width: 200,
    dataIndex: 'tenantMcidName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface OuModalProps {
  staffId?: number;
  type?: OuStaffType;
  ouIds?: number[];
  onChange?: (value: number[]) => void;
}

type SearchParams = Omit<StaffOuRequest, 'size' | 'page' | 'type' | 'staffId'>;

const ChannelOuModal: FC<OuModalProps> = ({ staffId, type, ouIds, onChange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState] = useUpdate();

  const [searchForm] = Form.useForm();

  const [data, setData] = useState<V2ChannelTenantOuPagePostResponse['list']>([]);
  const [tenantList, setTenantList] = useState<V2ChannelTenantTenantListPostResponse['list']>([]);

  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    if (staffId && type !== undefined) {
      setLoading(true);
      apiV2ChannelTenantOuPagePost({
        page: pageOffset,
        size: pageSize,
        staffId,
        ...searchParams,
      })
        .then(res => {
          setData(res.list ?? []);
          setTotalCount(res.total || 0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId, pageOffset, type, pageSize, searchParams, updateState]);

  useEffect(() => {
    apiV2ChannelTenantTenantListPost({}).then(res => {
      setTenantList(res.list ?? []);
    });
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      onChange?.(selectedRowKeys as number[]);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: ouIds,
  };

  const onFinish = (record: SearchParams) => {
    setPageOffset(1);
    setSearchParams(record);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  return (
    <>
      {type !== undefined && (
        <>
          <CustomFilter<SearchParams> form={searchForm} onFinish={onFinish} onReset={onReset} colSpan={8}>
            <Form.Item name="ouName" label="运营单元名称">
              <Input placeholder="请输入名称查询" maxLength={20} />
            </Form.Item>
            <Form.Item name="mcid" label="所属租户">
              <Select
                placeholder="请选择"
                options={tenantList?.map(item => ({ label: item.name, value: item.mcid }))}
              />
            </Form.Item>
          </CustomFilter>
          <Table
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            sticky
            scroll={{ y: 300 }}
            rowKey="id"
            columns={channelOuColumns}
            dataSource={data}
            showSelectedCount
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </>
      )}
    </>
  );
};

export default ChannelOuModal;
