import { combineURL, Form, Radio, useTenantMenuNavigation } from '@maxtropy/components';
import { TenantMenuLevel, TenantMenuNavigationResponse } from '@maxtropy/components/es/type';
import { list2Tree, TenantMenuEntry } from '@maxtropy/components/es/components/Layout/TenantLayout/util';
import { Cascader, Col, Divider, FormInstance, Row } from 'antd';
import { cloneDeep } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getStaffNavigation, Navigation, navigationDisplay } from '../../api/staff';
import ShowInput from '../../components/ShowInput';
import { InfoCircleOutlined } from '@ant-design/icons';

export enum OperateType {
  Single = 'single',
  Mutiple = 'mutiple',
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export type CascaderValueType = [string, string, string] | undefined | null;

export interface FormValueProps {
  configType: Navigation;
  navigationProgramDetailId?: CascaderValueType;
}

interface HomeManageModalProps {
  operate?: OperateType;
  staffId?: number;
  staffIds?: number[];
  form: FormInstance<FormValueProps>;
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

function menuData2Option(data?: TenantMenuEntry[]) {
  if (!Array.isArray(data)) return undefined;
  const cloneList = cloneDeep(data);
  const options: Option[] = cloneList.map(node => ({
    value: node.currentId,
    label: node.name,
    children: node.level !== TenantMenuLevel.THREE ? menuData2Option(node.children) : undefined,
  }));
  return options;
}

function getPath(tenantMenuNavigation: TenantMenuNavigationResponse[], id?: CascaderValueType) {
  if (!id) return;
  if (tenantMenuNavigation) {
    const menu = tenantMenuNavigation.filter(i => i.currentId === id.at(2)).at(0);
    if (menu) {
      if (menu.moduleOrigin) {
        return combineURL(menu.moduleOrigin, menu.path);
      } else {
        return menu.path!;
      }
    }
  }
}

const HomeManageModal: FC<HomeManageModalProps> = ({ operate, staffId, form, staffIds }) => {
  const [staffForm] = Form.useForm();

  const tenantMenuNavigation = useTenantMenuNavigation();

  const [hasDelete, setHasDelete] = useState<boolean>(false);

  const options = useMemo(() => {
    return menuData2Option(list2Tree(tenantMenuNavigation ?? []));
  }, [tenantMenuNavigation]);
  // prettier-ignore
  useEffect(() => { // NOSONAR
    if (operate === OperateType.Single) {
      if (tenantMenuNavigation && staffId !== undefined) {
        getStaffNavigation(staffId).then(res => {
          form.setFieldsValue({
            configType: res.configType ?? Navigation.Default,
            navigationProgramDetailId:
              res.configType === Navigation.Custome
                ? res.rootId && res.parentId && res.currentId
                  ? [res.rootId, res.parentId, res.currentId]
                  : undefined
                : undefined,
          });
          if (res.configType === Navigation.Custome && !(res.rootId && res.parentId && res.currentId)) {
            setHasDelete(true);
          }
          staffForm.setFieldsValue({
            customerUserHumanCode: res.customerUserHumanCode,
            customerUserName: res.customerUserName,
            customerFullName: res.customerFullName,
            staffCode: res.staffCode,
          });
        });
      }
    }
  }, [tenantMenuNavigation, operate, form, staffForm, staffId]);

  const onCascaderChange = useCallback(() => {
    setHasDelete(false);
  }, []);

  return (
    <div style={{ minHeight: 450 }}>
      {operate === OperateType.Single && (
        <>
          <Form form={staffForm} {...formLayout} layout="vertical">
            <Row gutter={18}>
              <Col span={12}>
                <Form.Item name="customerUserHumanCode" label="用户ID">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="customerUserName" label="姓名">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="customerFullName" label="所属组织">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="staffCode" label="员工工号">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ margin: '0 0 15px 0' }} />
        </>
      )}
      <Form
        form={form}
        initialValues={{
          configType: Navigation.Default,
        }}
      >
        <Form.Item name="configType" label={<span style={{ fontWeight: 500 }}>首页类型</span>} {...formLayout}>
          <Radio.Group
            options={[
              { label: navigationDisplay[Navigation.Default], value: Navigation.Default },
              { label: navigationDisplay[Navigation.Custome], value: Navigation.Custome },
            ]}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['configType']}>
          {({ getFieldValue }) =>
            getFieldValue('configType') === Navigation.Custome && (
              <Form.Item
                name="navigationProgramDetailId"
                label="选择"
                extra={hasDelete ? '配置导航已更新或删除' : undefined}
              >
                <Cascader onChange={onCascaderChange} placeholder="请选择" options={options} />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item noStyle dependencies={['configType', 'navigationProgramDetailId']}>
          {({ getFieldValue }) => {
            const configType = getFieldValue('configType');
            const navigationProgramDetailId: CascaderValueType = getFieldValue('navigationProgramDetailId');
            const path =
              configType === Navigation.Default
                ? `${window.location.origin}/home`
                : getPath(tenantMenuNavigation ?? [], navigationProgramDetailId);
            return (
              <Form.Item {...formLayout} label="链接">
                <ShowInput value={path} />
              </Form.Item>
            );
          }}
        </Form.Item>
        {operate === OperateType.Mutiple && (
          <Form.Item {...formLayout} label="该首页设置应用员工数">
            <ShowInput style={{ color: 'var(--primary-color)' }} value={`${staffIds?.length ?? 0}个`} />
          </Form.Item>
        )}
      </Form>
      <Row align="middle" gutter={15}>
        <Col span={1} style={{ color: 'var(--mx-warning-color)' }}>
          <InfoCircleOutlined />
        </Col>
        <Col span={22} style={{ color: 'var(--mx-text-desc-color)' }}>
          若员工无自定义页面权限，则显示为默认首页。请勿配置无导航的系统外部页面。
        </Col>
      </Row>
    </div>
  );
};

export default HomeManageModal;
