import { Button, EllipsisSpan, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { useState } from 'react';
import { HrefType, HrefTypeDisPlay, NavItem, delNav } from '../../../../api/compositeNavConfig';
import CusNavEditModal from './CusNavEditModal';
import { useHasPermission } from '../../../../utils/hooks';
import { PermissionsType } from '../../../../common/permissionsConst';

const columns = [
  {
    title: '导航名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属分组',
    dataIndex: 'comprehensiveMenuGroupName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '导航链接',
    dataIndex: 'path',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '跳转方式',
    dataIndex: 'hrefType',
    ellipsis: { showTitle: true },
    render: (v: HrefType) => <EllipsisSpan value={HrefTypeDisPlay[v]} />,
  },
  {
    title: '排序权重',
    dataIndex: 'sortWeight',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

interface Props {
  data: NavItem[];
  updateFn: () => void;
}

const CustomNav: React.FC<Props> = ({ data, updateFn }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [navItem, setNavItem] = useState<NavItem>();
  const hasEdit = useHasPermission(PermissionsType.B_EDIT_NAVIGATION); // 编辑导航
  const hasDelete = useHasPermission(PermissionsType.B_DELETE_NAVIGATION); // 删除导航

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (v: any, record: NavItem) => (
        <Space>
          {hasDelete && (
            <Button
              type="link"
              onClick={() => {
                delNav(record.id).then(res => {
                  if (res.influencedLineAmount) {
                    updateFn();
                  }
                });
              }}
            >
              删除
            </Button>
          )}
          {hasEdit && (
            <Button
              type="link"
              onClick={() => {
                setNavItem(record);
                setModalOpen(true);
              }}
            >
              编辑
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table columns={buildColumns} dataSource={data} />
      {modalOpen && (
        <CusNavEditModal
          updateFn={updateFn}
          data={navItem}
          isEdit={true}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};

export default CustomNav;
