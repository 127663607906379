import { fetch } from '@maxtropy/components';

export interface NavigationDetail {
  id: number;
  // 名称
  name?: string;
  // 类型（1、平台导航，2、自定义导航）
  type?: number;
  // 排序权重
  sortWeight?: number;
  // 关联分组ID
  comprehensiveMenuGroupId?: number;
  // 关联分组名称
  comprehensiveMenuGroupName?: string;
  // 链接地址
  path?: string;
  // 对应导航权限code
  functionPermissionCode?: string;
  // 对应导航权限名称
  functionPermissionCodeName?: string;
  // 打开方式（1、窗口内打开；2、新窗口打开）
  hrefType?: number;
}

export interface NavigationsByGroupResponse {
  // 自定义配置导航
  CUSTOM?: Record<string, NavigationDetail[]>;
  // 平台默认导航
  PLATFORM?: Record<string, NavigationDetail[]>;
}

// 根据定义好的分组获取所有导航
export function getNavigationsByGroup() {
  return fetch<{ map: NavigationsByGroupResponse }>(
    '/api/v2/comprehensive-menu/navigation/group',
    {
      method: 'POST',
    },
    true
  );
}

export interface EnergyAssetStatistics {
  // 租户下回路个数
  circuitCount?: number;
  // 租户下能源/工质拓扑中的过程个数
  emtProcessCount?: number;
  // 租户下光伏个数
  pvStationCount?: number;
  // 储能站个数
  bsaCount?: number;
}

// 获取能源资产管理数据
export function getEnergyAssetStatistics() {
  return fetch<EnergyAssetStatistics>(
    '/api/v2/comprehensive-menu/energy-assets/data',
    {
      method: 'POST',
    },
    true
  );
}

export interface EdgeDeviceStatistics {
  // 采集设备数
  edgeDeviceCount?: number;
  // 采集点数
  edgeDevicePointCount?: number;
}

// 获取物联接入数据
export function getEdgeDeviceStatistics() {
  return fetch<EdgeDeviceStatistics>(
    '/api/v2/comprehensive-menu/edge-device/data',
    {
      method: 'POST',
    },
    true
  );
}

export interface GatewayStatistics {
  totalCount?: number;
  // 离线数
  offlineCount?: number;
  // 在线数
  onlineCount?: number;
}

// 获取网关数据
export function getGatewayStatistics() {
  return fetch<GatewayStatistics>(
    '/api/v2/comprehensive-menu/gateway/data',
    {
      method: 'POST',
    },
    true
  );
}

export interface AuthConfigStatistics {
  // 总员工数量
  totalStaffNum?: number;
  // 总生产单元数量
  totalOuNum?: number;
}

// 获取权限设置的数据
export function getAuthConfigStatistics() {
  return fetch<AuthConfigStatistics>(
    `/api/v2/comprehensive-menu/permission-setting/data`,
    {
      method: 'POST',
    },
    true
  );
}

// 初始化导航
export function init() {
  return fetch<{ menuInitialized: boolean }>(
    '/api/v2/comprehensive-menu/init',
    {
      method: 'POST',
    },
    true
  );
}
