import { fetch, Key, PromiseWithErrorHandler } from '@maxtropy/components';
import { AuthorityType, LeftNavPreference, OuStaffType, OuStatus, UserStatus } from './const';
import { RoleInfo } from './role';
import { OrganizationTreeProps } from './customer';
import { TenantMenuNavigationResponse } from '@maxtropy/components/es/type';

export interface Paged<T> {
  total: number;
  page: number;
  size: number;
  list: T[];
}

export interface PageRequest {
  page?: number;
  size?: number;
}
export interface StaffListRequest extends PageRequest {
  humanCode?: string;
  username?: string;
  cellphone?: string;
  status?: UserStatus;
  staffStatus?: UserStatus;
  staffCode?: string;
  mcids?: string[];
  name?: string;
  roleId?: number;
}

export interface StaffResponse {
  createTime?: string;
  customerUserCellphone?: string;
  customerUserHumanCode?: string;
  customerUserName?: string;
  customerUserStatus?: UserStatus;
  customerUserUsername?: string;
  id: number;
  mcid?: string;
  mcidName?: string;
  muid?: string;
  staffCode?: string;
  updateTime?: string;
  status?: UserStatus;
  customerName: string;
  customerFullName: string;
  roleId?: number;
  roleName?: string;
}

export interface CustomerUser {
  password?: string;
  muid: string;
  username: string;
  name: string;
  cellphone: string;
  passwordUpdateTime: string;
  status: number;
  gender?: number;
  birthday?: number;
  email?: string;
  wechat?: string;
  qq?: string;
  headPic?: string;
  humanCode?: string;
}

export interface Staff {
  id: number;
  mcid: string;
  muid: string;
  name?: string;
  createTime: string;
  updateTime: string;
  status: UserStatus;
  staffCode: string;
  roleId?: number;
  roleName?: string;
  workEmail?: string;
  workQq?: string;
  workWechat?: string;
}

export interface CreateUserForm {
  name: string;
  cellphone: string;
  username: string;
  email?: string;
  wechat?: string;
  birthday?: string;
  qq?: string;
  headPic?: string;
  navigationPreference: LeftNavPreference;
}

export interface CreateStaffForm {
  mcid: string;
  muid: string;
  workEmail?: string;
  workWechat?: string;
  workQq?: string;
  roleId?: number;
  status: UserStatus;
}

export interface UpdateStaffForm extends CreateStaffForm {
  id: string;
}

interface StaffLike extends Staff {
  roleName?: string;
}

interface GetStaffListResponse {
  bind: StaffLike[];
  unbind: StaffLike[];
}

export interface RoleBindStaffForm {
  roleCode: string;
  staffIds: string[];
}

export function getStaffList(query: StaffListRequest): Promise<Paged<StaffResponse>> {
  return fetch(
    `/api/v2/staff/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export function disableBatchStaff(staffIds: number[]): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/staff/disable/batch`,
    {
      method: 'POST',
      body: JSON.stringify({ staffIds }),
    },
    true
  );
}

export function freezeStaff(staffId: number): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/staff/freeze`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export function enableStaff(staffId: number): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/staff/enable`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export function resetCustomerPassword(body: { muid: string }): Promise<{ password?: string }> {
  return fetch(
    `/api/v2/customer-user/reset-password-return`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface BatchReassignStaffparams {
  staffIds: number[];
  muid: string;
  operationPassword: string;
}

export function batchReassignStaff(params: BatchReassignStaffparams): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/staff/batchReassign`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface ListMcidByStaffIdRequest {
  staffId: string;
  type: AuthorityType;
}

export function getListMcidByStaffId(query: ListMcidByStaffIdRequest): Promise<{ list: string[] }> {
  return fetch(
    `/api/v2/staff/dataRole/listMcidByStaffId`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface UpdateStaffDataRole {
  mcidList?: string[];
  staffId: string;
  type: AuthorityType;
}

export function updateStaffDataRole(query: UpdateStaffDataRole) {
  return fetch(
    `/api/v2/staff/dataRole/update`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export const createUser = (form: CreateUserForm): Promise<CustomerUser> => {
  return fetch(
    `/api/v2/staff/createCustomerUser`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

export function fetchUserById(staffId: string): Promise<CustomerUser> {
  return fetch(
    `/api/v2/staff/getUserInfo`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export function fetchStaffById(staffId: string): Promise<Staff> {
  return fetch(
    `/api/v2/staff/getStaffInfo`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export const createStaff = (form: CreateStaffForm): Promise<Staff> => {
  return fetch(
    `/api/v2/staff/createStaff`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

export const updateStaff = (form: UpdateStaffForm): Promise<Staff> => {
  return fetch(
    `/api/v2/staff/updateStaff`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

export function getByCellphone(cellphone: string): PromiseWithErrorHandler<CustomerUser> {
  return fetch(
    `/api/v2/staff/getByCellphone`,
    {
      method: 'POST',
      body: JSON.stringify({ cellphone }),
    },
    true
  );
}

export function getByUsername(username: string): Promise<CustomerUser> {
  return fetch(
    `/api/v2/staff/getByUsername`,
    {
      method: 'POST',
      body: JSON.stringify({ username }),
    },
    true
  );
}

export function getRole(mcid: string): Promise<{ list: RoleInfo[] }> {
  return fetch(
    `/api/v2/staff/getRole`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );
}

export function getCustomerTree(): Promise<OrganizationTreeProps> {
  return fetch(
    `/api/v2/staff/getCustomerTree`,
    {
      method: 'POST',
    },
    true
  );
}

export function getStaffCode(): Promise<{ staffCode: string }> {
  return fetch(
    `/api/v2/staff/staff-code`,
    {
      method: 'POST',
    },
    true
  );
}

export function checkStaffCodeUnique(query: { staffCode: string }): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/staff/checkUnique`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export function getStaffRoleList(roleCode: string, children: boolean): Promise<{ map: GetStaffListResponse }> {
  return fetch(
    `/api/v2/staff/role/staffList`,
    {
      method: 'POST',
      body: JSON.stringify({ roleCode, children }),
    },
    true
  );
}

// 角色绑定员工 -- 新增
export const bindRoleStaff = (form: RoleBindStaffForm): Promise<void> => {
  return fetch(
    `/api/v2/staff/role/insert`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

// 角色绑定员工 -- 解绑
export const unBindRoleStaff = (form: RoleBindStaffForm): Promise<void> => {
  return fetch(
    `/api/v2/staff/role/delete`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    },
    true
  );
};

export interface StaffByMcidResponse {
  staffId: number;
  name: string;
  cellphone: string;
  staffCode: string;
}

export function getListStaffByMcid(mcid: string) {
  return fetch<{ list: StaffByMcidResponse[] }>(
    `/api/v2/staff/listStaffByMcid`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );
}

export interface StaffAndUserResponse extends Omit<StaffByMcidResponse, 'staffId' | 'cellphone'> {
  id: number;
  mcid: string;
  cellPhone: string;
  customerFullName: string;
  muid: string;
  status: UserStatus;
}

export function getOuStaffAndUserResponse(staffId: Key) {
  return fetch<StaffAndUserResponse>(
    `/api/v2/staff/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export interface OuResponse {
  id: number;
  name: string;
  ouTypeId: string;
  mcid: string;
  customerName: string;
  serialNumber: string;
  status: OuStatus;
  memberType: OuStaffType;
}

export interface BindStaffOuRequest {
  staffId: number;
  ouMemberType: OuStaffType;
  ouIds: number[];
}

export function bindStaffOu(request: BindStaffOuRequest) {
  return fetch(
    `/api/v2/staff/ou/bind`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function unBindStaffOuAll(staffId: number) {
  return fetch<void>(
    `/api/v2/staff/ou/all`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export function unBindStaffOu(staffId: number, ouId: number) {
  return fetch<void>(
    `/api/v2/staff/ou/unbind`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId, ouId }),
    },
    true
  );
}

export interface StaffOuOwnerRequest extends PageRequest {
  staffId: number;
}

export function getStaffOuOwnPage(request: StaffOuOwnerRequest) {
  return fetch<Paged<OuResponse>>(
    `/api/v2/staff/ou/page/own`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface StaffOuRequest extends StaffOuOwnerRequest {
  type: OuStaffType;
  ouName?: string;
  mcid?: string;
}

export function getStaffOuPage(request: StaffOuRequest) {
  return fetch<Paged<OuResponse>>(
    `/api/v2/staff/ou/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export enum Navigation {
  Default = 1,
  Custome = 2,
}

export const navigationDisplay: Record<Navigation, string> = {
  [Navigation.Default]: '默认首页',
  [Navigation.Custome]: '自定义首页',
};

export interface StaffNavigationResponse {
  id: number;
  // 员工id
  staffId: number;
  // 类型
  configType: Navigation;
  // 导航方案详情id
  currentId: string;
  parentId: string;
  rootId: string;
  // 用户id
  customerUserHumanCode: string;
  // 姓名
  customerUserName: string;
  // 所属组织名称
  customerFullName: string;
  // 员工工号
  staffCode: string;
}

// 获取当前登录staff的导航配置详情
export function getCurrentNavigation() {
  return fetch<TenantMenuNavigationResponse | null>(
    `/api/v2/staff/navigation/config/detail`,
    {
      method: 'POST',
    },
    true
  );
}

// 获取staff导航配置
export function getStaffNavigation(staffId: Key) {
  return fetch<StaffNavigationResponse>(
    `/api/v2/staff/navigation/config/info-by-staff`,
    {
      method: 'POST',
      body: JSON.stringify({ staffId }),
    },
    true
  );
}

export interface BatchUpdateNavigationRequest {
  staffIds: number[];
  configType: Navigation;
  // 导航方案详情id
  currentId: string;
  parentId: string;
  rootId: string;
}

// 批量更新当前staff首页导航配置（没有就新增）
export function updateStaffNavigation(request: BatchUpdateNavigationRequest) {
  return fetch<void>(
    `/api/v2/staff/navigation/config/batch-update`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}
