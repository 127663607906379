import {
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  Table,
  Button,
  CustomFilter,
  Form,
  Input,
} from '@maxtropy/components';
import { Col, Space, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  getPropMgmtDeptList,
  PropMgmtDeptPageRequest,
  PropMgmtDeptPageResponse,
} from '../../../api/propertyManagementConfig';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/hooks';
import styles from './index.module.scss';
import { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { getOrganizationWithCodeWithCurrent } from '../../../api/customer';
import { PlusOutlined } from '@ant-design/icons';
import { formatTreeData } from '../../../lib/util';

const columns = [
  {
    title: '物业管理编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '销售单位',
    dataIndex: 'salesOrg',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
];

export interface SearchParams extends Omit<PropMgmtDeptPageRequest, 'page' | 'size'> {}

const PropMgmtDeptList: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const hasPropertyManageAdd = useHasPermission(PermissionsType.B_PROPERTYMANAGEADD); // 新增物业管理配置
  // const hasPropertyManageEdit = useHasPermission(PermissionsType.B_PROPERTYMANAGEEDIT);// 编辑物业管理配置
  // const hasPropertyManageInfo = useHasPermission(PermissionsType.B_PROPERTYMANAGEINFO);// 编辑物业管理配置
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [data, setData] = useState<PropMgmtDeptPageResponse[]>([]); // 后端返回数据
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo; // 分页的一些配置
  const [searchParams, setSearchParams] = useState<SearchParams>(); // 搜索参数
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      // console.log(res)
      setTreeData(formatTreeData([res]));
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getPropMgmtDeptList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount]);

  const onFinish = (val: SearchParams) => {
    setPageOffset(1);
    const params = {
      code: val.code,
      salesOrg: val.salesOrg,
      customerMcid: val.customerMcid,
    };
    setSearchParams(params);
  };

  const onReset = () => {
    setPageOffset(1);
    const params = {
      code: undefined,
      salesOrg: undefined,
      customerMcid: undefined,
    };
    setSearchParams(params);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 183,
      render: (record: any) => (
        <Space size={16}>
          {hasPropertyManageAdd && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                navigate(`/system/operation/prop-mgmt-dept/edit/${record.id}`);
              }}
            >
              编辑
            </Button>
          )}
          {
            // hasPropertyManageInfo &&
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                navigate(`/system/operation/prop-mgmt-dept/detail/${record.id}`);
              }}
            >
              查看
            </Button>
          }
        </Space>
      ),
    },
  ];

  return (
    <Wrapper
      routes={breadcrumbRoutes?.routes}
      className="page_wrapper"
      filters={
        <CustomFilter form={searchForm} onFinish={(val: any) => onFinish(val)} onReset={onReset}>
          <Form.Item label="物业管理编号" name="code">
            <Input placeholder="请输入" />
          </Form.Item>

          <Form.Item label="销售单位" name="salesOrg">
            <Input placeholder=" 请输入" />
          </Form.Item>

          <Form.Item name="customerMcid" label="关联组织">
            <TreeSelect
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeDefaultExpandAll
              onChange={val => {}}
              treeData={treeData}
              placeholder="请选择"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </CustomFilter>
      }
    >
      <div className={styles.content}>
        {hasPropertyManageAdd && (
          <div className={styles.operationArea}>
            <Button
              type="primary"
              onClick={() => {
                navigate('/system/operation/prop-mgmt-dept/create');
              }}
              icon={<PlusOutlined />}
            >
              新建物业管理配置
            </Button>
          </div>
        )}
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
    </Wrapper>
  );
};

export default PropMgmtDeptList;
