import React from 'react';
import lampstand from '../../../assets/image/lampstand.png';
import styles from '../screen.module.scss';

type LampImageProps = {
  headImageUrl: string;
};

export default function LampImage({ headImageUrl }: LampImageProps) {
  return (
    <div className={styles.lampImage} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        style={{ objectFit: 'cover', marginBottom: '-48px', display: 'inline-block' }}
        width="48"
        src={headImageUrl}
        alt=""
      />
      <img style={{ objectFit: 'cover', display: 'inline-block' }} width="100" src={lampstand} alt="" />
    </div>
  );
}
