import { InfoCircleOutlined } from '@ant-design/icons';
import { FormTitle, Key, Tooltip, Wrapper } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useState } from 'react';
import AccountManage from './components/AccountManage';
import PermissionGather from './components/PermissionGather';
import StaffList from './components/StaffList';
import styles from './index.module.scss';
const routes = [{ name: '节点数据权限' }, { name: '审批权限管理' }, { name: '审批和数据权限' }];
const Qldata: React.FC = () => {
  const [currentDeptId, setCurrentDeptId] = useState<number>();

  /**
   * @setAccountApiIsOk
   * 判断获取账户数使用情况api是否可以正常调用
   *
   * 防止权限集合相关的api抛错, 导致页面同时抛2个同样的弹框, 暂时这么写
   *
   */
  const [accountApiIsOk, setAccountApiIsOk] = useState(false);
  const selectTreeKey = (selectedKeys: Key[]) => {
    let keys = selectedKeys as number[];
    setCurrentDeptId(keys[0]);
  };
  return (
    <Wrapper routes={routes} className={styles.content}>
      <FormTitle
        title={
          <Space size={10}>
            审批和数据权限
            <Tooltip title="适用于工单管理、资产管理数据权限和审批设置">
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            </Tooltip>
          </Space>
        }
      />
      <div>
        <Row gutter={20}>
          <Col span={10}>
            <div>权限集合</div>
            <div>
              <AccountManage deptId={currentDeptId} getApiStatus={setAccountApiIsOk} />
            </div>
            <div>
              <PermissionGather accountApiIsOk={accountApiIsOk} selectTreeKey={selectTreeKey} />
            </div>
          </Col>
          {currentDeptId && (
            <Col span={14}>
              <div>员工列表</div>
              <div>
                <StaffList deptId={currentDeptId} />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </Wrapper>
  );
};
export default Qldata;
