import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Space, Spin } from 'antd';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import {
  GroupItem,
  TabKey,
  comprehensiveMenuGroup,
  getComprehensiveMenuGroup,
} from '../../../../api/compositeNavConfig';
import ShowInput from '../../../../components/ShowInput';
import { Button, Input, Key, Modal } from '@maxtropy/components';

interface Props {
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  updateFn: () => void;
}

const formLayout = {
  labelCol: { span: 4, offset: 2 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { offset: 6 },
};

function addTypeAttr(arr: GroupItem[]) {
  return arr.length > 0
    ? arr.map(i => {
        return {
          ...i,
          type: TabKey.CUSTOM,
        };
      })
    : undefined;
}

const GroupModal: React.FC<Props> = ({ modalOpen, setModalOpen, updateFn }) => {
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const [platListLength, setPlatListLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  // 获取分组列表
  useEffect(() => {
    setLoading(true);
    getComprehensiveMenuGroup()
      .then(res => {
        if (res.list) {
          setGroupList(res.list);
          form.setFieldsValue({
            groupList: res.list ?? [],
          });
          const platLen = (res.list ?? []).findIndex(i => i.type === TabKey.CUSTOM);
          setPlatListLength(platLen > 0 ? platLen : (res.list ?? []).length);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOk = () => {
    form.submit();
  };

  const onFinish = (v: { groupList: GroupItem[] }) => {
    const deleteList: Key[] = [];
    const addList: GroupItem[] = [];
    const updateList: GroupItem[] = [];

    const newCusList = v.groupList.slice(platListLength);
    const newCusId = newCusList.map(i => i.id);
    const oldCusList = groupList.slice(platListLength);

    // 删除的分组
    oldCusList.forEach(i => {
      if (!newCusId.includes(i.id)) {
        deleteList.push(i.id as Key);
      }
    });
    // 新增的分组
    newCusList.forEach(i => {
      if (!i.id) {
        addList.push(i);
      }
    });
    // 编辑的分组
    newCusList
      .filter(i => !deleteList.includes(i.id as Key))
      .filter(i => i.id)
      .forEach(i => {
        const sameIdCus = oldCusList.filter(old => old.id === i.id)[0];
        if (sameIdCus && (sameIdCus.name !== i.name || sameIdCus.sortWeight !== i.sortWeight)) {
          updateList.push(i);
        }
      });

    comprehensiveMenuGroup({
      deleteList: deleteList.length > 0 ? deleteList : undefined,
      addList: addTypeAttr(addList),
      updateList: addTypeAttr(updateList),
    }).then(() => {
      setModalOpen(false);
      updateFn();
    });
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  return (
    <Modal open={modalOpen} size="normal" bodyScroll title="分组管理" onCancel={onCancel} onOk={onOk}>
      <Spin spinning={loading}>
        <Form form={form} style={{ marginTop: 15 }} onFinish={onFinish}>
          <Space style={{ paddingLeft: 190 }} size={145} align="baseline">
            <ShowInput value="分组名称" />
            <ShowInput value="排序权重" />
          </Space>
          <Form.List name="groupList">
            {
              // prettier-ignore
              (fields, { add, remove }, { errors }) => ( // NOSONAR
              <>
                {fields.map((field, index) => {
                  const isPlatform = index < platListLength;
                  return (
                    <Form.Item
                      key={field.key}
                      label={index === 0 ? '导航分组' : ''}
                      {...(index === 0 ? formLayout : formItemLayoutWithOutLabel)}
                      className={styles.formItemX}
                    >
                      <Space align="baseline">
                        <Form.Item
                          name={[field.name, 'name']}
                          rules={[
                            { required: true, message: '请输入名称', whitespace: true },
                            {
                              pattern: /^[\u4e00-\u9fa5]+$/,
                              message: '请输入中文',
                            },
                            { max: 12, message: '最多输入12个中文字符' },
                          ]}
                          style={{ width: 194 }}
                        >
                          <Input placeholder={isPlatform ? '--' : '请输入名称'} disabled={isPlatform} />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'sortWeight']}
                          rules={
                            isPlatform
                              ? undefined
                              : [
                                  { required: true, message: '' },
                                  {
                                    validator: async (_, value: string) => {
                                      if (!value) {
                                        throw new Error('请输入权重');
                                      }
                                      if (Number(value) <= 0 || (value.length > 1 && value.slice(0, 1) === '0')) {
                                        throw new Error('请输入正整数');
                                      }
                                      let regEn = /[^0-9]+/im;
                                      if (regEn.test(value)) {
                                        throw new Error('请输入整数');
                                      }
                                    },
                                  },
                                ]
                          }
                          style={{ width: 194 }}
                        >
                          <Input placeholder={isPlatform ? '--' : '请输入权重'} disabled={isPlatform} />
                        </Form.Item>
                        {isPlatform ? null : (
                          <Button
                            type="link"
                            onClick={() => remove(index)}
                            icon={<MinusCircleOutlined style={{ color: '#838383' }} />}
                          />
                        )}
                      </Space>
                    </Form.Item>
                  );
                })}
                <>
                  <Button
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 145, width: 398, color: '#4A90E2' }}
                    type="dashed"
                    onClick={() => add()}
                  >
                    新增分组
                  </Button>
                  <div style={{ paddingLeft: 165 }}>
                    <Form.ErrorList errors={errors} />
                  </div>
                </>
              </>
            )
            }
          </Form.List>
        </Form>
      </Spin>
    </Modal>
  );
};

export default GroupModal;
