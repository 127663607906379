import { fetch } from '@maxtropy/components';
import { Key } from 'react';

export interface DepartmentTreeProps {
  name?: string;
  qflowDeptId?: string;
  parentId?: string;
  children?: DepartmentTreeProps[];
}
// 获取部门树
/**
 *
 * @param tenantMcid 租户mcid
 * @returns
 */
export function getDepartmentTree(tenantMcid: string) {
  return fetch<{ list: DepartmentTreeProps[] }>(
    `/api/v2/qingflow/department/list`,
    {
      method: 'POST',
      body: JSON.stringify({ tenantMcid }),
    },
    true
  );
}
export interface DepartParams {
  name?: string;
  id?: Key;
  parentId?: Key;
}
// 创建部门
export function createDepartment(params: DepartParams) {
  return fetch<{ result: boolean }>(
    `/api/v2/qingflow/department/create`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
// 更新部门
export function updateDepartment(params: DepartParams) {
  return fetch<{ result: boolean }>(
    `/api/v2/qingflow/department/simple-update`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
// 删除轻流部门
export function deleteDepartment(departmentId: string) {
  return fetch<{ result: boolean }>(
    `/api/v2/qingflow/department/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ departmentId }),
    },
    true
  );
}
// 将用户设置为leader成员
/**
 *
 * @param userId 用户id（Staff code）
 * @param add 是否是添加到leader列表，true：添加，false：删除（变成成员）
 * @returns
 */
interface BecomeLeaderPrams {
  deptId: string;
  userId: string;
  add: boolean;
}
export function isBecomeLeader({ deptId, userId, add }: BecomeLeaderPrams) {
  return fetch<{ deptId: number }>(
    `/api/v2/qingflow/department/editLeader`,
    {
      method: 'POST',
      body: JSON.stringify({ deptId, userId, add }),
    },
    true
  );
}
// 获取配额/已使用配额
export interface QuotaProps {
  userQuota?: number;
  usedUserQuota?: number;
}
export function getQuota(tenantMcid: string) {
  return fetch<QuotaProps>(
    `/api/v2/qingflow/department/user_quota`,
    {
      method: 'POST',
      body: JSON.stringify({ tenantMcid }),
    },
    true
  );
}
// 根据部门id查询部门内的用户信息和主管id
export interface UserProps {
  userId?: string;
  name?: string;
  areaCode?: string;
  mobileNum?: string;
  email?: string;
  headImg?: string;
  department?: number[];
  role?: number[];
  beingDisabled?: boolean;
  beingActive?: boolean;
  superiorId?: string;
  customRole?: number[];
  customDepartment?: number[];
  isLeader?: boolean;
}
export function getUserListAndStaffIds(deptId?: Key) {
  return fetch<{ leaderIds?: string[]; userList?: UserProps[] }>(
    `/api/v2/qingflow/user/list`,
    {
      method: 'POST',
      body: JSON.stringify({ deptId }),
    },
    true
  );
}
// 更新用户状态启用/禁用
/**
 *
 * @param userId // 用户id（staff code）
 * @param beingDisabled 是否是禁用（是，则禁用用户；否，则启用用户）
 * @returns
 */
export function updateUserStatus(userId: string, beingDisabled: boolean) {
  return fetch<{ result: boolean }>(
    `/api/v2/qingflow/user/status`,
    {
      method: 'POST',
      body: JSON.stringify({ userId, beingDisabled }),
    },
    true
  );
}

// 将用户移入/移出指定集合（部门）
export function moveUser({
  deptId,
  userIds,
  moveIn,
}: {
  deptId: string;
  userIds: string[];
  moveIn: boolean; // true：移入，false：移出
}) {
  return fetch(
    `/api/v2/qingflow/department/moveUser`,
    {
      method: 'POST',
      body: JSON.stringify({ deptId, userIds, moveIn }),
    },
    true
  );
}

// 批量创建用户

export function createUsers(params: number[]) {
  return fetch<{ result: boolean }>(
    `/api/v2/qingflow/user/create`,
    {
      method: 'POST',
      body: JSON.stringify({ staffIds: params }),
    },
    true
  );
}
// 添加账户的staff列表
export interface QlStaffProps {
  id?: number; // staffid
  mcid?: string;
  name?: string;
  muid?: string;
  status?: number;
  staffCode?: string; // 对应userId
}
export function getAccountList() {
  return fetch<{ list: QlStaffProps[] }>(
    `/api/v2/qingflow/user/candidate`,
    {
      method: 'POST',
    },
    true
  );
}

// 查询可以加入部门的staff列表
export function getStaffDeptList(deptId: Key) {
  return fetch<{ list: QlStaffProps[] }>(
    `/api/v2/qingflow/user/dept/candidate`,
    {
      method: 'POST',
      body: JSON.stringify({ deptId }),
    },
    true
  );
}
