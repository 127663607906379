import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Space } from 'antd';
import styles from '../index.module.scss';
import {
  ContactsOutlined,
  DeploymentUnitOutlined,
  FileSearchOutlined,
  FormOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import {
  getOrganizationTree,
  getSummary,
  OrganizationTreeData,
  OrganizationTreeProps,
  SummaryProps,
} from '../../../api/customer';
import { DataNode } from 'antd/es/tree';
import { filterTreeData, getParent } from '../../../lib/util';
import MoveModal from './MoveModal';
import { Link, useNavigate } from 'react-router-dom';
import { useHasPermission } from '../../../utils/hooks';
import { getParentKey } from '../../../utils/helper';
import { Tooltip, Tree, Input, CustomerAndUser, Button, Empty } from '@maxtropy/components';
import { PermissionsType } from '../../../common/permissionsConst';
import qs from 'qs';

interface ManageProps {
  user?: CustomerAndUser;
  isRootNode: boolean;
}

interface InfoProps extends SummaryProps {
  title?: string;
  code?: string;
  mcid: string;
}

export interface CurrentDataProps extends OrganizationTreeData {
  parentName?: string;
  parentMcid?: string;
  parentCode?: string;
}

export interface TreeDataProps extends OrganizationTreeProps {
  rootNode?: boolean;
}

const dataList: any[] = [];
const generateList = (list: any[]) => {
  for (let i = 0; i < list.length; i++) {
    const node = list[i];
    const { key, data } = node;
    dataList.push({ key, data });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const Manage: React.FC<ManageProps> = ({ user, isRootNode }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [moveModalVisible, setMoveModalVisible] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<TreeDataProps[]>([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
  const [info, setInfo] = useState<InfoProps>();
  const [searchParam, setSearchParam] = useState<string>('');
  const [currentData, setCurrentData] = useState<CurrentDataProps>();
  const [hasJurisdiction, setHasJurisdiction] = useState<string[]>([]);
  const [expandedKeys, setExpandKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const btnHasPermission = useHasPermission(PermissionsType.BUPDATECUSTOMER);
  const staffBtnHasPermission = useHasPermission(PermissionsType.PSTAFFMANAGE);
  const isInitialized = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.customer && !editModalVisible && !moveModalVisible && !addModalVisible && !deleteModalVisible) {
      getOrganizationTree().then(data => {
        if (data && user.customer && user.customer.mcid) {
          setHasJurisdiction(getParent([data], user.customer.mcid));
          setTreeData([{ ...data, rootNode: true }]);
          if (!isInitialized.current) {
            setExpandKeys([data.data!.mcid]);
            setDefaultSelectedKeys([data.data!.mcid]);
            getInfo(data.data!.mcid, data.data?.name, data.data?.code);
            isInitialized.current = true;
          }
          generateList(formatTreeData([data]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, editModalVisible, moveModalVisible, addModalVisible, deleteModalVisible]);

  useEffect(() => {
    if (isEdit) {
      setTreeData([...treeData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const formatTreeData = (data: TreeDataProps[], parentInfo?: OrganizationTreeData) => {
    return data.map(item => {
      const res: DataNode = {
        ...item,
        key: item.data?.mcid ?? '',
        disabled: !item.data?.hasPermission,
        title: !item.data?.hasPermission ? '' : titleFormat(item.data, parentInfo, item.rootNode),
        children: formatTreeData(item.children, item.data),
      };
      return res;
    });
  };

  const titleFormat = (data: OrganizationTreeData, parentInfo?: OrganizationTreeData, rootNode?: boolean) => {
    return (
      <div>
        {((isRootNode && rootNode) || data.mcid === user?.staff?.mcid) && (
          <DeploymentUnitOutlined className={styles.mark} />
        )}
        {data.levelName}-{data.name}
        <span className={styles.titleFormatCode}>[{data.code}]</span>
        <Space className={styles.titleFormatIcon}>
          {!isEdit ? (
            <>
              <FileSearchOutlined
                onClick={() => {
                  getInfo(data.mcid, data.name, data.code);
                }}
              />
            </>
          ) : (
            <>
              {/*编辑*/}
              <Tooltip title="编辑">
                <FormOutlined
                  onClick={() => {
                    setCurrentData({
                      ...data,
                      parentName: parentInfo?.name,
                      parentMcid: parentInfo?.mcid,
                    });

                    navigate(
                      `/system/customer/info/${data.mcid}?${qs.stringify(
                        {
                          name: data.name,
                          levelName: data.levelName,
                          parentName: parentInfo?.name,
                          parentMcid: parentInfo?.mcid,
                        },
                        { indices: false }
                      )}`
                    );
                  }}
                />
              </Tooltip>
              {/*新增*/}
              {data.canAddSub && (
                <Tooltip title="新增">
                  <PlusCircleOutlined
                    onClick={() => {
                      setCurrentData({
                        ...data,
                        parentName: parentInfo?.name,
                        parentMcid: parentInfo?.mcid,
                      });
                      navigate(
                        `/system/customer/info/${data.mcid}?${qs.stringify(
                          {
                            name: data.name,
                            levelName: data.levelName,
                            parentName: parentInfo?.name,
                            parentMcid: parentInfo?.mcid,
                            isAdd: true,
                          },
                          { indices: false }
                        )}`
                      );
                    }}
                  />
                </Tooltip>
              )}
              {/*删除*/}
              {!hasJurisdiction.includes(data.mcid) && (
                <Tooltip title="删除">
                  <MinusCircleOutlined
                    onClick={() => {
                      setCurrentData({
                        ...data,
                        parentName: parentInfo?.name,
                        parentMcid: parentInfo?.mcid,
                      });
                      setDeleteModalVisible(true);
                    }}
                  />
                </Tooltip>
              )}
              {/*移动*/}
              {!rootNode && (
                <Tooltip title="移动">
                  <SyncOutlined
                    onClick={() => {
                      setCurrentData({
                        ...data,
                        parentName: parentInfo?.name,
                        parentMcid: parentInfo?.mcid,
                        parentCode: parentInfo?.code,
                      });
                      setMoveModalVisible(true);
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </Space>
      </div>
    );
  };

  const list = useMemo(() => {
    if (treeData && searchParam) {
      const data = filterTreeData(formatTreeData(treeData), searchParam);
      const keys = dataList
        .map(item => {
          if (
            (item.data?.name as string).includes(searchParam) ||
            (item.data?.code && item.data?.code.includes(searchParam))
          ) {
            return getParentKey(item.key, data);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      setExpandKeys(keys);
      setAutoExpandParent(true);
      return data;
    } else {
      return formatTreeData(treeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData, searchParam, isEdit]);

  const onExpand = (expandedKeys: any[]) => {
    setExpandKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const getInfo = (mcid: string, title?: string, code?: string) => {
    getSummary(mcid).then(res => {
      setInfo({
        ...res,
        title: title,
        code: code,
        mcid: mcid,
      });
    });
  };

  return (
    <>
      <Row style={{ width: '100%' }} gutter={[192, 0]}>
        <Col span={12}>
          <Input.Search
            allowClear
            placeholder="请输入组织名的全部/一部分或完整组织CODE"
            onSearch={v => setSearchParam(v)}
          />
          {list && list.length > 0 ? (
            <Tree
              height={500}
              defaultSelectedKeys={defaultSelectedKeys}
              showLine={{ showLeafIcon: false }}
              treeData={list}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              autoExpandParent={autoExpandParent}
              style={{ marginTop: 20 }}
              onSelect={(selectedKeys, e: any) => {
                if (selectedKeys.length > 0) {
                  console.log(e.node.data);
                  console.log('12', selectedKeys[0]);
                  getInfo(selectedKeys[0] as string, e.node.data.name, e.node.data.code);
                }
              }}
            />
          ) : (
            <Empty style={{ marginTop: '10%' }} />
          )}
          <Space style={{ marginTop: 50 }}>
            {!isEdit && btnHasPermission && list && list.length > 0 && (
              <Button type="primary" className={styles.primaryBtn} onClick={() => setIsEdit(true)}>
                编辑
              </Button>
            )}
            {isEdit && <Button onClick={() => setIsEdit(false)}>退出编辑状态</Button>}
          </Space>
        </Col>
        {info && (
          <Col span={12} className={styles.col}>
            <p>
              <ContactsOutlined className={styles.icon} />
              {info?.title}
              <span className={styles.titleFormatCode}>[{info?.code}]</span>
            </p>
            <p>
              现有职员<span className={styles.mark}>{info?.staffSum ?? 0}</span>人
              {/*，项目<span className={styles.mark}>{info?.projectSum ?? 0}</span>个(不包含下级子组织)*/}
            </p>
            {staffBtnHasPermission && (
              <Button
                type="primary"
                className={styles.primaryBtn}
                onClick={() => {
                  navigate(`/system/customer/staff?mcids=${info.mcid}`);
                }}
              >
                查看人员详情
              </Button>
            )}
          </Col>
        )}
      </Row>
      {(editModalVisible || addModalVisible) && (
        <EditModal
          visible={editModalVisible || addModalVisible}
          isAdd={addModalVisible}
          currentData={currentData}
          onCancel={() => {
            setEditModalVisible(false);
            setAddModalVisible(false);
          }}
        />
      )}
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          currentData={currentData}
          user={user}
          onCancel={() => setDeleteModalVisible(false)}
        />
      )}
      {moveModalVisible && (
        <MoveModal
          visible={moveModalVisible}
          currentData={currentData}
          treeData={treeData}
          user={user}
          onCancel={() => setMoveModalVisible(false)}
        />
      )}
    </>
  );
};

export default Manage;
