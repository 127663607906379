import { FC, Key, useEffect, useState } from 'react';
import {
  EllipsisSpan,
  Filter,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  Button,
  Form,
  Modal,
  Select,
  Input,
  CustomFilter,
} from '@maxtropy/components';
import { Col, Space, TreeSelect } from 'antd';
import { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { formatTreeData } from '../../lib/util';
import { getOrganizationWithCodeWithCurrent } from '../../api/customer';
import HomeManageModal, { FormValueProps, OperateType } from './HomeManageModal';
import { getRole, getStaffList, StaffListRequest, StaffResponse, updateStaffNavigation } from '../../api/staff';
import { ColumnType } from 'antd/es/table';
import { useHasPermission } from '../../utils/hooks';
import { PermissionsType } from '../../common/permissionsConst';
import { RoleInfo } from '../../api/role';

const columns: ColumnType<StaffResponse>[] = [
  {
    title: '用户ID',
    dataIndex: 'customerUserHumanCode',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'customerFullName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '姓名',
    dataIndex: 'customerUserName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属角色',
    dataIndex: 'roleName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export type SearchParams = Omit<
  StaffListRequest,
  'username' | 'staffStatus' | 'cellphone' | 'status' | 'staffCode' | 'page' | 'size'
>;

const HomeMange: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const hasHomePageConfig = useHasPermission(PermissionsType.B_HOME_PAGE_CONFIG);
  const hasHomePageBatchConfig = useHasPermission(PermissionsType.B_HOME_PAGE_BATCH_CONFIG);

  const [searchForm] = Form.useForm();
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [dataSource, setDataSource] = useState<StaffResponse[]>([]);

  const [operate, setOperate] = useState<OperateType>();

  const [current, setCurrent] = useState<StaffResponse>();

  const [staffIds, setStaffIds] = useState<number[]>([]);
  const [roles, setRoles] = useState<RoleInfo[]>([]);
  const [form] = Form.useForm<FormValueProps>();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
      // 获取当前组织后再获取当前组织对应的所有角色
      getRole(res.data!.mcid).then(response => {
        setRoles(response.list);
      });
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getStaffList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(data => {
        setTotalCount(data.total);
        setDataSource(data.list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, pageOffset, pageSize, setTotalCount]);

  const afterClose = () => {
    form.resetFields();
    setCurrent(undefined);
    setStaffIds([]);
  };

  const onCancel = () => {
    setOperate(undefined);
  };

  const onOk = () => {
    form
      .validateFields()
      .then(val => {
        let ids: number[];
        if (operate === OperateType.Single && current) {
          ids = [current.id];
        } else {
          ids = staffIds;
        }
        if (Array.isArray(ids) && ids.length > 0) {
          updateStaffNavigation({
            staffIds: ids,
            configType: val.configType,
            currentId: val.navigationProgramDetailId?.at(2)!,
            parentId: val.navigationProgramDetailId?.at(1)!,
            rootId: val.navigationProgramDetailId?.at(0)!,
          }).then(() => {
            onCancel();
          });
        }
      })
      .catch(() => {});
  };

  const onBatch = () => {
    if (Array.isArray(staffIds)) {
      if (staffIds.length === 0) {
        Modal.warning({
          content: '请先选择员工',
        });
        return;
      }
      setOperate(OperateType.Mutiple);
    }
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 183,
      render: (record: StaffResponse) =>
        hasHomePageConfig ? (
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              setCurrent(record);
              setOperate(OperateType.Single);
            }}
          >
            首页配置
          </Button>
        ) : null,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      setStaffIds(selectedRowKeys as number[]);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: staffIds,
  };

  const onFinish = (val: SearchParams) => {
    setPageOffset(1);
    setSearchParams({
      ...val,
      // @ts-ignore
      mcids: val.mcids ? [val.mcids] : [], // 后端接口入参改成了string[]
    });
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  return (
    <Wrapper
      routes={breadcrumbRoutes?.routes}
      className="page_wrapper"
      filters={
        <CustomFilter<any> form={searchForm} onFinish={onFinish} onReset={onReset}>
          <Form.Item label="角色" name="roleId">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择角色"
              allowClear
              showSearch
              filterOption={true}
              options={roles.map(item => ({ label: item.name, value: item.id }))}
            />
          </Form.Item>

          <Form.Item name="humanCode" label="用户ID">
            <Input placeholder="请输入用户ID" />
          </Form.Item>

          <Form.Item name="name" label="姓名">
            <Input placeholder="请输入姓名" />
          </Form.Item>

          <Form.Item name="mcids" label="所在组织">
            <TreeSelect treeDefaultExpandAll placeholder="请选择" treeData={treeData} />
          </Form.Item>
        </CustomFilter>
      }
    >
      <Space direction="vertical" style={{ marginBottom: 10 }}>
        {hasHomePageBatchConfig && (
          <Button type="primary" onClick={onBatch}>
            批量配置
          </Button>
        )}
      </Space>
      <Table
        rowKey="id"
        scroll={{ x: 1300 }}
        loading={loading}
        columns={buildColumns}
        dataSource={dataSource}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <Paging pagingInfo={pagingInfo} />
      <Modal
        title={`${operate === OperateType.Mutiple ? '批量' : ''}首页配置`}
        destroyOnClose
        open={operate !== undefined}
        onCancel={onCancel}
        onOk={onOk}
        contentClassName="modal-form-content"
        afterClose={afterClose}
        maskClosable={false}
        size="large"
        bodyScroll
      >
        <HomeManageModal
          form={form}
          staffIds={staffIds}
          operate={operate}
          staffId={operate === OperateType.Single ? current?.id : undefined}
        />
      </Modal>
    </Wrapper>
  );
};

export default HomeMange;
