export enum PermissionsType {
  // 系统设置
  PSYSTEM = 'p-system',
  // 角色和权限管理
  PROLEPERMISSIONMANAGE = 'p-rolePermissionManage',
  // 角色管理
  PROLEMANAGE = 'p-roleManage',
  // 新建角色页面
  PCREATEROLE = 'p-createRole',
  // 编辑权限页面
  PUPDATEROLE = 'p-updateRole',
  // 角色绑定员工页面
  PROLEBINDSTAFF = 'p-roleBindStaff',
  // 查看角色详情页面
  PROLEINFO = 'p-roleInfo',
  // 新建/编辑组织页面
  PUPDATEADDCUSTOMER = 'p-updateAddCustomer',

  // 组织管理
  PCUSTOMERMANAGE = 'p-customerManage',
  // 组织详情
  PCUSTOMERINFO = 'p-customerInfo',
  // 编辑组织按钮
  BUPDATECUSTOMER = 'b-updateCustomer',
  // 编辑组织页面
  PUPDATECUSTOMER = 'p-updateCustomer',
  // 定义组织节点页面
  PTENANTPERSONALIZED = 'p-tenantPersonalized',

  // 员工管理
  PSTAFFMANAGE = 'p-staffManage',
  // 新建员工页面
  PCREATESTAFF = 'p-createStaff',
  // 编辑员工页面
  PUPDATESTAFF = 'p-updateStaff',
  // 查看员工详情页面
  PSTAFFINFO = 'p-staffInfo',
  // 员工运营单元配置
  B_STAFF_OU_MANAGE = 'b-staffOuManage',

  // 节点数据权限
  P_QL_NODE_PERMISSIONS = 'p-qlNodePermissions',
  // 审批权限管理
  P_QL_APPROVE_MANAGE = 'p-qlApproveManage',
  // 审批和数据权限
  P_QL_APPROVE_DATA = 'p-qlApproveAndData',
  // 账户管理
  B_QL_MANAGE_ACCOUNT = 'b-qlManageAccount',
  // 添加账户
  B_QL_ADD_ACCOUNT = 'b-qlAddAccount',
  // 编辑员工
  B_QL_EDIT_USER = 'b-qlEditUser',
  // 移入移出权限集合
  B_QL_MOVE_USER = 'b-qlMoveUser',
  // 工单中心
  P_QL_WORK_CENTER = 'p-qlWorkOrderCenter',
  // 工单记录
  P_QL_WORK_RECORD = 'p-qlWorkOrderRecord',
  // 报警工单
  P_QL_WORK_ALARM = 'p-qlWorkOrderAlarm',
  // 工单联系单
  P_QL_WORK_LINK = 'p-qlWorkOrderLink',
  // 设备资产管理
  P_QL_DEVICE_MANAGE = 'p-qlDeviceAssetManage',
  // 资产管理
  P_QL_ASSET_MANAGE = 'p-qlAssetManage',
  // 资产台账
  P_QL_ASSET_BOOK = 'p-qlAssetStandingBook',
  // 资产类目
  P_QL_ASSET_TYPE = 'p-qlAssetDeviceType',
  // 运营基础配置
  P_BASICOPSCONFIG = 'p-basicOpsConfig',
  // 物业管理列表
  P_PROPERTYMANAGELIST = 'p-propertyManageList',
  // 新建/编辑物业管理配置按钮
  B_PROPERTYMANAGEADD = 'b-propertyManageAdd',
  // 新建/编辑物业管理配置页面
  P_PROPERTYMANAGEADD = 'p-propertyManageAdd',

  // 首页管理
  P_HOME_PAGE_MANAGE = 'p-homePageManage',
  // 首页配置
  B_HOME_PAGE_CONFIG = 'b-homePageConfig',
  // 批量配置
  B_HOME_PAGE_BATCH_CONFIG = 'b-homePageBatchConfig',

  // 综合导航配置
  P_SYNTHESIZE_NAVIGATION_CONFIG = 'p-synthesizeNavigationConfig',
  // 综合导航大屏
  P_SYNTHESIZE_NAVIGATION = 'p-synthesizeNavigation',
  // 分组管理
  B_DISTRIBUTE_GROUP_CONFIG = 'b-distributeGroupConfig',
  // 新增导航
  B_INCREASE_NAVIGATION = 'b-increaseNavigation',
  // 编辑导航
  B_EDIT_NAVIGATION = 'b-editNavigation',
  // 删除导航
  B_DELETE_NAVIGATION = 'b-deleteNavigation',
}
