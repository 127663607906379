import { Key, fetch } from '@maxtropy/components';

export enum TabKey {
  PLATFORM = 1,
  CUSTOM = 2,
}

export const TabKeyDisPlay = {
  [TabKey.PLATFORM]: '平台导航',
  [TabKey.CUSTOM]: '自定义导航',
};

export enum HrefType {
  CURWINDOW = 1,
  NEWWINDOW = 2,
}

export const HrefTypeDisPlay = {
  [HrefType.CURWINDOW]: '当前页面打开',
  [HrefType.NEWWINDOW]: '新标签页打开',
};

export interface GroupItem {
  id?: Key; // 主键ID
  name?: string; // 名称
  type?: TabKey; // 类型（1、平台分组，2、自定义分组）
  sortWeight?: Key; // 排序权重
}

// 获取分组
export const getComprehensiveMenuGroup = () => {
  return fetch<{ list: GroupItem[] }>(
    '/api/v2/comprehensive-menu/getGroup',
    {
      method: 'POST',
    },
    true
  );
};

export interface GroupBody {
  addList?: GroupItem[]; // 新增分组列表
  updateList?: GroupItem[]; // 更新分组列表
  deleteList?: Key[]; // 删除分组idList
}

// 分组增量接口
export const comprehensiveMenuGroup = (body: GroupBody) => {
  return fetch(
    '/api/v2/comprehensive-menu/modifyGroup',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export interface NavItem {
  id: Key;
  name: string; // 名称
  type: TabKey; // 类型（1、平台导航，2、自定义导航）
  sortWeight: number; // 排序权重
  comprehensiveMenuGroupId: Key; // 关联分组ID
  functionPermissionCode: string; // 对应导航权限code
  hrefType: HrefType; // 打开方式（1、窗口内打开；2、新窗口打开）
  comprehensiveMenuGroupName: string; // 关联分组名称
  functionPermissionCodeFullName: string;
  path: string; // path
  functionPermissionCodeName: string; // 对应导航权限名称
}

// 获取导航
export const getNavList = (typeId: TabKey) => {
  return fetch<{ list: NavItem[] }>(
    `/api/v2/comprehensive-menu/getNavigationByType`,
    {
      method: 'POST',
      body: JSON.stringify({ typeId }),
    },
    true
  );
};

export interface UpdateNavBody {
  id?: Key;
  name?: string; // 名称
  type?: TabKey; // 类型（1、平台分组，2、自定义分组）
  sortWeight?: number; // 排序权重
  comprehensiveMenuGroupId?: Key; // 关联分组id
  functionPermissionCode?: string; // 对应导航权限code
  hrefType?: HrefType; // 打开方式（1、窗口内打开；2、新窗口打开）
  tenantMcid?: string; // 租户mcid
  path?: string; //path
}

// 新增导航
export const addNav = (body: UpdateNavBody) => {
  return fetch<{ influencedLineAmount: boolean }>(
    '/api/v2/comprehensive-menu/createNavigation',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 修改导航
export const updateNav = (id: Key, body: UpdateNavBody) => {
  return fetch<{ influencedLineAmount: boolean }>(
    `/api/v2/comprehensive-menu/modifyNavigation`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );
};

// 删除导航
export const delNav = (id: Key) => {
  return fetch<{ influencedLineAmount: boolean }>(
    `/api/v2/comprehensive-menu/deleteNavigation`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};
