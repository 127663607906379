import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { CurrentDataProps } from './PermissionGather';
import { createDepartment, DepartParams, updateDepartment } from '../../../api/ql-user';
import { Form, Input, Modal } from '@maxtropy/components';

interface DeptOpModalProps {
  onCancel: (refresh?: boolean) => void;
  isAdd: boolean;
  currentData?: CurrentDataProps;
}

const DeptOpModal: React.FC<DeptOpModalProps> = props => {
  const { onCancel, isAdd, currentData } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isAdd) {
      form.setFieldsValue({
        name: currentData?.name,
      });
    }
  }, [isAdd, currentData?.name, form]);

  const onFinish = async (values: DepartParams) => {
    const promiseRes = isAdd
      ? createDepartment({
          ...values,
          parentId: currentData?.qflowDeptId,
        })
      : updateDepartment({
          ...values,
          id: currentData?.qflowDeptId,
          parentId: currentData?.parentId,
        });
    const res = await promiseRes;
    if (res?.result) {
      onCancel(true);
    }
  };

  return (
    <Modal
      title={`${isAdd ? '新建' : '编辑'}部门`}
      open
      size="normal"
      contentClassName="modal-form-content"
      onOk={() => form.submit()}
      onCancel={() => onCancel()}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="name" label="部门名称" rules={[{ required: true, message: '请输入部门名称' }]}>
          <Input placeholder="部门名称" style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeptOpModal;
