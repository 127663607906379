import React from 'react';
import { Col, Row, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { Form, Input, message, Modal, Wrapper, Button, SubContent, FormTitle } from '@maxtropy/components';
import { updatePassword } from '../../../api/user';

const FormItem = Form.Item;

const routes = [{ name: '个人中心' }, { name: '个人设置' }, { name: '修改登录密码' }];

const LoginPasswordForm: React.FC = () => {
  const [form] = Form.useForm();

  const checkConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== form.getFieldValue('newPassword')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  const handleSubmit = async (values: any) => {
    updatePassword(values)
      .onError(error => {
        const { errorMessage } = error?.cause;
        message.error(errorMessage);
        throw error;
      })
      .then(() => {
        form.resetFields();
        Modal.success({
          content: '密码已经生效，请重新登录',
          okText: '确定',
          onOk: () => {
            window.open('/server/logout', '_self');
          },
        });
      });
  };

  const onCancel = () => {
    form.resetFields();
  };

  const children: React.ReactNode = (
    <Form form={form} onFinish={handleSubmit}>
      <Row>
        <Col span={24}>
          <FormItem name="oldPassword" rules={[{ required: true, message: '请输入原登录密码' }]} label="原登录密码">
            <Input.Password placeholder="请输入原登录密码" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="newPassword"
            rules={[
              { required: true, message: '请输入新登录密码' },
              { pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])[\x20-\x7E]{8,}$/, message: '密码不少于8位，需同时包含字母和数字' },
            ]}
            label="新登录密码"
          >
            <Input.Password placeholder="请输入至少8位数密码，需同时包含字母和数字" />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="validNewPassword"
            rules={[{ required: true, message: '请确认新登录密码' }, { validator: checkConfirm }]}
            label="确认新登录密码"
          >
            <Input.Password placeholder="请输入至少8位数密码，需同时包含字母和数字" />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ marginLeft: 135, marginBottom: 24, color: 'var(--mx-text-desc-color)' }}>
          <ExclamationCircleOutlined className={styles.warning} />
          &nbsp;忘记密码请退出登录到登录页面找回密码。
        </Col>
      </Row>
      <Col span={12}>
        <Space>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={onCancel}>取消</Button>
        </Space>
      </Col>
    </Form>
  );

  return (
    <Wrapper routes={routes} className={styles.wrapperPadding}>
      <FormTitle title="修改登录密码" />
      <SubContent>{children}</SubContent>
    </Wrapper>
  );
};

export default LoginPasswordForm;
