import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './index.module.scss';
import { Key, RoleTree, TreeDataNode as DataNode, getRolePermissions } from '@maxtropy/components';
import { Modal, Tabs, TabsProps } from 'antd';
import {
  getNavigationAutoSyncPermissionList,
  getRoleNavigationPermissionList,
  getRoleNavigationPermissionTree,
  LevelType,
  NavigationPermissionTree,
  PermissionsItem,
  UpdateNavigationPermission,
} from '../../../api/role';
import { useParams } from 'react-router-dom';
import usePageStatus, { PageStatus } from './usePageStatus';
import {
  apiMiniAppPermissionMenuTreeGet,
  apiRoleMiniAppPermissionListGet,
  MiniAppPermissionMenuTreeGetResponse,
} from '@maxtropy/cc-customer-apis';
import { convertToDataNode, findCodeByKey, findKeysByCode, findTopLevelId } from './tools';
import { isNil } from 'lodash-es';

interface MiniApppermissions {
  roleCode?: string;
  miniAppId?: number;
  permissions?: string[];
  syncPermissions?: string[];
}

interface Props {
  miniIds: number[];
}
export interface EditNavigationPermissionRefProps {
  submit: () => UpdateNavigationPermission;
  getSubmitMiniAppMenuData: () => MiniApppermissions[];
}

function flat(data: NavigationPermissionTree[]): DataNode[] {
  const nodes = data.map(item => {
    if (item.level === LevelType.THIRD) {
      if (item.permissions && item.permissions.length > 0) {
        item.children = item.permissions.map(permission => {
          return {
            id: `${permission.code}-${permission.isPlatform}-${permission.integratedAppId}`,
            navigationName: item.name,
            name: permission.name,
            permissionName: permission.name,
            level: 4,
            weighting: undefined,
            code: permission.code,
            description: permission.description,
            permissions: [],
            children: undefined,
          };
        });
      }
    }
    const isLeaf = !(Array.isArray(item.children) && item.children.length > 0);
    const type = item.code !== null ? (item.code.at(0) === 'b' ? '按钮权限' : '页面权限') : undefined;
    const node = {
      children: [] as DataNode[],
      isLeaf,
      key:
        item.level === LevelType.THIRD
          ? item.integratedAppId === null && item.isPlatform === null && item.code === null
            ? `${item.id}`
            : `${item.code}-${item.isPlatform}-${item.integratedAppId}`
          : `${item.id}`,
      title: item.name,
      permissionName: item.permissionName,
      navigationName: item.navigationName,
      type,
      description: item.description,
      level: item.level,
      freeze:
        item.level === LevelType.THIRD &&
        item.integratedAppId === null &&
        item.isPlatform === null &&
        item.code === null
          ? true
          : false,
    };
    if (!isLeaf) {
      node.children = flat(item.children!);
    }
    return node;
  });

  return nodes;
}

// key: 一二级导航id / 权限Code、isPlatform、integratedAppId 拼接字符串
// value: PermissionsItem
function uniqueToObjMap(map: Map<string, PermissionsItem>, data: NavigationPermissionTree[]) {
  data.forEach(item => {
    if (item.level === LevelType.THIRD) {
      if (!(item.integratedAppId === null && item.isPlatform === null && item.code === null)) {
        map.set(`${item.code}-${item.isPlatform}-${item.integratedAppId}`, {
          isPlatform: item.isPlatform,
          integratedAppId: item.integratedAppId,
          code: item.code,
          description: item.description,
          name: item.name,
          id: item.id,
        });
        if (item.permissions && item.permissions.length > 0) {
          item.permissions.forEach(i => map.set(`${i.code}-${i.isPlatform}-${i.integratedAppId}`, i));
        }
      }
    }
    if (item.children && item.children.length > 0) {
      uniqueToObjMap(map, item.children);
    }
  });
  return map;
}

const EditNavigationPermission: ForwardRefRenderFunction<EditNavigationPermissionRefProps, Props> = (props, ref) => {
  const { miniIds } = props;
  const { code } = useParams<{ code: string }>();
  const [modal, modalContextHolder] = Modal.useModal();

  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const [originalMiniAppMenuTree, setOriginalMiniAppMenuTree] = useState<MiniAppPermissionMenuTreeGetResponse>([]);
  const [prevCheckedMiniAppMenuKeys, setPrevCheckedMiniAppMenuKeys] = useState<Key[]>([]);
  const [checkedMiniAppMenuKeys, setCheckedMiniAppMenuKeys] = useState<Key[]>([]);
  const [checkedMiniAppMenuPermission, setCheckedMiniAppMenuPermission] = useState<MiniApppermissions[]>([]);
  const [miniAppPermissionList, setMiniAppPermissionList] = useState<string[]>([]);

  const pageStatus = usePageStatus();
  const originalData = useRef<{ originalCheckedKeys: Key[] }>();
  const [uniqueToObj, setUniqueToObj] = useState<Map<string, PermissionsItem>>();
  const [autoSyncList, setAutoSyncList] = useState<Key[]>([]);

  const miniAppMenuTreeData: DataNode[] = useMemo(() => {
    return convertToDataNode(originalMiniAppMenuTree);
  }, [originalMiniAppMenuTree]);

  console.log('树', miniAppMenuTreeData);

  function getDisabledAndChecked(treeData: DataNode[]) {
    const disabledAndChecked: Key[] = [];
    treeData.forEach(level_1 => {
      level_1.children.forEach(level_2 => {
        level_2.children.forEach(level_3 => {
          if (level_3.freeze) {
            disabledAndChecked.push(level_3.key);
          }
        });
      });
    });
    return disabledAndChecked;
  }

  useEffect(() => {
    if (!code) return;
    getRoleNavigationPermissionTree(code).then(data => {
      const map = uniqueToObjMap(new Map<string, PermissionsItem>(), data.list);
      setUniqueToObj(map);
      const treeData = flat(data.list);
      setTreeData(treeData);
      getRoleNavigationPermissionList(code).then(data => {
        const disabledAndChecked = getDisabledAndChecked(treeData);
        const permissions = (data.list ?? [])
          .map(i => `${i.code}-${i.isPlatform}-${i.integratedAppId}`)
          .filter(i => Array.from(map.keys()).includes(i));
        setCheckedKeys([...disabledAndChecked, ...permissions]);
        originalData.current = { originalCheckedKeys: permissions };
      });
    });
    getNavigationAutoSyncPermissionList(code).then(res => {
      const autoSyncListKeys = (res.list ?? []).map(i => `${i.permissionCode}-${i.isPlatform}-${i.integratedAppId}`);
      setAutoSyncList(autoSyncListKeys);
    });

    // 获取小程序树数据
    if (miniIds?.length > 0) {
      apiMiniAppPermissionMenuTreeGet({ roleCode: code }).then(res => {
        setOriginalMiniAppMenuTree(res);
        apiRoleMiniAppPermissionListGet({ roleCode: code }).then(res => {
          const pList = (res ?? []).filter(m => !isNil(m.permissionCode)).map(k => k.permissionCode!);
          setMiniAppPermissionList(pList);
        });
      });
    }
  }, [code, miniIds]);

  const excludeKeys = useMemo(() => {
    return treeData.reduce((a, c) => {
      return [...a, c.key, ...(c.children ?? []).map(i => i.key)];
    }, [] as Key[]);
  }, [treeData]);

  // 归类小程序权限
  useEffect(() => {
    const codeMap: { [miniAppId: string]: string[] } = {};

    checkedMiniAppMenuKeys.forEach(item => {
      const code = findCodeByKey(miniAppMenuTreeData, String(item));
      if (code) {
        const topLevelId = findTopLevelId(miniAppMenuTreeData, String(item));
        if (topLevelId) {
          if (!codeMap[topLevelId]) {
            codeMap[topLevelId] = [];
          }
          codeMap[topLevelId].push(code);
        }
      }
    });

    const result = Object.entries(codeMap).map(([miniAppId, permissions]) => ({
      miniAppId: Number(miniAppId),
      permissions,
    }));

    setCheckedMiniAppMenuPermission(result);
  }, [checkedMiniAppMenuKeys, miniAppMenuTreeData]);

  // 回显已绑定的小程序权限
  useEffect(() => {
    const initialKeys = miniAppPermissionList
      .map(p => {
        const keys = findKeysByCode(miniAppMenuTreeData, p);
        if (keys.length === 0) return [p];
        return keys;
      })
      .flat();
    setCheckedMiniAppMenuKeys([...initialKeys]);
    setPrevCheckedMiniAppMenuKeys([...initialKeys]);
  }, [miniAppMenuTreeData, miniAppPermissionList]);

  const onCheckMiniAppMenu = (checkedKeys: Key[]) => {
    // 根据prevCheckedMiniAppMenuKeys和checkedKeys比对
    // checkedKeys有减少
    const delKeys = prevCheckedMiniAppMenuKeys.filter(i => !checkedKeys.includes(i));
    const delCodes = delKeys.map(k => {
      const code = findCodeByKey(miniAppMenuTreeData, k as string);
      if (isNil(code)) return k as string;
      return code;
    });

    // checkedKeys有增加
    const addKeys = checkedKeys.filter(i => !prevCheckedMiniAppMenuKeys.includes(i));
    const addCodes = addKeys
      .map(k => {
        const code = findCodeByKey(miniAppMenuTreeData, k as string);
        if (isNil(code)) return k as string;
        return code;
      })
      .filter(p => !isNil(p));

    const pList = Array.from(new Set([...miniAppPermissionList, ...addCodes])).filter(p => !delCodes.includes(p));

    setMiniAppPermissionList(pList as string[]);

    const newKeys = pList
      .map(p => {
        const key = findKeysByCode(miniAppMenuTreeData, p!);
        console.log(key, 'key');
        if (key.length === 0) return [p];
        return key;
      })
      .flat();
    setCheckedMiniAppMenuKeys(newKeys);
    setPrevCheckedMiniAppMenuKeys(newKeys);
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: () => {
        // 无权限校验的导航，不需要提交
        const checked = checkedKeys.filter(i => i !== 'null-null-null');
        const { permissions } = getRolePermissions({
          treeData,
          checkedKeys: checked,
          ...originalData.current!,
        });
        const addPermissions = permissions[0].map(i => uniqueToObj?.get(`${i}`)).flat() as Array<PermissionsItem>;
        const deletePermission = permissions[1].map(i => uniqueToObj?.get(`${i}`)).flat() as Array<PermissionsItem>;
        return {
          roleCode: code!,
          addPermissions: addPermissions.filter(i => i !== undefined),
          deletePermission: deletePermission.filter(i => i !== undefined),
        };
      },
      getSubmitMiniAppMenuData: () => {
        const upsertParams = checkedMiniAppMenuPermission.map(item => {
          return {
            roleCode: code!,
            miniAppId: item.miniAppId,
            permissions: item.permissions,
          };
        });
        return upsertParams || [];
      },
    }),
    [treeData, checkedKeys, code, checkedMiniAppMenuPermission, uniqueToObj]
  );

  const disabled = pageStatus === PageStatus.DETAIL;

  const cancelAutoSync = (checked: Key[]) => {
    const disabledAndChecked = getDisabledAndChecked(treeData);
    const excludeChecked = checked.filter(i => !excludeKeys.includes(i));
    const excludeCheckedKeys = checkedKeys.filter(i => !excludeKeys.includes(i));
    if (excludeCheckedKeys.length > excludeChecked.length) {
      const delChecked = excludeCheckedKeys.filter(i => !excludeChecked.includes(i));
      const isOpen = delChecked.some(i => autoSyncList.includes(i));
      if (isOpen) {
        modal.confirm({
          title: `取消该导航权限，将会关闭部分对应权限的自动同步，请确认是否取消？`,
          content: <p>可在本页面【功能视图】中查看自动同步的权限。</p>,
          okText: '确认取消',
          cancelText: '暂不取消',
          onOk: () => setCheckedKeys(Array.from(new Set([...excludeChecked, ...disabledAndChecked]))),
        });
      } else {
        setCheckedKeys(Array.from(new Set([...excludeChecked, ...disabledAndChecked])));
      }
    } else {
      setCheckedKeys(Array.from(new Set([...excludeChecked, ...disabledAndChecked])));
    }
  };

  // 如果没有绑定的小程序id， 说明没有勾选  那么不显示小程序权限管理Tab
  const navTabItem: TabsProps['items'] = [
    {
      key: '1',
      label: '权限功能管理',
      children: (
        <>
          <RoleTree
            treeData={treeData}
            className={styles.treeWrapper}
            checkedKeys={checkedKeys}
            onCheck={cancelAutoSync}
            showDetail
            disabled={disabled}
            renderHoverContent={data => (
              <div style={{ width: '320px' }}>
                <p className={styles.toolTips_P1}>导航名：{data.navigationName ? data.navigationName : data.title}</p>
                <p className={styles.toolTips_P1}>权限名：{data.permissionName}</p>
                <p className={styles.toolTips_P1}>权限类型：{data.type}</p>
                <p className={styles.toolTips_P2}>权限说明：{data.description}</p>
              </div>
            )}
          />
          {modalContextHolder}
        </>
      ),
    },
  ];

  const getTabItems = () => {
    if (miniIds?.length) {
      const miniTab = [
        {
          key: '2',
          label: '小程序权限管理',
          children: (
            <>
              <RoleTree
                treeData={miniAppMenuTreeData}
                className={styles.miniTreeWrapper}
                checkedKeys={checkedMiniAppMenuKeys}
                onCheck={onCheckMiniAppMenu}
                showDetail
                disabled={disabled}
              />
              {modalContextHolder}
            </>
          ),
        },
      ];
      return [...navTabItem, ...miniTab];
    }
    return [...navTabItem];
  };

  return (
    <div className={styles.treeContainer}>
      <Tabs items={getTabItems()} style={{ width: '100%', padding: '10px 0 20px 10px' }}></Tabs>
    </div>
  );
};

export default forwardRef(EditNavigationPermission);
