import { fetch, NavigationPreference, CustomerAndUser } from '@maxtropy/components';
import { Gender, UserStatus } from './const';

export interface Customer {
  mcid: string;
  address: string;
  phone: string;
  createTime: object;
  contactName: string;
  name: string;
  uscc: string;
  fullName: string;
  updateTime: object;
  contactPhone: string;
  logoUrl?: string;
}

export function getCurrentUser(): Promise<CustomerAndUser> {
  return fetch(
    '/api/v2/customer-user/current',
    {
      method: 'POST',
    },
    true
  );
}

export interface UpdateCustomerUserNavigationPreferenceRequest {
  navigationPreference: NavigationPreference;
}

export function updateCustomerUserNavigationPreference(request: UpdateCustomerUserNavigationPreferenceRequest) {
  return fetch<void>(
    '/api/v2/customer-user/navigationPreference',
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function existOperationPassword(): Promise<{ existed: boolean }> {
  return fetch(
    `/api/v2/customer-user/exist-operation-password`,
    {
      method: 'POST',
    },
    true
  );
}

export interface InitOperationPasswordRequest {
  operationPassword: string;
  validOperationPassword: string;
}

export function initOperationPassword(body: InitOperationPasswordRequest): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/customer-user/init-operation-password`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface UpdateOperationPasswordRequest {
  newOperationPassword: string;
  oldOperationPassword: string;
  validNewOperationPassword: string;
}

export function updateOperationPassword(body: UpdateOperationPasswordRequest): Promise<{ result: boolean }> {
  return fetch(
    `/api/v2/customer-user/update-operation-password`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface UpdatePasswordRequest {
  newPassword: string;
  oldPassword: string;
  validNewPassword: string;
}

export function updatePassword(body: UpdatePasswordRequest) {
  return fetch(
    `/api/v2/customer-user/update-password`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface CustomerUserDtoProps {
  muid?: string;
  username?: string;
  name?: string;
  passwordUpdateTime?: string;
  updateTime?: string;
  status?: UserStatus;
  gender?: Gender;
  email?: string;
  wechat?: string;
  birthday?: string;
  cellphone?: string;
  qq?: string;
  headPic?: string;
  humanCode?: string;
  createTime?: string;
}

export interface StaffDtoProps {
  id: number;
  mcid?: string;
  muid?: string;
  status?: UserStatus;
  staffCode?: string;
  workEmail?: string;
  workWechat?: string;
  workQq?: string;
  createTime?: string;
  updateTime?: string;
}

export interface CustomerUserInfoProps {
  customerUserDto: CustomerUserDtoProps;
  staffDto: StaffDtoProps;
}

export function getCustomerUserInfo(): Promise<CustomerUserInfoProps> {
  return fetch(
    `/api/v2/customer-user/info`,
    {
      method: 'POST',
    },
    true
  );
}

export interface UpdateCustomerUserInfoProps extends StaffDtoProps, CustomerUserDtoProps {
  staffId: number;
}

export function updateCustomerUserInfo(body: UpdateCustomerUserInfoProps): Promise<CustomerUserInfoProps> {
  return fetch(
    `/api/v2/customer-user/updateSelf`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export function sendSms() {
  return fetch<{ expiredTimestamp: number }>(
    `/api/v2/customer-user/send-sms-operation-token`,
    {
      method: 'POST',
    },
    true
  );
}

export function checkSms(token: string) {
  return fetch<{ result: boolean }>(
    `/api/v2/customer-user/valid-operation-token`,
    {
      method: 'POST',
      body: JSON.stringify({ token }),
    },
    true
  );
}
